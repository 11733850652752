import { t } from 'i18next';

import { PwcOrderStatus } from 'types/pwcOrder';

const STATUS_LABELS: Record<PwcOrderStatus, [string, string]> = {
  [PwcOrderStatus.Initiated]: ['page.pwcOrders.components.status.initiated', 'Initiated'],
  [PwcOrderStatus.Pending]: ['page.pwcOrders.components.status.pending', 'Pending'],
  [PwcOrderStatus.Completed]: ['page.pwcOrders.components.status.completed', 'Completed'],
  [PwcOrderStatus.Expired]: ['page.pwcOrders.components.status.expired', 'Expired'],
  [PwcOrderStatus.Canceled]: ['page.pwcOrders.components.status.canceled', 'Cancelled'],
  [PwcOrderStatus.Confirmed]: ['page.pwcOrders.components.status.confirmed', 'Confirmed'],
  [PwcOrderStatus.Incomplete]: ['page.pwcOrders.components.status.incomplete', 'Incomplete'],
};

export const LABELS = {
  STATUS: STATUS_LABELS,
};

export const badgePropsMap: Record<PwcOrderStatus, { type: PwcOrderStatus; label: string }> = {
  [PwcOrderStatus.Initiated]: {
    type: PwcOrderStatus.Initiated,
    label: t(LABELS.STATUS.INITIATED),
  },
  [PwcOrderStatus.Pending]: {
    type: PwcOrderStatus.Pending,
    label: t(LABELS.STATUS.PENDING),
  },
  [PwcOrderStatus.Confirmed]: {
    type: PwcOrderStatus.Confirmed,
    label: t(LABELS.STATUS.CONFIRMED),
  },
  [PwcOrderStatus.Completed]: {
    type: PwcOrderStatus.Completed,
    label: t(LABELS.STATUS.COMPLETED),
  },
  [PwcOrderStatus.Expired]: {
    type: PwcOrderStatus.Expired,
    label: t(LABELS.STATUS.EXPIRED),
  },
  [PwcOrderStatus.Canceled]: {
    type: PwcOrderStatus.Canceled,
    label: t(LABELS.STATUS.CANCELED),
  },
  [PwcOrderStatus.Incomplete]: {
    type: PwcOrderStatus.Incomplete,
    label: t(LABELS.STATUS.INCOMPLETE),
  },
};
