import styled from '@emotion/styled';

import { Button } from 'components/buttons';

export const LightText = styled.div(({ theme }) => ({
  color: theme.colors.inactive,
  fontSize: 13,
}));

export const PaddingWrapper = styled.div(() => ({
  padding: 10,
  width: 'fit-content',
}));

export const FormWrapper = styled.div(({ theme }) => ({
  padding: 10,
  background: theme.colors.pageWrapper,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  width: 'fit-content',
  borderRadius: 5,
  marginBottom: 15,

  '& > div > div': {
    color: theme.colors.text.primary,
  },
}));

export const FieldsWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

export const Separator = styled.div(({ theme }) => ({
  height: 1,
  margin: '30px 0',
  backgroundColor: theme.colors.container.primary,
}));

export const RemoveButton = styled(Button)(() => ({
  marginRight: 'auto',
  '& > span': {
    gap: 10,
    display: 'flex',
  },
}));
