import styled from '@emotion/styled';

export const Label = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const Container = styled.div((props) => ({
  gap: 4,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
