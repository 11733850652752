import { Divider } from '@mui/material';
import { OptionallyVisible } from 'components';
import { FieldArrayRenderProps, getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import IconAdd from 'components/icons/add';
import IconRemove from 'components/icons/dash';
import FileDropzone from 'components/inputs/file/dropzone';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FormType, LABELS, imgAcceptRules } from '../../keys';
import { Section } from '../../styles';
import { BridgeDocumentPurpose, DocumentType } from '../../types';
import replaceLabelsUnderscore from '../../utils/replaceLabelsUnderscore';
import FormikInput from '../formikTextInput/formikTextInput';
import { Block, Container, InnerLabel, Label, RecordButton } from './styles';

interface DocumentsProps {
  // path to the field in the formik values, must point to a field of an array type: DocumentType[]
  fieldPath: string;
  insert: FieldArrayRenderProps['insert'];
  remove: FieldArrayRenderProps['remove'];

  //  for errors coming from backend
  error?: Record<string, string>;
}

const PURPOSES: SelectItem[] = Object.entries(BridgeDocumentPurpose).map(([key, value]) => ({
  key: value,
  label: key,
}));

const formattedPurposes = replaceLabelsUnderscore(PURPOSES);

const Documents = ({ fieldPath, error, insert, remove }: DocumentsProps) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext<FormType>();
  const documents = getIn(values, fieldPath) as DocumentType[];

  const addItem = () => {
    insert(documents.length + 1, { purpose: undefined, file: undefined, description: undefined });
  };

  const removeItem = (index: number) => {
    return () => {
      remove(index);
    };
  };

  return (
    <>
      <Label>{t(LABELS.SECTIONS.DOCUMENTS.TITLE)}</Label>

      <Block>
        <FormField error={error?.[fieldPath]}>{/* showing BE errors at the top, move it? */}</FormField>

        <Section>
          {documents.map((identifyingInformation, index) => {
            const purpose = `${fieldPath}[${index}].purpose`;
            const file = `${fieldPath}[${index}].file`;
            const description = `${fieldPath}[${index}].description`;

            const purposeError = getIn(errors, `${purpose}.key`);
            const purposeTouched = getIn(touched, purpose);

            const fileVal = getIn(values, file);

            const handleImageChange = () => (files: File[]) => {
              const [img] = files;
              const isRemovingFile = !img && fileVal;
              if (isRemovingFile) {
                setFieldValue(file, '');
                return;
              }

              setFieldValue(file, img);
            };

            const fileError = getIn(errors, file);
            const fileTouched = getIn(touched, file);

            return (
              <Container>
                <FormField error={purposeError && purposeTouched ? purposeError : undefined}>
                  <SearchableSelect<SelectItem, false>
                    fullWidth
                    value={identifyingInformation.purpose}
                    items={formattedPurposes}
                    placeholder={t(LABELS.SECTIONS.DOCUMENTS.PLACEHOLDER_PURPOSE)}
                    onChange={(selectItem) => {
                      setFieldValue(purpose, selectItem);
                    }}
                    getItemLabel={(item) => item.label}
                    multiple={false}
                    closeDropdownOnSelect
                    popperProps={{ disablePortal: true }}
                  />
                </FormField>

                <FormField error={fileError && fileTouched ? fileError : undefined}>
                  <InnerLabel>{t(LABELS.SECTIONS.DOCUMENTS.LOGO.UPLOAD)}</InnerLabel>
                  <FileDropzone
                    error={fileError && fileTouched ? fileError : undefined}
                    onFileChange={handleImageChange()}
                    filledInputLabel={t(LABELS.SECTIONS.DOCUMENTS.LOGO.LABEL)}
                    initialFile={fileVal}
                    hints={[t(LABELS.SECTIONS.DOCUMENTS.LOGO.SUPPORTED_FORMAT), t(LABELS.SECTIONS.DOCUMENTS.LOGO.MAX_FILE_SIZE)]}
                    accept={imgAcceptRules}
                  />
                </FormField>

                <FormikInput placeholder={t(LABELS.SECTIONS.DOCUMENTS.PLACEHOLDER_DESCRIPTION)} fieldPath={description} />

                <OptionallyVisible visible={index > 0}>
                  <div>
                    <RecordButton type="button" inside flat onClick={removeItem(index)}>
                      <IconRemove />
                      {t(LABELS.SECTIONS.DOCUMENTS.REMOVE)}
                    </RecordButton>
                  </div>
                </OptionallyVisible>

                <OptionallyVisible visible={index !== documents.length - 1}>
                  <Divider />
                </OptionallyVisible>
              </Container>
            );
          })}
        </Section>
      </Block>

      <RecordButton type="button" flat onClick={addItem}>
        <IconAdd />
        {t(LABELS.SECTIONS.DOCUMENTS.ADD)}
      </RecordButton>
    </>
  );
};

export default Documents;
