import styled from '@emotion/styled';
import { Link as ReactLink } from 'react-router-dom';

import { ButtonWithLoading } from 'components/buttons/buttonWithLoading';

export const SectionWrapper = styled.div((props) => ({
  display: 'flex',
  padding: 10,
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 15,
  alignSelf: 'stretch',
}));

export const Image = styled.img(() => ({
  maxWidth: 16,
  maxHeight: 16,
}));

export const CryptoBlock = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

export const Item = styled.div((props) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 20,
}));

export const ItemName = styled.div((props) => ({
  width: 150,
  minWidth: 150,
  color: props.theme.colors.inactive,
  fontSize: 13,
  fontWeight: 400,
}));

export const ItemValue = styled.div((props) => ({
  fontSize: 13,
  fontWeight: 400,
}));

export const Delimiter = styled.div((props) => ({
  height: 0.5,
  background: props.theme.colors.container.tertiary,
  width: '100%',
}));

export const AmountBlock = styled.div((props) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 8,
}));

export const AmountValue = styled.div((props) => ({
  fontSize: 13,
  fontWeight: 400,
}));

export const AmountNumericValue = styled.div((props) => ({
  fontVariantNumeric: 'lining-nums tabular-nums',
}));

export const OnChainTransactionHash = styled.div<{ displayFullHash?: boolean }>((props) => ({
  whiteSpace: 'nowrap',
  maxWidth: props.displayFullHash ? 'none' : 250,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: 13,
  fontWeight: 400,
  '& a': {
    color: props.theme.colors.text.accent,
  },
}));

export const Link = styled(ReactLink)((props) => ({
  color: props.theme.colors.accent,
}));

export const OrderItems = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 15,
});

export const OrderItemDetails = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

export const OrderItemImage = styled.img({
  width: 22,
  height: 22,
  borderRadius: 4,
  objectFit: 'cover',
});

export const OrderItemName = styled.div(({ theme }) => ({
  overflow: 'hidden',
  maxWidth: '320px',
}));

export const UnlockButton = styled(ButtonWithLoading)(() => ({
  marginTop: -3,
  padding: 3,
}));

export const CancelButton = styled(UnlockButton)(() => ({
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 16,
  paddingRight: 16,
  alignSelf: 'center',
}));
