import { OrderItemType, UnmatchedDepositAmountPolicy } from 'types/pwcOrder';

import { makeLabels } from 'utils/i18n';

export enum AvailableOrderDuration {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
}

export const FORM_FIELDS = {
  CURRENCY: 'fiatCurrencyCode',
  FIAT_AMOUNT: 'fiatAmount',
  ITEMS: 'items',
  CUSTOMER: 'customer',
  EXTERNAL_CUSTOMER_ID: 'externalCustomerId',
  PASS_PROCESSING_FEE: 'passProcessingFeeToCustomer',
  EXPIRATION_TIME: 'orderTtl',
  UNMATCHED_DEPOSIT_POLICY: 'unmatchedDepositAmountPolicy',
  EXTERNAL_ORDER_ID: 'externalOrderId',
  REDIRECT_URL: 'redirectUrl',
  CANCEL_REDIRECT_URL: 'cancelRedirectUrl',
  WEBHOOK_URL: 'webhookUrl',
  LOCKED: 'locked',
} as const;

export const ITEM_FORM_FIELDS = {
  NAME: 'name',
  FIAT_AMOUNT: 'fiatAmount',
  IMAGE_URL: 'imageUrl',
} as const;

export const CUSTOMER_FORM_FIELDS = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
} as const;

export const uploadFieldTypes = [ITEM_FORM_FIELDS.IMAGE_URL];

export type FormFieldType = (typeof FORM_FIELDS)[keyof typeof FORM_FIELDS];
export type ItemFormFieldType = (typeof ITEM_FORM_FIELDS)[keyof typeof ITEM_FORM_FIELDS];
export type CustomerFormFieldType = (typeof CUSTOMER_FORM_FIELDS)[keyof typeof CUSTOMER_FORM_FIELDS];
type FormLabels = Record<FormFieldType, [string, string]>;
type ItemFormLabels = Record<ItemFormFieldType, [string, string]>;
type CustomerFormLabels = Record<CustomerFormFieldType, [string, string]>;

export const DURATION_LABELS: Record<AvailableOrderDuration, [string, string]> = {
  [AvailableOrderDuration.Minutes]: ['minutes', 'Minutes'],
  [AvailableOrderDuration.Hours]: ['hours', 'Hours'],
  [AvailableOrderDuration.Days]: ['days', 'Days'],
};

export const UNMATCHED_DEPOSIT_POLICY_LABELS: Record<UnmatchedDepositAmountPolicy, [string, string]> = {
  [UnmatchedDepositAmountPolicy.INCOMPLETE_PAYMENT]: ['incompletePaymentPolicy', 'Incomplete Payment'],
  [UnmatchedDepositAmountPolicy.FLEXIBLE_AMOUNT]: ['flexibleAmountPolicy', 'Flexible Amount'],
  [UnmatchedDepositAmountPolicy.AUTOMATIC_REFUND]: ['automaticRefundPolicy', 'Automatic Refund'],
};

const CUSTOMER_FORM_LABELS: CustomerFormLabels = {
  [CUSTOMER_FORM_FIELDS.EMAIL]: ['customerEmail', 'Email'],
  [CUSTOMER_FORM_FIELDS.FIRST_NAME]: ['customerFirstName', 'First Name'],
  [CUSTOMER_FORM_FIELDS.LAST_NAME]: ['customerLastName', 'Last Name'],
};

const CUSTOMER_FORM_PLACEHOLDERS: CustomerFormLabels = {
  [CUSTOMER_FORM_FIELDS.EMAIL]: ['customerEmailPlaceholder', 'Enter Customer Email'],
  [CUSTOMER_FORM_FIELDS.FIRST_NAME]: ['customerFirstNamePlaceholder', 'Enter First Name'],
  [CUSTOMER_FORM_FIELDS.LAST_NAME]: ['customerLastNamePlaceholder', 'Enter Last Name'],
};

export const CUSTOMER_FORM_INITIAL_VALUES: Record<CustomerFormFieldType, any> = {
  [CUSTOMER_FORM_FIELDS.EMAIL]: '',
  [CUSTOMER_FORM_FIELDS.FIRST_NAME]: '',
  [CUSTOMER_FORM_FIELDS.LAST_NAME]: '',
};

const FORM_LABELS: FormLabels = {
  [FORM_FIELDS.CURRENCY]: ['currency', 'Currency'],
  [FORM_FIELDS.FIAT_AMOUNT]: ['fiatAmount', 'Order Amount'],
  [FORM_FIELDS.ITEMS]: ['items', 'Product or Service'],
  [FORM_FIELDS.CUSTOMER]: ['customer', 'Customer Details'],
  [FORM_FIELDS.EXTERNAL_CUSTOMER_ID]: ['externalCustomerId', 'External Customer ID'],
  [FORM_FIELDS.PASS_PROCESSING_FEE]: ['passProcessingFeeToCustomer', 'Processing Fee'],
  [FORM_FIELDS.EXPIRATION_TIME]: ['expirationTime', 'Order Expiration Time'],
  [FORM_FIELDS.UNMATCHED_DEPOSIT_POLICY]: ['unmatchedDepositAmountPolicy', 'Unmatched Deposit Policy'],
  [FORM_FIELDS.EXTERNAL_ORDER_ID]: ['externalOrderId', 'External Order ID'],
  [FORM_FIELDS.REDIRECT_URL]: ['redirectUrl', 'Redirect URL'],
  [FORM_FIELDS.CANCEL_REDIRECT_URL]: ['cancelRedirectUrl', 'Cancel Redirect URL'],
  [FORM_FIELDS.WEBHOOK_URL]: ['webhookUrl', 'Webhook URL'],
  [FORM_FIELDS.LOCKED]: ['locked', 'Locked'],
};

const FORM_PLACEHOLDERS: FormLabels = {
  [FORM_FIELDS.CURRENCY]: ['currencyPlaceholder', ''],
  [FORM_FIELDS.FIAT_AMOUNT]: ['fiatAmountPlaceholder', 'Enter Order Total Amount'],
  [FORM_FIELDS.ITEMS]: ['itemsPlaceholder', ''],
  [FORM_FIELDS.CUSTOMER]: ['customerPlaceholder', ''],
  [FORM_FIELDS.EXTERNAL_CUSTOMER_ID]: ['externalCustomerIdPlaceholder', 'Enter External Customer ID'],
  [FORM_FIELDS.PASS_PROCESSING_FEE]: ['passProcessingFeeToCustomerPlaceholder', 'Passing Processing Fee To Customer'],
  [FORM_FIELDS.EXPIRATION_TIME]: ['expirationTimePlaceholder', 'Expired in'],
  [FORM_FIELDS.UNMATCHED_DEPOSIT_POLICY]: ['unmatchedDepositAmountPolicyPlaceholder', ''],
  [FORM_FIELDS.EXTERNAL_ORDER_ID]: ['externalOrderIdPlaceholder', 'Enter Merchant Order ID'],
  [FORM_FIELDS.REDIRECT_URL]: ['redirectUrlPlaceholder', 'Enter Successful Redirect URL'],
  [FORM_FIELDS.CANCEL_REDIRECT_URL]: ['cancelRedirectUrlPlaceholder', 'Enter Cancel Redirect URL'],
  [FORM_FIELDS.WEBHOOK_URL]: ['webhookUrlPlaceholder', ''],
  [FORM_FIELDS.LOCKED]: ['lockedPlaceholder', 'Lock Order'],
};

export const FORM_INITIAL_VALUES: Record<FormFieldType, any> = {
  [FORM_FIELDS.CURRENCY]: 'USD',
  [FORM_FIELDS.FIAT_AMOUNT]: '',
  [FORM_FIELDS.ITEMS]: [],
  [FORM_FIELDS.CUSTOMER]: CUSTOMER_FORM_INITIAL_VALUES,
  [FORM_FIELDS.EXTERNAL_CUSTOMER_ID]: '',
  [FORM_FIELDS.PASS_PROCESSING_FEE]: false,
  [FORM_FIELDS.EXPIRATION_TIME]: '',
  [FORM_FIELDS.UNMATCHED_DEPOSIT_POLICY]: UnmatchedDepositAmountPolicy.INCOMPLETE_PAYMENT,
  [FORM_FIELDS.EXTERNAL_ORDER_ID]: '',
  [FORM_FIELDS.REDIRECT_URL]: '',
  [FORM_FIELDS.CANCEL_REDIRECT_URL]: '',
  [FORM_FIELDS.WEBHOOK_URL]: '',
  [FORM_FIELDS.LOCKED]: false,
};

export const ITEM_FORM_INITIAL_VALUES: Record<ItemFormFieldType, any> = {
  [ITEM_FORM_FIELDS.NAME]: '',
  [ITEM_FORM_FIELDS.FIAT_AMOUNT]: null,
  [ITEM_FORM_FIELDS.IMAGE_URL]: '',
};

const ITEM_FORM_LABELS: ItemFormLabels = {
  [ITEM_FORM_FIELDS.NAME]: ['name', 'Product/Service Name'],
  [ITEM_FORM_FIELDS.FIAT_AMOUNT]: ['fiatAmount', 'Price'],
  [ITEM_FORM_FIELDS.IMAGE_URL]: ['imageUrl', 'Product/Service Image'],
};

const ITEM_FORM_PLACEHOLDERS: ItemFormLabels = {
  [ITEM_FORM_FIELDS.NAME]: ['namePlaceholder', 'Enter Item Name'],
  [ITEM_FORM_FIELDS.FIAT_AMOUNT]: ['fiatAmountPlaceholder', 'Enter Item Amount'],
  [ITEM_FORM_FIELDS.IMAGE_URL]: ['imageUrlPlaceholder', ''],
};

const ITEM_FORM_LABELS_FOR_TYPE: Partial<Record<OrderItemType, [string, string]>> = {
  [OrderItemType.Tax]: ['taxOrderItemLabel', 'Tax Amount'],
  [OrderItemType.Delivery]: ['deliveryOrderItemLabel', 'Delivery Fee Amount'],
  [OrderItemType.Tip]: ['tipOrderItemLabel', 'Tip Amount'],
  [OrderItemType.Custom]: ['customOrderItemLabel', 'Custom'],
};

const ITEM_FORM_PLACEHOLDERS_FOR_TYPE: Partial<Record<OrderItemType, [string, string]>> = {
  [OrderItemType.Tax]: ['taxOrderItemPlaceholder', 'Enter Tax Amount'],
  [OrderItemType.Delivery]: ['deliveryOrderItemPlaceholder', 'Enter Delivery Fee Amount'],
  [OrderItemType.Tip]: ['tipOrderItemPlaceholder', 'Enter Tip Amount'],
  [OrderItemType.Custom]: ['customOrderItemPlaceholder', 'Enter Custom Amount'],
};

export const INITIAL_NAMES_FOR_TAX_ITEMS: Partial<Record<OrderItemType, string>> = {
  [OrderItemType.Tax]: 'Tax',
  [OrderItemType.Delivery]: 'Delivery',
  [OrderItemType.Tip]: 'Tip',
};

export const URL_PLACEHOLDER = 'https://';

const NAMESPACE = 'page.paymentByLink.form';
export const LABELS = makeLabels(
  {
    ORDER_DETAILS_PANEL_TITLE: ['orderDetailsPanelTitle', 'Order Details'],
    ORDER_SUMMARY_PANEL_TITLE: ['orderSummaryPanelTitle', 'Order Summary'],
    ITEM_DESCRIPTION: ['itemDescription', 'Product or Service'],
    TAX_DESCRIPTION: ['taxDescription', 'Tax'],
    CUSTOMER_DETAILS_PANEL_TITLE: ['customerDetailsPanelTitle', 'Customer Details'],
    ADVANCED_SETTINGS_PANEL_TITLE: ['advancedSettingsPanelTitle', 'Advanced Settings'],
    FORM_FIELDS: {
      LABELS: FORM_LABELS,
      PLACEHOLDERS: FORM_PLACEHOLDERS,
    },
    ITEM_FORM_FIELDS: {
      LABELS: ITEM_FORM_LABELS,
      PLACEHOLDERS: ITEM_FORM_PLACEHOLDERS,
      LABELS_FOR_TYPE: ITEM_FORM_LABELS_FOR_TYPE,
      PLACEHOLDERS_FOR_TYPE: ITEM_FORM_PLACEHOLDERS_FOR_TYPE,
      CUSTOM_NAME_PLACEHOLDER: ['customNamePlaceholder', 'Enter Custom Name'],
    },
    CUSTOMER_FORM_FIELDS: {
      LABELS: CUSTOMER_FORM_LABELS,
      PLACEHOLDERS: CUSTOMER_FORM_PLACEHOLDERS,
    },
    FILE_UPLOAD: {
      SUPPORTED_FORMAT: ['supportedFormat', 'Supported Formats: JPG, PNG'],
      MAX_FILE_SIZE: ['maxFileSize', 'Maximum Size: 1MB'],
    },
    ACTIONS: {
      ADD_PRODUCT: ['addProduct', 'ADD PRODUCT OR SERVICE'],
      REMOVE_PRODUCT: ['removeProduct', 'REMOVE PRODUCT OR SERVICE'],
      ADD_CUSTOM: ['addCustom', 'ADD CUSTOM FEE'],
      REMOVE_CUSTOM: ['removeCustom', 'REMOVE CUSTOM FEE'],
    },
  },
  NAMESPACE,
);

export const BREADCRUMBS = [['breadcrumbs', 'Payment by Link']];

export default LABELS;
