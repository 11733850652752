import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, LABELS } from '../../../../keys';
import { BridgeAccountPurpose, SourceOfFundsType } from '../../../../types';
import replaceLabelsUnderscore from '../../../../utils/replaceLabelsUnderscore';
import { Optional } from '../../styles';
import { Container, Label } from './styles';

interface Props {
  // path to the field in the formik values, must point to a field of an array type: IdentifyingInformationType[]
  fieldPath: string;
  //  for errors coming from backend
  error?: Record<string, string>;
}

const ACCOUNT_PURPOSE: SelectItem[] = Object.entries(BridgeAccountPurpose).map(([key, value]) => ({
  key: value,
  label: key,
}));

const formattedAccountPurpose = replaceLabelsUnderscore(ACCOUNT_PURPOSE);

const AccountPurpose = ({ fieldPath, error }: Props) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext<typeof FORMIK_INITIAL_VALUES>();

  const accountPurpose = `${fieldPath}`;
  const accountPurposeError = getIn(errors, `${fieldPath}.key`);
  const accountPurposeTouched = getIn(touched, fieldPath);

  const accountPurposeInformationValue = getIn(values, fieldPath) as SourceOfFundsType;

  return (
    <Container>
      <Label>
        {t(LABELS.SECTIONS.ACCOUNT_PURPOSE.TITLE)} <Optional>{t(LABELS.SECTIONS.FIELD_OPTIONAL)}</Optional>
      </Label>

      <FormField error={error?.[fieldPath]}>{/* showing BE errors at the top, move it? */}</FormField>

      <FormField error={accountPurposeError && accountPurposeTouched ? accountPurposeError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={accountPurposeInformationValue}
          items={formattedAccountPurpose}
          placeholder={t(LABELS.SECTIONS.ACCOUNT_PURPOSE.PLACEHOLDER)}
          onChange={(selectItem) => {
            setFieldValue(accountPurpose, selectItem);
          }}
          getItemLabel={(item) => item.label}
          multiple={false}
          closeDropdownOnSelect
          popperProps={{ disablePortal: true }}
        />
      </FormField>
    </Container>
  );
};

export default AccountPurpose;
