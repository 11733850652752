import styled from '@emotion/styled/macro';

import { Path, Svg } from 'components/icons/styles';

export const GreyText = styled.span((props) => ({
  display: 'inline',
  fontSize: 13,
  lineHeight: '13px',
  color: props.theme.colors.inactive,
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  whiteSpace: 'nowrap',
}));

export const CryptoCurrency = styled.span((props) => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 5,

  span: {
    display: 'inline-flex',
    minWidth: 40,
    justifyContent: 'flex-end',
  },
}));

export const FiatCurrency = styled.span((props) => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 5,

  span: {
    display: 'inline-flex',
    minWidth: 30,
    justifyContent: 'flex-end',
  },
}));

export const FilterContainer = styled.div(() => ({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
}));

export const Text = styled.span((props) => ({
  fontWeight: 'inherit',
  color: 'inherit',
  fontSize: 14,
  lineHeight: '17px',
}));

export const FilterButton = styled.button<{ active: boolean }>((props) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 10,
  minWidth: 200,
  border: `0.5px solid ${props.theme.colors.container.tertiary}`,
  backgroundColor: props.active ? props.theme.colors.highlight : props.theme.colors.background,
  color: props.active ? props.theme.colors.accent : props.theme.colors.inactive,
  fontWeight: props.active ? 600 : 400,
  borderRadius: 5,
}));

export const ActionButton = styled.button<{ isOpen?: boolean }>((props) => ({
  padding: '9.5px 10px',
  backgroundColor: props.theme.colors.transparent,
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  fontSize: 12,
  border: 0,
  textAlign: 'left',
  lineHeight: '14.5px',
  color: 'red',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: props.theme.colors.highlight,
  },
}));

export const ArrowIcon = styled.div<{ rotated: boolean }>((props) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.primary,
  },
  // @ts-ignore
  [Svg]: {
    transition: 'all .3s ease',
    transform: props.rotated ? '' : 'rotate(180deg)',
  },
}));
