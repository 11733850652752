import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { getDefaultDatePresets } from 'components/filters/date/keys';
import {
  AmountFilterPassedProps,
  CheckboxFilterPassedProps,
  CurrencyFilterPassedProps,
  DateFilterPassedProps,
  RadioFilterPassedProps,
  SelectFilterPassedProps,
} from 'components/filters/types';
import { CurrencyItem } from 'components/inputs/select/currencySelect/types';
import { SelectItem } from 'components/inputs/select/select';

import { Filter, FilterSection, FilterType } from 'types/filters';

import { COLUMN_IDS, LABELS, filterStatusGroups } from './keys';
import { Filters, RequestFilters } from './types';

export const makeFilterSections = (filterMap: Record<string, Filter<unknown>>, isAdmin: boolean): FilterSection[] => {
  return [
    {
      key: 'filters',
      content: [filterMap.statuses, filterMap.locked, filterMap.date],
    },
    {
      key: 'currencyFilters',
      content: [filterMap.cryptoCurrencyCode, filterMap.cryptoAmount, filterMap.fiatCurrencyCode, filterMap.fiatAmount],
    },
    ...(isAdmin
      ? [
          {
            key: 'partnerFilters',
            content: [filterMap.merchantUuids],
          },
        ]
      : []),
  ];
};

export const makeFilters = ({
  cryptoOptions,
  fiatOptions,
  merchantAccounts,
}: {
  cryptoOptions: CurrencyItem[];
  fiatOptions: CurrencyItem[];
  merchantAccounts: SelectItem[];
}): Record<string, Filter<unknown>> => {
  const statusFilter: Filter<CheckboxFilterPassedProps> = {
    key: 'statuses',
    label: t(LABELS.FILTERS.STATUS.LABEL),
    type: FilterType.Checkbox,
    options: [
      {
        key: 'completed',
        value: filterStatusGroups.completed,
        label: t(LABELS.FILTERS.STATUS.COMPLETED),
      },
      {
        key: 'confirmed',
        value: filterStatusGroups.confirmed,
        label: t(LABELS.FILTERS.STATUS.CONFIRMED),
      },
      {
        key: 'initiated',
        value: filterStatusGroups.initiated,
        label: t(LABELS.FILTERS.STATUS.INITIATED),
      },
      {
        key: 'pending',
        value: filterStatusGroups.pending,
        label: t(LABELS.FILTERS.STATUS.PENDING),
      },
      {
        key: 'canceled',
        value: filterStatusGroups.canceled,
        label: t(LABELS.FILTERS.STATUS.CANCELED),
      },
      {
        key: 'expired',
        value: filterStatusGroups.expired,
        label: t(LABELS.FILTERS.STATUS.EXPIRED),
      },
      {
        key: 'incomplete',
        value: filterStatusGroups.incomplete,
        label: t(LABELS.FILTERS.STATUS.INCOMPLETE),
      },
    ],
  };

  const lockedFilter: Filter<RadioFilterPassedProps> = {
    key: COLUMN_IDS.LOCKED,
    label: t(LABELS.FILTERS.LOCKED.LABEL),
    type: FilterType.Radio,
    options: [
      {
        key: 'yes',
        value: 'true',
        label: t(LABELS.FILTERS.LOCKED.YES),
      },
      {
        key: 'no',
        value: 'false',
        label: t(LABELS.FILTERS.LOCKED.NO),
      },
    ],
  };

  const datePresets = getDefaultDatePresets();

  const dateFilter: Filter<DateFilterPassedProps> = {
    key: 'date',
    label: t(LABELS.FILTERS.DATE.LABEL),
    type: FilterType.Date,
    presets: datePresets,
  };

  const cryptoCurrencyFilter: Filter<CurrencyFilterPassedProps> = {
    key: 'cryptoCurrencyCode',
    type: FilterType.CurrencySelect,
    label: t(LABELS.FILTERS.CRYPTO_CURRENCY.LABEL),
    options: cryptoOptions,
    multiple: false,
  };

  const cryptoCurrencyAmountFilter: Filter<AmountFilterPassedProps> = {
    key: 'cryptoAmount',
    type: FilterType.Amount,
    label: t(LABELS.FILTERS.PAID_AMOUNT.LABEL),
  };

  const fiatCurrencyFilter: Filter<CurrencyFilterPassedProps> = {
    key: 'fiatCurrencyCode',
    type: FilterType.CurrencySelect,
    label: t(LABELS.FILTERS.FIAT_CURRENCY.LABEL),
    options: fiatOptions,
    multiple: false,
  };

  const fiatCurrencyAmountFilter: Filter<AmountFilterPassedProps> = {
    key: 'fiatAmount',
    type: FilterType.Amount,
    label: t(LABELS.FILTERS.ORDER_AMOUNT.LABEL),
  };

  const accountFilter: Filter<SelectFilterPassedProps> = {
    key: 'merchantUuids',
    label: t(LABELS.FILTERS.MERCHANT.LABEL),
    type: FilterType.Select,
    placeholder: t(LABELS.FILTERS.MERCHANT.PLACEHOLDER),
    multiple: false,
    options: merchantAccounts,
  };

  return [
    statusFilter,
    lockedFilter,
    dateFilter,
    cryptoCurrencyFilter,
    cryptoCurrencyAmountFilter,
    fiatCurrencyFilter,
    fiatCurrencyAmountFilter,
    accountFilter,
  ].reduce((acc, cur) => {
    return { ...acc, [cur.key]: cur };
  }, {});
};

export const prepareFilters = ({ locked, ...filters }: Filters) => {
  const output: RequestFilters = {
    ...filters,
  };

  if (filters.merchantUuids) {
    output.merchantUuids = Array.isArray(filters.merchantUuids) ? filters.merchantUuids : [filters.merchantUuids];
  }

  if (locked && !isEmpty(locked)) {
    const [value] = locked;
    output.locked = value ? value === 'true' : false;
  }

  return output;
};
