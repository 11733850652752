import { FieldArray } from 'formik';
import React from 'react';

import { SelectItem } from 'components/inputs/select/select';

import { FORM_FIELDS } from '../../keys';
import AssociatedPerson from './components/associatedPerson';

interface StepFormProps {
  countries: SelectItem[];
  error?: Record<string, string>;
}

export default ({ error, countries }: StepFormProps) => {
  return (
    <FieldArray
      render={({ insert, remove }) => (
        <AssociatedPerson
          error={error}
          insertPerson={insert}
          removePerson={remove}
          countries={countries}
          fieldPath={FORM_FIELDS.ASSOCIATED_PERSONS}
        />
      )}
      name={FORM_FIELDS.ASSOCIATED_PERSONS}
    />
  );
};
