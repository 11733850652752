import { Switch } from '@mui/material';
import { getIn, useFormikContext } from 'formik';
import React from 'react';

import { FormType } from '../../keys';
import { Label, Wrapper } from './styles';

interface Props {
  //  path to the field in the formik values
  fieldPath: string;
  label?: string;
}

const FormikSwitch = ({ fieldPath, label }: Props) => {
  const { values, setFieldValue } = useFormikContext<FormType>();

  const inputValue = getIn(values, fieldPath);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFieldValue(fieldPath, checked);
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Switch checked={Boolean(inputValue)} onChange={handleChange} />
    </Wrapper>
  );
};

export default FormikSwitch;
