import { useEffect, useState } from 'react';

import { TablePagination } from 'types/pagination';

import { getDefaultPagination } from '../pagination';

const paginationStorage: Record<string, TablePagination> = {};

export const usePersistentPagination = (defaultState: TablePagination, dependencies: any[], key?: string) => {
  const [pagination, setPagination] = useState<TablePagination>((key && paginationStorage[key]) || defaultState || getDefaultPagination());

  useEffect(() => {
    const newState = {
      ...pagination,
      page: 1,
    };
    setPagination(newState);
    if (key) {
      paginationStorage[key] = newState;
    }
  }, dependencies);

  const changePage = (page: number) => {
    const newState = {
      ...pagination,
      page,
    };
    setPagination(newState);
    if (key) {
      paginationStorage[key] = newState;
    }
  };

  const changeRowsPerPage = (itemsPerPage: number) => {
    const newState = {
      ...pagination,
      itemsPerPage,
      page: 1,
    };
    setPagination(newState);
    if (key) {
      paginationStorage[key] = newState;
    }
  };

  return {
    pagination,
    setPagination,
    changePage,
    changeRowsPerPage,
  };
};
