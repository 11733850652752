import { useSelector } from 'react-redux';
import { useLazyGetMerchantsListQuery } from 'services/merchants';
import pwcOrdersApi from 'services/pwcOrders';
import { useUploadAssetMutation } from 'services/staticAssets';

import { SelectItem } from 'components/inputs/select/select';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { APIError } from 'types/error';
import { RequestPagination, ServerPagination } from 'types/pagination';
import { CreateOrderParams } from 'types/pwcOrder';
import { UserAccessLevel } from 'types/user';

import PaymentByLinkCreate from '.';

const PaymentByLinkContainer = () => {
  const { account } = useSelector(selectAccount);
  const [createOrderMutation, { isLoading, error }] = pwcOrdersApi.useCreateOrderMutation();
  const [uploadAsset, { isLoading: isAssetUploading, reset: resetUpload }] = useUploadAssetMutation();
  const userLevel = useSelector(selectUserLevel);
  const canCreateOrdersForDifferenMerchants = Boolean(userLevel && userLevel > UserAccessLevel.Support);
  const accountsMerchantUuid = canCreateOrdersForDifferenMerchants ? undefined : account.uuid;

  const [fetchMerchants] = useLazyGetMerchantsListQuery();

  const createOrder = (payload: Omit<CreateOrderParams, 'merchantUuid'>, merchantUuid?: string) => {
    if (!merchantUuid && !accountsMerchantUuid) {
      throw new Error('Merchant UUID is required');
    }
    return createOrderMutation({ ...payload, merchantUuid: merchantUuid || accountsMerchantUuid! });
  };

  const getMerchants = async (params: {
    search?: string;
    pagination?: RequestPagination;
  }): Promise<{ data: SelectItem[]; pagination?: ServerPagination }> => {
    const query: { search?: string; pagination?: RequestPagination } = {
      pagination: params.pagination,
    };

    if (params.search) {
      query.search = params.search;
    }

    const response = await fetchMerchants(query);

    if ('data' in response) {
      const records: SelectItem[] = response.data!.data.map((item) => ({
        key: item.uuid,
        label: item.name,
      }));
      return {
        data: records,
        pagination: response.data!.pagination,
      };
    }

    return { data: [] };
  };

  return (
    <PaymentByLinkCreate
      getMerchants={getMerchants}
      showMerchantSelection={canCreateOrdersForDifferenMerchants}
      readOnlyMode={false}
      orderCreation={{
        isLoading,
        error,
        createOrder: createOrder as (payload: Omit<CreateOrderParams, 'merchantUuid'>) => Promise<any>,
      }}
      assetUpload={{
        uploadAsset: uploadAsset as (file: File) => Promise<{ data: string; error?: { data: APIError } }>,
        isAssetUploading,
        resetUpload,
      }}
    />
  );
};

export default PaymentByLinkContainer;
