import { makeLabels } from 'utils/i18n';

const NAMESPACE = 'routes';
export const LABELS = makeLabels(
  {
    PAYMENT_BY_LINK_MENU_ITEM: ['menuItems.pwc.paymentByLink', 'Payment by Link'],
  },
  NAMESPACE,
);

export default LABELS;
