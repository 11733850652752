import styled from '@emotion/styled/macro';

import { COLUMN_IDS } from './keys';

export const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  padding: '5px 0',
  [`& [data-column="${COLUMN_IDS.LAST_UPDATE_AT}"]`]: {
    minWidth: 180,
  },
  [`
  & [data-column="${COLUMN_IDS.ID}"],
  & [data-column="${COLUMN_IDS.TYPE}"]
  `]: {
    minWidth: 120,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`
  & [data-column="${COLUMN_IDS.CRYPTO_AMOUNT}"],
  & [data-column="${COLUMN_IDS.FIAT_AMOUNT}"]
  `]: {
    maxWidth: 300,
    textAlign: 'right',
    '& .MuiTableSortLabel-root': {
      flexDirection: 'row-reverse',
    },
  },
  [`
  & [data-column="${COLUMN_IDS.STATUS}"]
  `]: {
    minWidth: 100,
  },
}));
