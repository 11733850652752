import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetReconciliationsListQuery } from 'services/reconciliations';
import { ReconciliationListQuery } from 'services/reconciliations/types';

import { DataTableRow } from 'components/dataTable';

import RoutePath from 'router/path';

import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { usePersistentPagination } from 'utils/tables/pagination';
import { useDebouncedQuery } from 'utils/tables/useDebouncedQuery';

import { SettlementReconciliationList } from './reconciliationList';
import { ReconciliationDataRow } from './types';

export const ReconciliationListContainer = () => {
  const navigate = useNavigate();

  const { pagination, changeRowsPerPage, changePage, setPagination } = usePersistentPagination(getDefaultPagination(), []);
  const reconciliationsListQuery: ReconciliationListQuery = useDebouncedQuery(() => {
    const baseQuery: ReconciliationListQuery = {
      pagination: undecoratePagination(pagination),
    };

    return baseQuery;
  }, [pagination]);
  const { data, isFetching } = useGetReconciliationsListQuery(reconciliationsListQuery);

  useEffect(() => {
    if (data?.pagination) {
      setPagination(decoratePagination(data?.pagination));
    }
  }, [data?.pagination]);

  const reconciliations: ReconciliationDataRow[] = (data?.data ?? []).map((order) => {
    return {
      ...order,
      id: order.uuid,
    };
  });

  const navigateToReconciliation = ({ uuid }: DataTableRow) => navigate(RoutePath.ReconciliationsById(uuid));

  return (
    <SettlementReconciliationList
      pagination={pagination}
      reconciliations={reconciliations}
      isLoading={isFetching}
      onChangePage={changePage}
      onChangeRowsPerPage={changeRowsPerPage}
      onRowClick={navigateToReconciliation}
    />
  );
};
