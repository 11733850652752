import React, { FunctionComponent } from 'react';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { formatTableDate } from 'utils/date';

export const DateCell: FunctionComponent<DataTableCellProps> = ({ row, column }) => {
  const columnName = column.id;
  const cellValue = row[columnName];

  return <TableCell align="left">{formatTableDate(cellValue)}</TableCell>;
};
