import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

export const KEYS = {
  DATE_FORMAT: 'MMM DD, HH:mm A',
  CRYPTO_SYMBOL_SEPARATOR: '.',
  CRYPTO_NETWORK_SPACING_SYMBOLS_EXP: /[_]/gi,
  CRYPTO_AMOUNT_PLACEHOLDER: '-',
  CHARGE_TYPE: 'Charge',
};

export const LABELS = {
  TABLE_COLUMNS: {
    ID: ['page.pwcOrders.view.sections.chargesList.table.columns.id', 'ID'],
    TYPE: ['page.pwcOrders.view.sections.chargesList.table.columns.type', 'Type'],
    CRYPTO_AMOUNT: ['page.pwcOrders.view.sections.chargesList.table.columns.cryptoAmount', 'Crypto Amount'],
    FIAT_AMOUNT: ['page.pwcOrders.view.sections.chargesList.table.columns.fiatAmount', 'Fiat Amount'],
    LAST_UPDATE_AT: ['page.pwcOrders.view.sections.chargesList.table.columns.lastUpdateAt', 'Last Update At'],
    // Cell placeholder for status column. Update this value when the status is available.
    STATUS: ['page.pwcOrders.view.sections.chargesList.table.columns.status', ''],
    ACTIONS: ['page.pwcOrders.view.sections.chargesList.table.columns.actions', ''],
  },
};

export const COLUMN_IDS = {
  ID: 'uuid',
  TYPE: 'type',
  CRYPTO_AMOUNT: 'cryptoAmount',
  FIAT_AMOUNT: 'fiatAmount',
  LAST_UPDATE_AT: 'lastUpdateAt',
  FIAT_CURRENCY: 'fiatCurrency',
  CRYPTO_CURRENCY: 'cryptoCurrency',
  STATUS: 'status',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.ID, label: t(LABELS.TABLE_COLUMNS.ID) },
  { id: COLUMN_IDS.TYPE, label: t(LABELS.TABLE_COLUMNS.TYPE) },
  { id: COLUMN_IDS.CRYPTO_AMOUNT, label: t(LABELS.TABLE_COLUMNS.CRYPTO_AMOUNT) },
  { id: COLUMN_IDS.FIAT_AMOUNT, label: t(LABELS.TABLE_COLUMNS.FIAT_AMOUNT) },
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS) },
  { id: COLUMN_IDS.LAST_UPDATE_AT, label: t(LABELS.TABLE_COLUMNS.LAST_UPDATE_AT), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];
