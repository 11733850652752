import styled from '@emotion/styled';

export const Header = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const PanelContent = styled.div(() => ({
  padding: 10,
}));

export const CreatedOrderWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
  padding: 20,
  paddingBottom: 40,
  '& a': {
    color: theme.colors.primary,
    fontWeight: 500,
  },
}));

export const RemoteSelectWrapper = styled.div(() => ({
  width: 290,
}));

export const PostOrderCreationActions = styled.div(() => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'center',
}));
