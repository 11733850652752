import React, { FunctionComponent } from 'react';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { formatTableDate } from 'utils/date';

export const DateCell: FunctionComponent<DataTableCellProps> = ({ row, column }) => {
  return <TableCell align="left">{formatTableDate(row[column.id])}</TableCell>;
};
