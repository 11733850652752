import { makeLabels } from 'utils/i18n';

export const KEYS = {
  CREATE_ORDER_CURRENCY: 'USD',
};

const NAMESPACE = 'page.paymentByLink';
export const LABELS = makeLabels(
  {
    PAGE_TITLE: ['title', 'Create Order'],
    PAGE_TITLE_SUCCESS: ['titleSuccess', 'Order Created'],
    CONTROL_LABEL: ['controlLabel', 'Create'],
    ORDER_CREATION_ERROR: ['orderCreationError', 'Unable to create order'],
    ORDER_CREATED_BUTTON_ACTION: ['orderCreatedButtonAction', 'Copy Link'],
    CREATE_NEW_ORDER_ACTION: ['createNewOrderAction', 'Create New Order'],
    MERCHANT_SELECTION: ['merchantSelection', 'Select Merchant'],
    MERCHANT_SELECT_PLACEHOLDER: ['merchantSelectionPlaceholder', 'Select Merchant'],
    MERCHANT_SELECT_LABEL: ['merchantSelectLabel', 'Merchant'],
  },
  NAMESPACE,
);

export const BREADCRUMBS = [['breadcrumbs', 'Payment by Link']];

export default LABELS;
