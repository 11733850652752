import styled from '@emotion/styled';

import { Button } from 'components/buttons';

export const Label = styled.div((props) => ({
  marginTop: 8,
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const RecordButton = styled(Button)<{ inside?: boolean }>((props) => ({
  textTransform: 'uppercase',
  marginLeft: props.inside ? 0 : 9,
  marginTop: props.inside ? 0 : 12,
}));

export const Container = styled.div((props) => ({
  width: '100%',
}));

export const RemoveContainer = styled.div((props) => ({
  marginTop: 18,
}));

export const Title = styled(Label)((props) => ({
  marginTop: 0,
}));
