import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { OptionallyVisible, PageWrapper } from 'components';
import { useFormik } from 'formik';
import { omitBy } from 'lodash';
import { useNotifications } from 'providers/notifications/useNotifications';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PwcOrderCreationResponse } from 'services/pwcOrders/types';

import { Button } from 'components/buttons';
import { ButtonWithLoading } from 'components/buttons/buttonWithLoading';
import { CopyButton } from 'components/buttons/copy';
import { Panel } from 'components/containers';
import { FieldTitle, FormRow } from 'components/form/formLayout/styles';
import { RemoteSelect } from 'components/inputs/select/remoteSelect';
import { SelectItem } from 'components/inputs/select/select';

import { APIError, ValidationErrorMeta } from 'types/error';
import { NotificationSeverity } from 'types/notifications';
import { CreateOrderParams, OrderItem, OrderItemType } from 'types/pwcOrder';

import { getValidationDetails } from 'utils/error';

import AdvancedSettingsForm from './form/advancedSettings';
import CustomerDetails from './form/customerDetails';
import { FORM_INITIAL_VALUES, FormFieldType } from './form/keys';
import OrderDetailsForm from './form/orderDetails';
import OrderSummary from './form/orderSummary';
import { ImageUploadOutcome, Item } from './form/types';
import LABELS, { BREADCRUMBS } from './keys';
import { CreatedOrderWrapper, Header, PanelContent, PostOrderCreationActions, RemoteSelectWrapper } from './styles';

interface PaymentByLinkCreateProps {
  showMerchantSelection: boolean;
  readOnlyMode: boolean;
  orderCreation: {
    isLoading: boolean;
    error?: unknown;
    createOrder: (
      payload: Omit<CreateOrderParams, 'merchantId'>,
      merchantUuid?: string,
    ) => Promise<{ error?: { data: APIError } } & { data: PwcOrderCreationResponse }>;
  };
  assetUpload: {
    uploadAsset: (file: File) => Promise<{ data: string; error?: { data: APIError } }>;
    isAssetUploading: boolean;
    resetUpload: () => void;
  };
  getMerchants: (params: { search?: string; pagination?: { itemsPerPage: number; page: number } }) => Promise<{ data: SelectItem[] }>;
}

const PaymentByLinkCreate = ({
  getMerchants,
  showMerchantSelection,
  readOnlyMode,
  orderCreation,
  assetUpload,
}: PaymentByLinkCreateProps) => {
  const { pushToast } = useNotifications();
  const { t } = useTranslation();
  const breadcrumbs = BREADCRUMBS.map((breadcrumb) => t(breadcrumb));
  const [uploadOutcomes, setUploadOutcomes] = useState<ImageUploadOutcome[]>([]);
  const isActionBusy = orderCreation.isLoading || assetUpload.isAssetUploading;
  const [createdOrder, setCreatedOrder] = useState<PwcOrderCreationResponse | undefined>();
  const [merchantUuid, setMerchantUuid] = useState<string | undefined>();
  const isActionDisabled = !merchantUuid && showMerchantSelection;

  const getToastErrorMessage = (error?: { data: APIError }) => {
    if (error?.data.errorMessage) {
      return `${t(LABELS.ORDER_CREATION_ERROR)}: ${error.data.errorMessage}`;
    }

    return t(LABELS.ORDER_CREATION_ERROR);
  };

  const uploadFormAssets = async (values: Record<FormFieldType, any>): Promise<ImageUploadOutcome[]> => {
    const itemsWithFiles = values.items.filter((item: Item) => item.file);
    const results: ImageUploadOutcome[] = [];
    await Promise.all(
      itemsWithFiles.map(async (item: Item) => {
        const result = await assetUpload.uploadAsset(item.file!);
        if (result.error) {
          results.push({ id: item.id, error: result.error.data.errorMessage });
          return;
        }

        results.push({ id: item.id, imageUrl: result.data });
      }),
    );

    return results;
  };

  const isEmptyValue = (value: any) => {
    const hasValue = value === undefined || value === '' || value === null;
    const hasAnyNestedValues = typeof value === 'object' && Object.keys(value).length === 0;

    return hasValue || hasAnyNestedValues;
  };

  const onSubmit = async (values: Record<FormFieldType, any>) => {
    const uploadResults = await uploadFormAssets(values);
    const uploadErrors = uploadResults.filter((result) => result.error);
    if (uploadErrors.length) {
      setUploadOutcomes(uploadErrors);
      return;
    }

    const submitValues = omitBy(
      {
        ...values,
        fiatAmount: parseFloat(values.fiatAmount),
        items: values.items
          .filter((item: OrderItem) => item.fiatAmount || item.imageUrl || (item.name && item.type === OrderItemType.Product))
          .map(({ id, file, ...item }: Item) => ({
            ...item,
            imageUrl: uploadResults.find((result) => result.id === id)?.imageUrl,
          })),

        customer: omitBy(values.customer, isEmptyValue),
      },
      isEmptyValue,
    );

    const result = await orderCreation.createOrder(submitValues as unknown as Omit<CreateOrderParams, 'merchantId'>, merchantUuid);
    if (result.error) {
      pushToast({
        severity: NotificationSeverity.error,
        message: getToastErrorMessage(result.error),
      });

      const meta = result.error ? ((result.error as FetchBaseQueryError).data as { meta: ValidationErrorMeta })?.meta : undefined;
      const creationError = meta ? getValidationDetails(meta) : undefined;
      orderCreationForm.setErrors(creationError || {});
    }

    setCreatedOrder(result.data);
  };

  const orderCreationForm = useFormik({
    initialValues: FORM_INITIAL_VALUES,
    onSubmit,
  });

  const onCreateClick = () => {
    orderCreationForm.submitForm();
  };

  const onFieldChange = (field: FormFieldType, value: any) => {
    orderCreationForm.setFieldValue(field, value);
    orderCreationForm.setFieldError(field, undefined);
  };

  const onMerchantChange = (item: SelectItem, _?: string) => {
    setMerchantUuid(item.key);
  };

  const startOver = () => {
    orderCreationForm.resetForm();
    setCreatedOrder(undefined);
  };

  if (createdOrder) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs}>
        <Header>{t(LABELS.PAGE_TITLE)}</Header>
        <Panel label={t(LABELS.PAGE_TITLE_SUCCESS)}>
          <CreatedOrderWrapper>
            <a target="_blank" href={createdOrder.checkoutUrl}>
              {createdOrder.checkoutUrl}
            </a>
            <PostOrderCreationActions>
              <CopyButton value={createdOrder.checkoutUrl} secondary>
                {t(LABELS.ORDER_CREATED_BUTTON_ACTION)}
              </CopyButton>
              <Button secondary onClick={startOver}>
                {t(LABELS.CREATE_NEW_ORDER_ACTION)}
              </Button>
            </PostOrderCreationActions>
          </CreatedOrderWrapper>
        </Panel>
      </PageWrapper>
    );
  }
  return (
    <PageWrapper breadcrumbs={breadcrumbs}>
      <Header>
        {t(LABELS.PAGE_TITLE)}
        <OptionallyVisible visible={!readOnlyMode}>
          <ButtonWithLoading isLoading={isActionBusy} onClick={onCreateClick} primary disabled={readOnlyMode || isActionDisabled}>
            {t(LABELS.CONTROL_LABEL)}
          </ButtonWithLoading>
        </OptionallyVisible>
      </Header>
      <OptionallyVisible visible={showMerchantSelection}>
        <Panel label={t(LABELS.MERCHANT_SELECTION)}>
          <PanelContent>
            <FormRow>
              <FieldTitle>{t(LABELS.MERCHANT_SELECT_LABEL)}</FieldTitle>
              <RemoteSelectWrapper>
                <RemoteSelect<SelectItem, false>
                  fullWidth
                  value={merchantUuid ?? ''}
                  getItems={getMerchants}
                  placeholder={t(LABELS.MERCHANT_SELECT_PLACEHOLDER)}
                  onChange={onMerchantChange}
                  getItemLabel={(item) => item.label}
                  closeDropdownOnSelect
                  leaveSearchValueOnClose
                />
              </RemoteSelectWrapper>
            </FormRow>
          </PanelContent>
        </Panel>
      </OptionallyVisible>
      <OrderDetailsForm
        readOnlyMode={isActionBusy}
        values={orderCreationForm.values}
        setFieldValue={onFieldChange}
        error={orderCreationForm.errors}
      />
      <OrderSummary
        readOnlyMode={isActionBusy}
        errors={orderCreationForm.errors}
        uploadErrors={uploadOutcomes}
        setFieldValue={onFieldChange}
      />
      <CustomerDetails
        readOnlyMode={isActionBusy}
        errors={orderCreationForm.errors}
        values={orderCreationForm.values}
        setFieldValue={onFieldChange}
      />
      <AdvancedSettingsForm
        readOnlyMode={isActionBusy}
        errors={orderCreationForm.errors}
        values={orderCreationForm.values}
        setFieldValue={onFieldChange}
      />
    </PageWrapper>
  );
};

export default PaymentByLinkCreate;
