import React from 'react';

import DataTable from 'components/dataTable/dataTable';

import { OrderPwcData } from '../../types';
import { ChargeDetails } from '../chargeDetails/chargeDetails';
import { ChargeTableCell } from './components/chargeTableCell';
import { COLUMNS, KEYS } from './keys';
import { Container } from './styles';

export interface OrderDetailsProps {
  orderPwcData: OrderPwcData;
  shouldShowExchangeDetailsSection: boolean;
  shouldShowSettlementDetailsSection: boolean;
  shouldShowPayoutSection: boolean;
}

export const ChargesListSection = ({
  orderPwcData,
  shouldShowExchangeDetailsSection,
  shouldShowSettlementDetailsSection,
  shouldShowPayoutSection,
}: OrderDetailsProps) => {
  const { charges, general } = orderPwcData;
  const { fiatCurrencyCode } = general;

  const formattedCharges = charges.map((charge) => {
    return {
      id: charge.uuid,
      uuid: charge.uuid,
      type: KEYS.CHARGE_TYPE,
      cryptoAmount: charge.cryptoPayment.cryptoAmount,
      fiatAmount: charge.cryptoExchange.fiatAmount,
      lastUpdateAt: charge.updatedAt,
      fiatCurrency: fiatCurrencyCode,
      cryptoCurrency: charge.cryptoPayment.cryptoCurrencyCode,
      expandable: 'expandable',
    };
  });

  return (
    <Container>
      <DataTable
        columns={COLUMNS}
        rows={formattedCharges}
        CellComponent={ChargeTableCell}
        isExpandable
        expandableComponent={
          <ChargeDetails
            orderPwcData={orderPwcData}
            shouldShowExchangeDetailsSection={shouldShowExchangeDetailsSection}
            shouldShowSettlementDetailsSection={shouldShowSettlementDetailsSection}
            shouldShowPayoutSection={shouldShowPayoutSection}
          />
        }
      />
    </Container>
  );
};
