import styled from '@emotion/styled/macro';

import { FileDropzone as OriginalFileDropzone } from 'components/inputs/file/dropzone';

export const FileDropzone = styled(OriginalFileDropzone)<{ darkMode?: boolean; error?: string }>((props) => {
  if (props.darkMode && props.initialFile) {
    return {
      backgroundColor: props.theme.colors.primary,
      '&:hover': {
        backgroundColor: props.theme.colors.primary,
      },
    };
  }

  return {};
});
