import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps } from 'components/dataTable';
import { CopyMenuItem, NavigateMenuItem } from 'components/dataTable/cells';
import { ActionMenu } from 'components/dataTable/cells/actionMenu';
import { TableCell } from 'components/dataTable/tableCell';

import RoutePath from 'router/path';

import { formatTableDate } from 'utils/date';

import LABELS, { COLUMN_IDS } from '../keys';

const DateAndYearCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return <TableCell>{formatTableDate(value)}</TableCell>;
};

export const ActionCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();

  const { uuid, email } = row;

  return (
    <TableCell>
      <ActionMenu title={t(LABELS.ACTIONS.TITLE)}>
        <NavigateMenuItem to={RoutePath.pwc.customerById(uuid)}>{t(LABELS.ACTIONS.OPEN_CUSTOMER_DETAILS)}</NavigateMenuItem>
        <CopyMenuItem value={uuid}>{t(LABELS.ACTIONS.COPY_CUSTOMER_ID)}</CopyMenuItem>
        <CopyMenuItem value={email}>{t(LABELS.ACTIONS.COPY_CUSTOMER_EMAIL)}</CopyMenuItem>
      </ActionMenu>
    </TableCell>
  );
};

const DefaultCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return <TableCell data-column={column.id}>{value}</TableCell>;
};

const cellComponentMap: Record<string, FunctionComponent<DataTableCellProps>> = {
  [COLUMN_IDS.EMAIL]: DefaultCell,
  [COLUMN_IDS.CUSTOMER_ID]: DefaultCell,
  [COLUMN_IDS.CREATED_AT]: DateAndYearCell,
  [COLUMN_IDS.LAST_PURCHASED_AT]: DateAndYearCell,
  [COLUMN_IDS.ACTIONS]: ActionCell,
  default: DefaultCell,
};

export const CustomerTableCell = (props: DataTableCellProps) => {
  const { column } = props;
  const Component = cellComponentMap[column.id] || cellComponentMap.default;
  return <Component {...props} />;
};
