import { OptionallyVisible } from 'components';
import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import IconRemove from 'components/icons/dash';
import { Input, InputType } from 'components/inputs';
import FileDropzone from 'components/inputs/file/dropzone';

import LABELS, { ITEM_FORM_FIELDS, ItemFormFieldType } from '../keys';
import { ImageUploadOutcome, Item } from '../types';
import { KEYS, acceptedImages } from './keys';
import { FormWrapper, RemoveButton } from './styles';

interface OrderDetailsFormProps {
  readOnlyMode: boolean;
  item: Item;
  onItemChange: (item: Omit<Item, 'type'>) => void;
  error: FormikErrors<Record<ItemFormFieldType, string>>;
  uploadError?: ImageUploadOutcome;
  onRemoveItem: () => void;
  deleteable?: boolean;
}

const OrderSummaryItem = ({ readOnlyMode, item, onItemChange, error, uploadError, onRemoveItem, deleteable }: OrderDetailsFormProps) => {
  const { t } = useTranslation();

  const onChange = (fieldName: ItemFormFieldType) => (value: string) => {
    onItemChange({
      ...item,
      [fieldName]: value,
    });
  };

  const onFileChange = (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    onItemChange({
      ...item,
      file: files[0],
    });
  };

  const fields: FormFieldDescription[] = [
    {
      key: ITEM_FORM_FIELDS.NAME,
      label: t(LABELS.ITEM_FORM_FIELDS.LABELS[ITEM_FORM_FIELDS.NAME]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={item.name}
          onChange={onChange(ITEM_FORM_FIELDS.NAME)}
          disabled={readonly}
          placeholder={t(LABELS.ITEM_FORM_FIELDS.PLACEHOLDERS[ITEM_FORM_FIELDS.NAME])}
        />
      ),
    },
    {
      key: ITEM_FORM_FIELDS.FIAT_AMOUNT,
      label: t(LABELS.ITEM_FORM_FIELDS.LABELS[ITEM_FORM_FIELDS.FIAT_AMOUNT]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Number}
          value={item.fiatAmount?.toString()}
          onChange={onChange(ITEM_FORM_FIELDS.FIAT_AMOUNT)}
          disabled={readonly}
          placeholder={t(LABELS.ITEM_FORM_FIELDS.PLACEHOLDERS[ITEM_FORM_FIELDS.FIAT_AMOUNT])}
        />
      ),
    },
    {
      key: ITEM_FORM_FIELDS.IMAGE_URL,
      label: t(LABELS.ITEM_FORM_FIELDS.LABELS[ITEM_FORM_FIELDS.IMAGE_URL]),
      renderInput: ({ readonly }) => (
        <FileDropzone
          onFileChange={onFileChange}
          initialFile={item.imageUrl ?? undefined}
          hints={[t(LABELS.FILE_UPLOAD.SUPPORTED_FORMAT), t(LABELS.FILE_UPLOAD.MAX_FILE_SIZE)]}
          accept={acceptedImages}
          disabled={readonly}
          maxFileSize={KEYS.MAX_FILE_SIZE}
          error={uploadError?.error}
        />
      ),
    },
  ];

  return (
    <FormWrapper>
      <FormLayout fields={fields} readonly={readOnlyMode} error={error as Record<string, string>} />
      <OptionallyVisible visible={deleteable}>
        <RemoveButton flat onClick={onRemoveItem}>
          <IconRemove />
          <span> {t(LABELS.ACTIONS.REMOVE_PRODUCT)}</span>
        </RemoveButton>
      </OptionallyVisible>
    </FormWrapper>
  );
};

export default OrderSummaryItem;
