import { ReactNode } from 'react';

export interface FormFieldInputProps {
  readonly?: boolean;
}

export interface FormFieldDescription {
  key: string;
  label: string | string[];
  renderInput: (props: FormFieldInputProps) => string | ReactNode | ReactNode[];
  hidden?: boolean;
  fullLabelWidth?: boolean;
  valueLabelWidth?: number;
  formRowItem?: ReactNode;
}

export enum FormFieldType {
  File = 'file',
  Text = 'text',
  Color = 'color',
  Boolean = 'boolean',
  Custom = 'custom',
}
