import { OptionallyVisible } from 'components';
import { Dayjs } from 'dayjs';
import { getIn, useFormikContext } from 'formik';
import React from 'react';

import { FormField } from 'components/form/formField/formField';
import { DatePicker } from 'components/inputs/date';

import { convertValueToDayjs } from 'utils/date';

import { FormType, KEYS } from '../../keys';
import { Label } from './styles';

interface Props {
  //  path to the field in the formik values
  fieldPath: string;
  placeholder?: string;
  label?: string;
  //  for errors coming from backend
  error?: Record<string, string>;
  errorPath?: string;
}

const FormikCalendar = ({ fieldPath, placeholder, error, errorPath = fieldPath, label }: Props) => {
  const { values, touched, errors, setFieldValue } = useFormikContext<FormType>();

  const inputValue = getIn(values, fieldPath);
  const inputError = getIn(errors, fieldPath);
  const inputTouched = getIn(touched, fieldPath);

  const handleDateChange = (fieldPath: string, value: Dayjs | null) => {
    setFieldValue(fieldPath, value ? value.format(KEYS.DEFAULT_DATE_FORMAT) : null);
  };

  return (
    <>
      <OptionallyVisible visible={Boolean(label)}>
        <Label>{label}</Label>
      </OptionallyVisible>

      <FormField error={error?.[errorPath] || (inputError && inputTouched ? inputError : undefined)}>
        <DatePicker
          inputLabel={placeholder}
          value={convertValueToDayjs(inputValue)}
          onChange={(value) => handleDateChange(fieldPath, value)}
          format={KEYS.DEFAULT_DATE_FORMAT}
          mask={KEYS.DEFAULT_DATE_MASK}
        />
      </FormField>
    </>
  );
};

export default FormikCalendar;
