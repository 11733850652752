import React from 'react';

import OptionallyVisible from 'components/optionallyVisible';

import { Container, SubContainer } from '../../styles';
import { OrderPwcData } from '../../types';
import { ExchangeDetailsSection } from '../exchangeDetails/exchangeDetails';
import { PaymentSection } from '../payment/payment';
import { PayoutSection } from '../payout/payout';
import { SettlementDetailsSection } from '../settlementDetails/settlementDetails';

export interface OrderDetailsProps {
  orderPwcData: OrderPwcData;
  shouldShowExchangeDetailsSection: boolean;
  shouldShowSettlementDetailsSection: boolean;
  shouldShowPayoutSection: boolean;
}

export const ChargeDetails = ({
  orderPwcData,
  shouldShowExchangeDetailsSection,
  shouldShowSettlementDetailsSection,
  shouldShowPayoutSection,
}: OrderDetailsProps) => {
  const sectionsProps = {
    orderData: orderPwcData,
  };

  return (
    <Container>
      <SubContainer>
        <PaymentSection {...sectionsProps} />

        <OptionallyVisible visible={shouldShowSettlementDetailsSection}>
          <SettlementDetailsSection {...sectionsProps} />
        </OptionallyVisible>
      </SubContainer>

      <SubContainer>
        <OptionallyVisible visible={shouldShowExchangeDetailsSection}>
          <ExchangeDetailsSection {...sectionsProps} />
        </OptionallyVisible>

        <OptionallyVisible visible={shouldShowPayoutSection}>
          <PayoutSection {...sectionsProps} />
        </OptionallyVisible>
      </SubContainer>
    </Container>
  );
};
