import styled from '@emotion/styled/macro';

import { Buttons } from 'components/modal/styles';

export const ModalRoot = styled.div((props) => ({
  outline: 'none',
}));

export const StyledButtons = styled(Buttons)((props) => ({
  marginTop: 12,
}));
