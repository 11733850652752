import styled from '@emotion/styled';

export const Label = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  marginBottom: 8,
}));

export const Wrapper = styled.div((props) => ({}));
