import dayjs from 'dayjs';

const KEYS = {
  TABLE_DATE_FORMAT: 'MMM DD, HH:mm A',
  TABLE_DATE_FORMAT_WITH_YEAR: 'MMM DD, YYYY, HH:mm A',
  LOCAL_DATE_OPTIONS: {
    timeStyle: 'medium',
    dateStyle: 'medium',
  },
};

export const convertValueToDayjs = (value: string | null | undefined) => (value ? dayjs(value) : null);

export const formatDateLocal = (value: string | null | undefined) =>
  value ? new Intl.DateTimeFormat(undefined, KEYS.LOCAL_DATE_OPTIONS as Intl.DateTimeFormatOptions).format(new Date(value)) : null;

export const formatTableDate = (value: string | null | undefined) => {
  if (!value) {
    return null;
  }

  const now = dayjs();
  const dateValue = convertValueToDayjs(value);
  const isSameYear = now.year() === dateValue?.year();
  const format = isSameYear ? KEYS.TABLE_DATE_FORMAT : KEYS.TABLE_DATE_FORMAT_WITH_YEAR;

  return convertValueToDayjs(value)?.format(format);
};
