import { LoadingIndicatorProps } from 'components/loadingIndicator/loadingIndicator';

import LoadingIndicator from '../loadingIndicator';
import { Button, ButtonProps } from './button';

export interface ButtonWithLoadingProps extends ButtonProps {
  isLoading?: boolean;
  loadingIndicatorProps?: Partial<LoadingIndicatorProps>;
}

export const ButtonWithLoading = ({ loadingIndicatorProps, isLoading, children, disabled, ...buttonProps }: ButtonWithLoadingProps) => {
  const content = isLoading ? <LoadingIndicator size={0.8} {...loadingIndicatorProps} /> : children;
  return (
    <Button {...buttonProps} disabled={isLoading || disabled}>
      {content}
    </Button>
  );
};
