import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';

import { Country } from 'types/country';
import { Customer, CustomerKYCStatus, CustomerStatus, CustomerTerminationReason, LastLoginInfo } from 'types/customer';

import { formatDateLocal } from 'utils/date';

import { KYCStatusBadge } from '../components/kycStatusBadge/kycStatusBadge';
import { CountryField } from './components/countryField/country';
import { InfoForm } from './components/form/form';
import { KycReasonField } from './components/kycReasonField/kycReason';
import { KycRetryField } from './components/kycRetryField/kycRetry';
import { StatusBar } from './components/statusBar/statusBar';
import { StatusField } from './components/statusField/status';
import { KEYS, LABELS, customerFormFields, customerLocationFormFields, extraFields, lastLoginFormFields } from './keys';
import { Container, KYCStatusContainer, RefreshButton, ToSTimeContainer } from './styles';
import { InfoFormField } from './types';
import { formatBoolean, getCountry, getState } from './util';

interface CustomerViewProps {
  customer: Customer;
  countries: Country[];
  kycProps: {
    kycStatus: CustomerKYCStatus;
    updateKYCStatus: () => void;
    isUpdating: boolean;
    allowKYCRetry: () => void;
    isUpdatingRetryCount: boolean;
  };
  statusProps: {
    updateStatus: (nextStatus: CustomerStatus, options?: { reasonCode?: CustomerTerminationReason; notes?: string }) => Promise<boolean>;
    isUpdating: boolean;
    canChangeStatus: boolean;
    isTerminationAvailable: boolean;
  };
}

export const CustomerView = ({ customer, countries, kycProps, statusProps }: CustomerViewProps) => {
  const { t } = useTranslation();
  const { isUpdating, updateKYCStatus } = kycProps;
  const lastLogin = customer[customerFormFields.lastLogin] as LastLoginInfo | null;

  const customerFields: InfoFormField[] = [
    {
      id: customerFormFields.email,
      label: t(LABELS.CUSTOMER.EMAIL),
      content: customer[customerFormFields.email] as string,
    },
    {
      id: customerFormFields.id,
      label: t(LABELS.CUSTOMER.USER_ID),
      content: customer[customerFormFields.id] as string,
    },
    {
      id: customerFormFields.participantCode,
      label: t(LABELS.CUSTOMER.PARTICIPANT_CODE),
      content: customer[customerFormFields.participantCode] as string,
    },
    {
      id: customerFormFields.signupDate,
      label: t(LABELS.CUSTOMER.SIGNUP_DATE),
      content: formatDateLocal(customer[customerFormFields.signupDate] as string),
    },
    {
      id: customerFormFields.lastLogin,
      label: t(LABELS.CUSTOMER.LAST_LOGIN),
      content: formatDateLocal(lastLogin?.loginAt),
    },
    {
      id: customerFormFields.lastLogin,
      label: t(LABELS.CUSTOMER.LAST_PARTNER_LOGIN),
      content: lastLogin?.account?.name,
    },
    {
      id: customerFormFields.acceptedToS,
      label: t(LABELS.CUSTOMER.TERMS_OF_SERVICE),
      content: (
        <ToSTimeContainer>
          <Badge type={BadgeType.Default}>{t(formatBoolean(customer[customerFormFields.acceptedToS] as boolean))}</Badge>
          <OptionallyVisible visible={Boolean(customer[customerFormFields.acceptedToS])}>
            {formatDateLocal(customer[customerFormFields.tosAcceptedAt] as string)}
          </OptionallyVisible>
        </ToSTimeContainer>
      ),
    },
    {
      id: customerFormFields.status,
      label: t(LABELS.CUSTOMER.STATUS),
      content: <StatusField status={customer[customerFormFields.status] as CustomerStatus} {...statusProps} />,
    },
    {
      id: customerFormFields.kycStatus,
      label: t(LABELS.CUSTOMER.KYC_STATUS),
      content: (
        <KYCStatusContainer>
          <KYCStatusBadge status={customer[customerFormFields.kycStatus] as CustomerKYCStatus} />
          <RefreshButton secondary={!isUpdating} flat={isUpdating} onClick={updateKYCStatus} disabled={isUpdating}>
            {t(isUpdating ? LABELS.CUSTOMER.UPDATING_KYC_STATUS : LABELS.CUSTOMER.UPDATE_KYC_STATUS)}
          </RefreshButton>
        </KYCStatusContainer>
      ),
    },
    {
      id: customerFormFields.kycReason,
      hidden: customer[customerFormFields.kycStatus] !== CustomerKYCStatus.Rejected,
      label: t(LABELS.CUSTOMER.KYC_REASON),
      content: <KycReasonField reason={customer[customerFormFields.kycReason] as string} />,
    },
    {
      id: customerFormFields.kycLastUpdate,
      hidden: !customer[customerFormFields.kycLastUpdate],
      label: t(LABELS.CUSTOMER.KYC_LAST_UPDATE),
      content: formatDateLocal(customer[customerFormFields.kycLastUpdate] as string),
    },
    {
      id: customerFormFields.statusChangeNotes,
      hidden: customer[customerFormFields.status] === CustomerStatus.Active,
      label: t(LABELS.CUSTOMER.BLOCKED_REASON),
      content: customer[customerFormFields.statusChangeNotes] as string,
    },
    {
      id: customerFormFields.kycAttemptsUsed,
      hidden: customer[customerFormFields.kycStatus] === CustomerKYCStatus.Approved,
      label: t(LABELS.CUSTOMER.KYC_ATTEMPTS),
      content: customer[customerFormFields.kycAttemptsUsed] as string,
    },
    {
      id: customerFormFields.isKycRetryAllowed,
      hidden: customer[customerFormFields.kycStatus] === CustomerKYCStatus.Approved,
      label: t(LABELS.CUSTOMER.CAN_RETRY_KYC),
      content: (
        <KycRetryField
          isUpdating={kycProps.isUpdatingRetryCount}
          canRetry={customer[customerFormFields.isKycRetryAllowed] as boolean}
          retryKyc={kycProps.allowKYCRetry}
        />
      ),
    },
  ];

  const country = getCountry(countries, customer[customerLocationFormFields.country] as string);
  const state = getState(country, customer[customerLocationFormFields.state] as string);
  const ipCountry = getCountry(countries, lastLogin?.ipCountry);
  const ipState = getState(ipCountry, lastLogin?.ipState);
  const isCountrySupported = [country?.isSupported, ipCountry?.isSupported].filter((item) => item !== undefined).every(Boolean);

  const isStateSupported = [state?.isAllowed, ipState?.isAllowed].filter((item) => item !== undefined).every(Boolean);
  const isStateHidden = !country || country.isoAlpha2 !== KEYS.US_CODE;
  const isIPStateHidden = !ipCountry || ipCountry.isoAlpha2 !== KEYS.US_CODE;

  const locationFields: InfoFormField[] = [
    {
      id: customerLocationFormFields.country,
      label: t(LABELS.LOCATION.COUNTRY),
      content: <CountryField country={country} />,
    },
    {
      id: customerLocationFormFields.state,
      hidden: isStateHidden,
      label: t(LABELS.LOCATION.STATE),
      content: state?.name,
    },
    {
      id: lastLoginFormFields.ipCountry,
      hidden: !ipCountry,
      label: t(LABELS.LOCATION.IP_COUNTRY),
      content: <CountryField country={ipCountry} />,
    },
    {
      id: lastLoginFormFields.ipState,
      hidden: isIPStateHidden,
      label: t(LABELS.LOCATION.IP_STATE),
      content: ipState?.name,
    },
    {
      id: extraFields.countrySupported,
      hidden: !country && !ipCountry,
      label: t(LABELS.LOCATION.COUNTRY_SUPPORTED),
      content: <Badge type={BadgeType.Default}>{t(formatBoolean(Boolean(isCountrySupported)))}</Badge>,
    },
    {
      id: extraFields.stateSupported,
      hidden: isStateHidden && isIPStateHidden,
      label: t(LABELS.LOCATION.STATE_SUPPORTED),
      content: <Badge type={BadgeType.Default}>{t(formatBoolean(Boolean(isStateSupported)))}</Badge>,
    },
  ];

  return (
    <>
      <StatusBar kycStatus={kycProps.kycStatus} customerStatus={customer.status} userId={customer.uuid} />
      <Container>
        <Panel label={t(LABELS.CUSTOMER.TITLE)}>
          <InfoForm fields={customerFields} />
        </Panel>
        <Panel label={t(LABELS.LOCATION.TITLE)}>
          <InfoForm fields={locationFields} />
        </Panel>
      </Container>
    </>
  );
};
