export const KEYS = {
  CRYPTO_NETWORK_FEE_DIGITS: 6,
  TAG_OR_MEMO_SPLIT_STRING: '?tag=',
  BASE_CURRENCY_UNIT: 1,
  APPROX_EQUAL_SYMBOL: '≈',
  NO_PARTNER: '-',
};

export const LABELS = {
  BREADCRUMBS: [
    ['page.orders.view.breadcrumbs.orders', 'Orders'],
    ['page.orders.view.breadcrumbs.orderDetails', 'Order details'],
  ],
  PAGE_TITLE: ['page.orders.view.title', 'Order details'],
  SECTIONS: {
    ORDER: {
      TITLE: ['page.orders.view.sections.order.title', 'Order'],
      SIDE: ['page.orders.view.sections.order.side', 'Type'],
      ORDER_ID: ['page.orders.view.sections.order.orderId', 'Order ID'],
      EXTERNAL_ORDER_ID: ['page.orders.view.sections.order.externalOrderId', 'External Order ID'],
      PARTNER_ACCOUNT_ID: ['page.orders.view.sections.order.partnerAccountId', 'Partner Account ID'],
      DATE: ['page.orders.view.sections.order.date', 'Date'],
      STATUS: ['page.orders.view.sections.order.status', 'Status'],
      CRYPTO_AMOUNT: ['page.orders.view.sections.order.cryptoAmount', 'Crypto Amount'],
      FIAT_AMOUNT: ['page.orders.view.sections.order.fiatAmount', 'Fiat Amount'],
      PROCESSING_FEE_AMOUNT: ['page.orders.view.sections.order.processingFeeAmount', 'Processing Fee Amount'],
      PROCESSING_FEE_PERCENT: ['page.orders.view.sections.order.processingFeePercent', 'Processing Fee %'],
      PARTNER_FEE_AMOUNT: ['page.orders.view.sections.order.partnerFeeAmount', 'Partner Fee Amount'],
      PARTNER_FEE_PERCENT: ['page.orders.view.sections.order.partnerFeePercent', 'Partner Fee %'],
      NETWORK_FEE: ['page.orders.view.sections.order.networkFee', 'Network Fee'],
      EXCHANGED_AMOUNT: ['page.orders.view.sections.order.exchangedAmount', 'Exchanged Amount'],
      CRYPTO_PRICE: ['page.orders.view.sections.order.cryptoPrice', 'Crypto Price'],
      FIAT_AMOUNT_IN_USD: ['page.orders.view.sections.order.fiatAmountInUSD', 'Fiat Amount in USD'],
      PROCESSING_FEE_FIAT_AMOUNT_IN_USD: ['page.orders.view.sections.order.processingFeeFiatAmountInUSD', 'Processing Fee Amount in USD'],
      PARTNER_FEE_FIAT_AMOUNT_IN_USD: ['page.orders.view.sections.order.partnerFeeFiatAmountInUSD', 'Partner Fee Amount in USD'],
      RECEIPT: ['page.orders.view.sections.order.receipt', 'Receipt'],
      SHOW_RECEIPT: ['page.orders.view.sections.order.showReceipt', 'Show'],
    },
    EXCHANGE_DETAILS: {
      TITLE: ['page.orders.view.sections.exchangeDetails.title', 'Exchange details'],
      QUOTE_ID: ['page.orders.view.sections.exchangeDetails.quoteId', 'Quote ID'],
      QUOTED_CURRENCY: ['page.orders.view.sections.exchangeDetails.quotedCurrency', 'Quoted Currency'],
      FIAT_CONVERSION_RATE: ['page.orders.view.sections.exchangeDetails.fiatConversionRate', 'Fiat Conversion Rate'],
      QUOTED_TOTAL_FIAT_AMOUNT: ['page.orders.view.sections.exchangeDetails.quotedTotalFiatAmount', 'Quoted Total Fiat Amount'],
      QUOTED_NETWORK_FEE: ['page.orders.view.sections.exchangeDetails.quotedNetworkFee', 'Quoted Network Fee'],
      NETWORK_FEE_CRYPTO_AMOUNT: ['page.orders.view.sections.exchangeDetails.networkFeeCryptoAmount', 'Network Fee Crypto Amount'],
      QUOTED_EXCHANGED_AMOUNT: ['page.orders.view.sections.exchangeDetails.quotedExchangedAmount', 'Quoted Exchanged Amount'],
      QUOTED_CRYPTO_PRICE: ['page.orders.view.sections.exchangeDetails.quotedCryptoPrice', 'Quoted Crypto Price'],
      TRADE_STATUS: ['page.orders.view.sections.exchangeDetails.tradeStatus', 'Trade Status'],
    },
    EXCHANGE_DETAILS_IN_USD: {
      TITLE: ['page.orders.view.sections.exchangeDetailsInUsd.title', 'USD Amounts'],
      FIAT_AMOUNT: ['page.orders.view.sections.exchangeDetailsInUsd.fiatAmount', 'Fiat Amount'],
      PROCESSING_FEE_AMOUNT: ['page.orders.view.sections.exchangeDetailsInUsd.processingFeeAmount', 'Processing Fee Amount'],
      PARTNER_FEE_AMOUNT: ['page.orders.view.sections.exchangeDetailsInUsd.partnerFeeAmount', 'Partner Fee Amount'],
      QUOTED_NETWORK_FEE: ['page.orders.view.sections.exchangeDetailsInUsd.quotedNetworkFee', 'Quoted Network Fee'],
      EXCHANGED_AMOUNT: ['page.orders.view.sections.exchangeDetailsInUsd.exchangedAmount', 'Exchanged Amount'],
    },
    CUSTOMER: {
      TITLE: ['page.orders.view.sections.customer.title', 'Customer'],
      USER_EMAIL: ['page.orders.view.sections.customer.userEmail', 'User Email'],
      USER_ID: ['page.orders.view.sections.customer.userId', 'User ID'],
      EXTERNAL_USER_ID: ['page.orders.view.sections.customer.externalUserId', 'External User ID'],
      PARTICIPANT_CODE: ['page.orders.view.sections.customer.participantCode', 'Participant Code'],
    },
    PAYMENT: {
      TITLE: ['page.orders.view.sections.payment.title', 'Payment'],
      MERCHANT_ID: ['page.orders.view.sections.payment.merchantId', 'Merchant ID'],
      SHIFT4_CHARGE_ID: ['page.orders.view.sections.payment.shift4ChargeId', 'Shift4 Charge ID'],
      PAYMENT_TYPE: ['page.orders.view.sections.payment.paymentType', 'Payment Type'],
      PAYMENT_METHOD: ['page.orders.view.sections.payment.paymentMethod', 'Payment Method'],
      CARD_DETAIL: ['page.orders.view.sections.payment.cardDetail', 'Card Detail'],
      FAILED_STATUS_REASON: ['page.orders.view.sections.payment.failedStatusReason', 'Failed Status Reason'],
      CAPTURED: ['page.orders.view.sections.payment.captured', 'Captured'],
      REFUNDED: ['page.orders.view.sections.payment.refunded', 'Refunded'],
      DISPUTED: ['page.orders.view.sections.payment.disputed', 'Disputed'],
      STATUS: ['page.orders.view.sections.payment.status', 'Payment Status'],
      DECLINE_ERROR_CODE: ['page.orders.view.sections.payment.declineCode', 'Decline Error Code'],
    },
    WITHDRAWAL: {
      TITLE: ['page.orders.view.sections.withdrawal.title', 'Withdrawal'],
      WALLET_ADDRESS: ['page.orders.view.sections.withdrawal.walletAddress', 'Wallet Address'],
      WALLET_MEMO_TAG: ['page.orders.view.sections.withdrawal.walletMemoTag', 'Wallet Memo/Tag'],
      WITHDRAWAL_STATUS: ['page.orders.view.sections.withdrawal.withdrawalStatus', 'Withdrawal Status'],
      TRANSACTION_HASH: ['page.orders.view.sections.withdrawal.transactionHash', 'Transaction Hash'],
    },
    DEPOSIT: {
      TITLE: ['page.orders.view.sections.deposit.title', 'Deposit'],
      WALLET_ADDRESS: ['page.orders.view.sections.deposit.walletAddress', 'From Wallet Address'],
      WALLET_MEMO_TAG: ['page.orders.view.sections.deposit.walletMemoTag', 'Wallet Memo/Tag'],
      DEPOSIT_STATUS: ['page.orders.view.sections.deposit.depositStatus', 'Deposit Status'],
      TRANSACTION_HASH: ['page.orders.view.sections.deposit.transactionHash', 'Transaction Hash'],
    },
    TOP_AREA: {
      ORDER_ID: ['page.orders.view.sections.statusBar.orderId', 'Order ID'],
      ORDER_TOTAL: ['page.orders.view.sections.statusBar.orderTotal', 'Order total'],
      STATUS: ['page.orders.view.sections.statusBar.status', 'Status'],
      TYPE: ['page.orders.view.sections.statusBar.type', 'Type'],
    },
  },
};
