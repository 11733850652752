import styled from '@emotion/styled';

export const Container = styled.div((props) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

export const Label = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  marginBottom: 8,
}));

export const Delimeter = styled.div((props) => ({
  borderBottom: `1px solid ${props.theme.colors.container.primary}`,
  width: '100%',
  margin: '8px 0',
}));

export const Optional = styled.span((props) => ({
  fontStyle: 'italic',
  color: props.theme.colors.inactive,
}));
