import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useGetMerchantQuery, useSimulateDepositMutation } from 'services/merchants';
import { useGetPayoutsListQuery } from 'services/payouts';

import { DataTableRow } from 'components/dataTable';

import RoutePath from 'router/path';

import selectAccount from 'state/selectors/accounts/accountSelector';

import { usePersistentFilters } from 'utils/filters';
import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { usePersistentPagination } from 'utils/tables/pagination';
import { getRequestSorting, useTableSorting } from 'utils/tables/sorting';

import { KEYS } from './keys';
import { PayoutsSection } from './payouts';
import { PayoutDataRow } from './types';
import { prepareFilters, transformPayoutsDataToTableFormat } from './utils';

export const PayoutsSectionContainer = () => {
  const navigate = useNavigate();

  const match = useMatch(KEYS.ROUTE_MATCH);
  const { account } = useSelector(selectAccount);
  const { uuid } = match?.params ?? { uuid: account.uuid };

  const { data: merchantResponse, refetch: merchantRefetch } = useGetMerchantQuery(uuid!, {
    skip: !uuid,
  });

  const [simulateDeposit, { isLoading: isPayoutInProgress }] = useSimulateDepositMutation();

  const { sorting, onSort } = useTableSorting({
    defaultSorting: {
      direction: KEYS.DEFAULT_TABLE_SORTING.DIRECTION,
      field: KEYS.DEFAULT_TABLE_SORTING.FIELD,
    },
  });

  const { filters, updateFilters } = usePersistentFilters({ merchantUuids: [uuid!] }, KEYS.FILTER_KEY);

  const { pagination, setPagination, changeRowsPerPage, changePage } = usePersistentPagination(getDefaultPagination(), [sorting]);

  const {
    data,
    isFetching,
    refetch: payoutsListRefetch,
  } = useGetPayoutsListQuery({
    pagination: undecoratePagination(pagination),
    sort: getRequestSorting(sorting),
    filters: prepareFilters(filters),
  });

  useEffect(() => {
    if (data?.pagination) {
      setPagination(decoratePagination(data?.pagination));
    }
  }, [data?.pagination]);

  useEffect(() => {
    updateFilters({ merchantUuids: [uuid!] });
  }, [uuid]);

  const navigateToPayout = ({ uuid }: DataTableRow) => navigate(RoutePath.pwc.payoutById(uuid));

  const payouts: PayoutDataRow[] = transformPayoutsDataToTableFormat(data?.data);

  const delay = (ms: number) =>
    new Promise<void>((resolve) => {
      setTimeout(() => resolve(), ms);
    });

  const simulatePayoutHandler = async () => {
    const result = await simulateDeposit(uuid!).unwrap();

    if (result.data) {
      // Add a 1-second delay before refetching data to allow the backend
      // or dependent systems enough time to process and update the data.
      await delay(1000);
      payoutsListRefetch();
      merchantRefetch();
    }

    return result;
  };

  return (
    <PayoutsSection
      merchantData={merchantResponse?.data.payout}
      onSort={onSort}
      sorting={sorting}
      onRowClick={navigateToPayout}
      pagination={pagination}
      payouts={payouts}
      isLoading={isFetching}
      onChangePage={changePage}
      onChangeRowsPerPage={changeRowsPerPage}
      payout={{
        simulatePayout: simulatePayoutHandler,
        isPayoutInProgress,
      }}
    />
  );
};
