import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCross from 'components/icons/cross';

import { CreateAccountModalForm, CreateAccountModalSuccess, CreateAccountModalVerify } from './components';
import { KEYS, LABELS } from './keys';
import { Buttons, Delimeter, ModalControls, ModalRoot, PanelContent } from './styles';
import { CreateAccountStep } from './types';
import { useCreateAccountModal } from './useCreateAccountModal';

export const CreateAccountModal = ({
  error,
  isSubmitting,
  reset,
  open,
  onClose,
  account,
  onCreateAccount,
  setAccountType,
  accountType,
  getGroupAccounts,
  setIsWelcomeEmailEnabled,
  isWelcomeEmailEnabled,
}: ReturnType<typeof useCreateAccountModal>['modalProps']) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState<CreateAccountStep>(CreateAccountStep.Form);
  const [formValues, setFormValues] = useState(account);

  const handleFormValueChange = (fieldName: string, value: any) => {
    setFormValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  const handleCreateAccount = async () => {
    const response = await onCreateAccount(formValues);

    if (response) {
      goToSuccessStep();
    } else {
      goToFormStep();
    }
  };

  const goToFormStep = () => {
    setCurrentStep(CreateAccountStep.Form);
  };
  const goToVerifyStep = () => {
    setCurrentStep(CreateAccountStep.Verify);
  };

  const goToSuccessStep = () => {
    setCurrentStep(CreateAccountStep.Success);
  };

  const allRequiredFieldsNonEmpty = Object.entries(formValues)
    .filter(([key]) => !KEYS.OPTIONAL.includes(key))
    .every(([, value]) => value.toString().trim() !== '');

  const getModalContent = () => {
    switch (currentStep) {
      case CreateAccountStep.Verify:
        return <CreateAccountModalVerify isWelcomeEmailEnabled={isWelcomeEmailEnabled} accountType={accountType} formValues={formValues} />;
      case CreateAccountStep.Success:
        return <CreateAccountModalSuccess formValues={formValues} />;

      default:
        return (
          <CreateAccountModalForm
            isSubmitting={isSubmitting}
            getGroupAccounts={getGroupAccounts}
            setIsWelcomeEmailEnabled={setIsWelcomeEmailEnabled}
            isWelcomeEmailEnabled={isWelcomeEmailEnabled}
            setAccountType={setAccountType}
            accountType={accountType}
            formValues={formValues}
            onFieldValueChange={handleFormValueChange}
            error={error}
          />
        );
    }
  };
  const getModalButtons = () => {
    switch (currentStep) {
      case CreateAccountStep.Verify:
        return (
          <Buttons>
            <Button secondary onClick={goToFormStep} disabled={isSubmitting}>
              {t(LABELS.CREATE_MODAL.BUTTONS.EDIT)}
            </Button>
            <Button primary onClick={handleCreateAccount} disabled={isSubmitting}>
              {t(LABELS.CREATE_MODAL.BUTTONS.CONFIRM)}
            </Button>
          </Buttons>
        );

      case CreateAccountStep.Success:
        return (
          <Buttons>
            <Button primary onClick={closeModal} disabled={isSubmitting}>
              {t(LABELS.CREATE_MODAL.BUTTONS.CLOSE)}
            </Button>
          </Buttons>
        );

      default:
        return (
          <Buttons>
            <Button secondary onClick={closeModal} disabled={isSubmitting}>
              {t(LABELS.CREATE_MODAL.BUTTONS.CANCEL)}
            </Button>
            <Button primary onClick={goToVerifyStep} disabled={isSubmitting || !allRequiredFieldsNonEmpty}>
              {t(LABELS.CREATE_MODAL.BUTTONS.CREATE)}
            </Button>
          </Buttons>
        );
    }
  };

  const modalContent = getModalContent();
  const modalButtons = getModalButtons();

  return (
    <Dialog open={open} scroll="body" onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.CREATE_MODAL.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <PanelContent>
            {modalContent}
            <Delimeter />
            {modalButtons}
          </PanelContent>
        </Panel>
      </ModalRoot>
    </Dialog>
  );
};
