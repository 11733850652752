import styled from '@emotion/styled/macro';

import { Root as ButtonRoot, Text as ButtonText } from 'components/buttons/styles';
import { Path } from 'components/icons/styles';

export const ControlPanel = styled.div<{ active: boolean }>((props) => ({
  width: '100%',
  height: '100%',
  display: props.active ? 'flex' : 'none',
  gap: 10,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  position: 'absolute',
  bottom: '-100%',
  transition: 'all .3s ease',
  backgroundColor: `${props.theme.colors.primary}33`,
  // @ts-ignore
  [ButtonRoot]: {
    border: 0,
  },
  // @ts-ignore
  [ButtonText]: {
    gap: 8,
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const Container = styled.div<{
  isFocused: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  disabled?: boolean;
  error?: string;
}>(
  (props) => ({
    minHeight: 100,
    maxHeight: 100,
    position: 'relative',
    borderRadius: 5,
    border: `0.5px solid ${props.theme.colors.container.tertiary}`,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    padding: 16,
    gap: 4,
    '&:hover': props.disabled
      ? {}
      : {
          // @ts-ignore
          [ControlPanel]: {
            bottom: 0,
          },
        },
  }),
  (props) => {
    if (props.disabled) {
      return {};
    }

    if (props.isFocused) {
      return {
        border: `1px solid ${props.theme.colors.accent}`,
        backgroundColor: props.theme.colors.highlight,
      };
    }

    if (props.isDragAccept) {
      return {
        border: `1px solid ${props.theme.colors.accent}`,
        backgroundColor: props.theme.colors.highlight,
      };
    }

    if (props.isDragReject || props.error) {
      return {
        border: `1px solid ${props.theme.colors.error.main}`,
        backgroundColor: props.theme.colors.error.light,
      };
    }

    return {
      '&:hover': {
        border: `1px solid ${props.theme.colors.accent}`,
        backgroundColor: props.theme.colors.highlight,
        margin: 0,
        // @ts-ignore
        [ControlPanel]: {
          bottom: 0,
        },
      },
    };
  },
);

export const Input = styled.input((props) => ({
  display: 'none',
}));

export const Caption = styled.p((props) => ({
  margin: 0,
  lineHeight: '20px',
  fontSize: 13,
  fontWeight: 500,
  fontFamily: props.theme.fonts.primary,
}));

export const Icon = styled.div((props) => ({
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));

export const FilePreview = styled.img(() => ({
  maxHeight: 76,
  maxWidth: 'calc(100% - 72px)',
}));

export const FileName = styled.h3((props) => ({
  margin: 0,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '20px',
  font: props.theme.fonts.primary,
  color: props.theme.colors.primary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  boxSizing: 'border-box',
  maxWidth: 200,
}));

export const Hints = styled.div<{ visible?: boolean }>((props) => ({
  display: props.visible ? 'flex' : 'none',
  marginTop: 5,
  flexDirection: 'column',
}));

export const Hint = styled.span((props) => ({
  fontSize: 12,
  fontWeight: 400,
  font: props.theme.fonts.primary,
  color: props.theme.colors.inactive,
}));

export const ErrorMessage = styled.span((props) => ({
  color: props.theme.colors.error.main,
  fontSize: 11,
}));
