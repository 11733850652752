import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import { SortOrder } from 'types/sort';

export const COLUMN_IDS = {
  STATUS: 'status',
  ID: 'uuid',
  DATE: 'createdAt',
  FIAT_AMOUNT: 'fiatAmount',
  CRYPTO_AMOUNT: 'cryptoAmount',
  ACTIONS: 'actions',
};

export const KEYS = {
  DEFAULT_TABLE_SORTING: {
    FIELD: COLUMN_IDS.DATE,
    DIRECTION: SortOrder.desc,
  },
};

export const LABELS = {
  BREADCRUMBS: [
    ['page.pwcCustomer.view.breadcrumbs.customers', 'Customers'],
    ['page.pwcCustomer.view.breadcrumbs.customerDetails', 'Customer details'],
  ],
  PAGE_TITLE: ['page.pwcCustomer.view.title', 'Customer details'],
  SECTIONS: {
    CUSTOMER: {
      TITLE: ['page.pwcCustomer.view.sections.customer.title', 'Customer'],
      CUSTOMER_EMAIL: ['page.pwcCustomer.view.sections.customer.userEmail', 'Customer Email'],
      FULL_NAME: ['page.pwcCustomer.view.sections.customer.fullName', 'Full Name'],
      CUSTOMER_ID: ['page.pwcCustomer.view.sections.customer.userId', 'User ID'],
      CREATION_DATE: ['page.pwcCustomer.view.sections.customer.createdDate', 'Creation Date'],
      LAST_PURCHASE: ['page.pwcCustomer.view.sections.customer.createdDate', 'Last Purchase'],
    },
    PAYOUT: {
      TITLE: ['page.pwcCustomer.view.sections.payout.title', 'Settlement Payout'],
      PAYOUT_DATE: ['page.pwcCustomer.view.sections.payout.date', 'Payout Date'],
      PAYOUT_ID: ['page.pwcCustomer.view.sections.payout.id', 'Payout ID'],
    },
    TOP_AREA: {
      CUSTOMER_ID: ['page.pwcCustomer.view.sections.statusBar.customerId', 'User ID'],
    },
  },
  PANEL_TITLE: ['page.pwcCustomer.view.sections.orders.title', 'Orders'],
  TABLE_COLUMNS: {
    ORDER: ['page.pwcCustomer.view.sections.orders.table.columns.order', 'Order'],
    STATUS: ['page.pwcCustomer.view.sections.orders.table.columns.status', 'Status'],
    FIAT_AMOUNT: ['page.pwcCustomer.view.sections.orders.table.columns.fiatAmount', 'Fiat Amount'],
    CRYPTO_AMOUNT: ['page.pwcCustomer.view.sections.orders.table.columns.fiatAmount', 'Crypto Amount'],
    DATE: ['page.pwcCustomer.view.sections.orders.table.columns.date', 'Date'],
    ACTIONS: ['page.pwcCustomer.view.sections.orders.table.columns.actions', ''],
  },
  ACTIONS: {
    ORDER: {
      TITLE: ['page.pwcCustomer.view.sections.orders.actions.title', 'Actions'],
      COPY_ORDER_ID: ['page.pwcCustomer.view.sections.orders.actions.copy', 'Copy Order ID'],
      OPEN_DETAILS: ['page.pwcCustomer.view.sections.orders.actions.openDetails', 'Open Order Details'],
    },
  },
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS), sortable: true },
  { id: COLUMN_IDS.ID, label: t(LABELS.TABLE_COLUMNS.ORDER), sortable: true },
  { id: COLUMN_IDS.DATE, label: t(LABELS.TABLE_COLUMNS.DATE), sortable: true },
  { id: COLUMN_IDS.FIAT_AMOUNT, label: t(LABELS.TABLE_COLUMNS.FIAT_AMOUNT), sortable: true },
  { id: COLUMN_IDS.CRYPTO_AMOUNT, label: t(LABELS.TABLE_COLUMNS.CRYPTO_AMOUNT), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];
