import { PwcOrderShort } from 'services/pwcCustomers/types';

import i18n from 'locales/i18next';

import { formatNumberI18n } from 'utils/i18n';

import { OrderDataRow } from './types';

export const transformOrdersDataToTableFormat = (orders?: PwcOrderShort[]): OrderDataRow[] => {
  return orders
    ? orders.map((order) => ({
        ...order,
        id: order.uuid,
      }))
    : [];
};
export const formatNumber = (stringOrNumber?: string | number) => formatNumberI18n(i18n.language)(Number(stringOrNumber ?? 0));
