import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import RoutePath from 'router/path';

import { formatDateLocal } from 'utils/date';

import { KEYS, LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import { Item, ItemName, ItemValue, Link, SectionWrapper } from '../styles';

export const PayoutSection = ({ orderData }: OrderPwcSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.PAYOUT.TITLE)}>
      <OptionallyVisible visible={Boolean(orderData.payout.uuid)}>
        <SectionWrapper>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYOUT.PAYOUT_ID)}</ItemName>
            <OptionallyVisible visible={Boolean(orderData.payout.uuid)}>
              <ItemValue>
                <Link to={RoutePath.pwc.payoutById(orderData.payout.uuid || KEYS.DASH)}>{orderData.payout.uuid}</Link>
              </ItemValue>
            </OptionallyVisible>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYOUT.PAYOUT_DATE)}</ItemName>
            <ItemValue>{formatDateLocal(orderData.payout.submittedAt)}</ItemValue>
          </Item>
        </SectionWrapper>
      </OptionallyVisible>
    </Panel>
  );
};
