import React, { FunctionComponent } from 'react';

import { DataTableCellProps } from 'components/dataTable';
import { TableCell } from 'components/dataTable/tableCell';
import IconChevron from 'components/icons/chevron';
import OptionallyVisible from 'components/optionallyVisible';

import { formatTableDate } from 'utils/date';

import { COLUMN_IDS, KEYS } from '../keys';
import { ActionButton, ArrowIcon, CryptoCurrency, FiatCurrency, GreyText } from './styles';

const GreyTextCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return (
    <TableCell data-column={column.id}>
      <GreyText>{value}</GreyText>
    </TableCell>
  );
};

const DateCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return <TableCell>{formatTableDate(value)}</TableCell>;
};

const DefaultCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return <TableCell data-column={column.id}>{value}</TableCell>;
};

const FiatAmountCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const currencyCode = row[COLUMN_IDS.FIAT_CURRENCY]?.toUpperCase();
  return (
    <TableCell data-column={column.id}>
      <OptionallyVisible visible={Boolean(value)}>
        <FiatCurrency>
          <GreyText>{value}</GreyText>
          <GreyText>{currencyCode}</GreyText>
        </FiatCurrency>
      </OptionallyVisible>
    </TableCell>
  );
};

const CryptoAmountCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const currencyCode = row[COLUMN_IDS.CRYPTO_CURRENCY];
  return (
    <TableCell data-column={column.id}>
      <CryptoCurrency>
        <span>{value}</span>
        <OptionallyVisible visible={value !== KEYS.CRYPTO_AMOUNT_PLACEHOLDER}>
          <span>{currencyCode}</span>
        </OptionallyVisible>
      </CryptoCurrency>
    </TableCell>
  );
};

const ActionCell = ({ onExpand, isExpand = false }: DataTableCellProps) => {
  return (
    <TableCell>
      <ActionButton onClick={() => onExpand && onExpand()}>
        <ArrowIcon rotated={isExpand}>
          <IconChevron width={24} height={24} />
        </ArrowIcon>
      </ActionButton>
    </TableCell>
  );
};

const cellComponentMap: Record<string, FunctionComponent<DataTableCellProps>> = {
  [COLUMN_IDS.ID]: DefaultCell,
  [COLUMN_IDS.TYPE]: GreyTextCell,
  [COLUMN_IDS.CRYPTO_AMOUNT]: CryptoAmountCell,
  [COLUMN_IDS.FIAT_AMOUNT]: FiatAmountCell,
  [COLUMN_IDS.LAST_UPDATE_AT]: DateCell,
  [COLUMN_IDS.STATUS]: GreyTextCell,
  [COLUMN_IDS.ACTIONS]: ActionCell,
  default: DefaultCell,
};

export const ChargeTableCell = (props: DataTableCellProps) => {
  const { column } = props;
  const Component = cellComponentMap[column.id] || cellComponentMap.default;
  return <Component {...props} />;
};
