import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps } from 'components/dataTable';
import { CopyMenuItem, NavigateMenuItem } from 'components/dataTable/cells';
import { ActionMenu } from 'components/dataTable/cells/actionMenu';
import { TableCell } from 'components/dataTable/tableCell';

import RoutePath from 'router/path';

import { formatTableDate } from 'utils/date';

import { KYCStatusBadge } from '../../components/kycStatusBadge/kycStatusBadge';
import { StatusBadge } from '../../components/statusBadge/statusBadge';
import { COLUMN_IDS, LABELS } from '../keys';
import { CustomerDataRow } from '../types';

const KYCStatusCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return (
    <TableCell data-column={column.id}>
      <KYCStatusBadge status={value} />
    </TableCell>
  );
};

const CustomerStatusCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return (
    <TableCell data-column={column.id}>
      <StatusBadge status={value} />
    </TableCell>
  );
};

const SignUpDateCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return <TableCell>{formatTableDate(value)}</TableCell>;
};

const LoginAtDateCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return <TableCell>{formatTableDate(value)}</TableCell>;
};

export const ActionCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();

  const { uuid, username } = row as CustomerDataRow;

  return (
    <TableCell>
      <ActionMenu title={t(LABELS.ACTIONS.TITLE)}>
        <NavigateMenuItem to={RoutePath.customerById(uuid)}>{t(LABELS.ACTIONS.OPEN_CUSTOMER_DETAILS)}</NavigateMenuItem>
        <CopyMenuItem value={uuid}>{t(LABELS.ACTIONS.COPY_USER_ID)}</CopyMenuItem>
        <CopyMenuItem value={username}>{t(LABELS.ACTIONS.COPY_USER_EMAIL)}</CopyMenuItem>
      </ActionMenu>
    </TableCell>
  );
};

const DefaultCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return <TableCell data-column={column.id}>{value}</TableCell>;
};

const cellComponentMap: Record<string, FunctionComponent<DataTableCellProps>> = {
  [COLUMN_IDS.EMAIL]: DefaultCell,
  [COLUMN_IDS.USER_ID]: DefaultCell,
  [COLUMN_IDS.SIGNUP_AT]: SignUpDateCell,
  [COLUMN_IDS.LOGIN_AT]: LoginAtDateCell,
  [COLUMN_IDS.KYC_STATUS]: KYCStatusCell,
  [COLUMN_IDS.STATUS]: CustomerStatusCell,
  [COLUMN_IDS.ACTIONS]: ActionCell,
  default: DefaultCell,
};

export const CustomerTableCell = (props: DataTableCellProps) => {
  const { column } = props;
  const Component = cellComponentMap[column.id] || cellComponentMap.default;
  return <Component {...props} />;
};
