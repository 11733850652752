import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps } from 'components/dataTable';
import { ActionMenu, CopyMenuItem, NavigateMenuItem } from 'components/dataTable/cells';
import { TableCell } from 'components/dataTable/tableCell';
import OptionallyVisible from 'components/optionallyVisible';
import { OrderStatusBadge } from 'components/pwcOrderStatusBadge/orderStatusBadge';

import RoutePath from 'router/path';

import { formatTableDate } from 'utils/date';

import { COLUMN_IDS, KEYS } from '../keys';
import { OrderDataRow } from '../types';
import { LABELS } from './keys';
import { CryptoCurrency, FiatCurrency, GreyText } from './styles';

const StatusCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return (
    <TableCell data-column={column.id}>
      <OrderStatusBadge status={value} />
    </TableCell>
  );
};

const GreyTextCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return (
    <TableCell data-column={column.id}>
      <GreyText>{value}</GreyText>
    </TableCell>
  );
};

const DateCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return <TableCell>{formatTableDate(value)}</TableCell>;
};

const DefaultCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return <TableCell data-column={column.id}>{value}</TableCell>;
};

const FiatAmountCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const currencyCode = row[COLUMN_IDS.FIAT_CURRENCY]?.toUpperCase();
  return (
    <TableCell data-column={column.id}>
      <OptionallyVisible visible={Boolean(value)}>
        <FiatCurrency>
          <span>{value}</span>
          <span>{currencyCode}</span>
        </FiatCurrency>
      </OptionallyVisible>
    </TableCell>
  );
};

const CryptoAmountCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const currencyCode = row[COLUMN_IDS.CRYPTO_CURRENCY];
  return (
    <TableCell data-column={column.id}>
      <CryptoCurrency>
        <GreyText>{value}</GreyText>
        <OptionallyVisible visible={value !== KEYS.CRYPTO_AMOUNT_PLACEHOLDER}>
          <GreyText>{currencyCode}</GreyText>
        </OptionallyVisible>
      </CryptoCurrency>
    </TableCell>
  );
};

const ActionCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();
  const { uuid, customerEmail } = row as OrderDataRow;

  return (
    <TableCell>
      <ActionMenu>
        <NavigateMenuItem to={RoutePath.pwc.orderById(uuid)}>{t(LABELS.ACTIONS.NAVIGATE_TO_ORDER)}</NavigateMenuItem>
        <CopyMenuItem value={uuid}>{t(LABELS.ACTIONS.COPY_ORDER_ID)}</CopyMenuItem>
        <CopyMenuItem value={customerEmail}>{t(LABELS.ACTIONS.COPY_CUSTOMER_EMAIL)}</CopyMenuItem>
      </ActionMenu>
    </TableCell>
  );
};

const cellComponentMap: Record<string, FunctionComponent<DataTableCellProps>> = {
  [COLUMN_IDS.STATUS]: StatusCell,
  [COLUMN_IDS.DATE]: DateCell,
  [COLUMN_IDS.ID]: GreyTextCell,
  [COLUMN_IDS.CUSTOMER]: GreyTextCell,
  [COLUMN_IDS.ORDER_AMOUNT]: FiatAmountCell,
  [COLUMN_IDS.PAID_AMOUNT]: CryptoAmountCell,
  [COLUMN_IDS.ACTIONS]: ActionCell,
  default: DefaultCell,
};

export const OrderTableCell = (props: DataTableCellProps) => {
  const { column } = props;
  const Component = cellComponentMap[column.id] || cellComponentMap.default;
  return <Component {...props} />;
};
