import { PageWrapper } from 'components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetCountriesListQuery } from 'services/assets';
import { useGetCustomersListQuery } from 'services/customers';
import { CustomersListQuery } from 'services/customers/types';

import { DataTableRow } from 'components/dataTable';
import { SelectItem } from 'components/inputs/select/select';

import RoutePath from 'router/path';

import { usePersistentFilters } from 'utils/filters';
import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { usePersistentPagination } from 'utils/tables/pagination';
import { usePersistentSearch } from 'utils/tables/search';
import { getRequestSorting, useTableSorting } from 'utils/tables/sorting';
import { useDebouncedQuery } from 'utils/tables/useDebouncedQuery';

import { CustomersList } from './customersList';
import { KEYS, LABELS } from './keys';
import { CustomerDataRow, Filters } from './types';
import { makeFilters, prepareFilters } from './utils';

export const CustomersListContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));
  const { search, setSearch } = usePersistentSearch(KEYS.SEARCH_DEBOUNCE_TIMEOUT, KEYS.FILTERS_KEY);
  const { filters, updateFilters, clearFilters } = usePersistentFilters<Filters>({}, KEYS.FILTERS_KEY);
  const { sorting, onSort } = useTableSorting({
    defaultSorting: {
      direction: KEYS.DEFAULT_TABLE_SORTING.DIRECTION,
      field: KEYS.DEFAULT_TABLE_SORTING.FIELD,
    },
  });

  const { pagination, setPagination, changeRowsPerPage, changePage } = usePersistentPagination(
    getDefaultPagination(),
    [filters, sorting, search],
    KEYS.FILTERS_KEY,
  );
  const customerListQuery: CustomersListQuery = useDebouncedQuery(
    () => ({
      pagination: undecoratePagination(pagination),
      sort: getRequestSorting(sorting),
      filters: prepareFilters(filters),
      search: search || undefined,
    }),
    [pagination, filters, sorting, search],
  );

  const { data, isFetching } = useGetCustomersListQuery(customerListQuery);

  const { data: countries = [] } = useGetCountriesListQuery();

  useEffect(() => {
    if (data?.pagination) {
      setPagination(decoratePagination(data?.pagination));
    }
  }, [data?.pagination]);

  const customers: CustomerDataRow[] = (data?.data || []).map((customer) => {
    return {
      ...customer,
      id: customer.uuid,
    };
  });

  const mappedCountries: SelectItem[] = countries.map((country) => ({
    key: country.isoAlpha2,
    icon: country.iconUrl,
    label: country.name,
  }));

  const filterSettings = makeFilters({ countries: mappedCountries });
  const navigateToCustomer = ({ uuid }: DataTableRow) => navigate(RoutePath.customerById(uuid));

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <CustomersList
        pagination={pagination}
        orders={customers}
        isLoading={isFetching}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
        onSort={onSort}
        sorting={sorting}
        onSearch={setSearch}
        search={search}
        onRowClick={navigateToCustomer}
        filterProps={{
          filterSettings,
          onFilterChange: updateFilters,
          onFiltersClear: clearFilters,
          filters,
        }}
      />
    </PageWrapper>
  );
};
