import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { Input, InputType } from 'components/inputs';

import { KEYS } from '../keys';
import LABELS, { FORM_FIELDS, FormFieldType } from './keys';
import { FormContainer } from './styles';

interface OrderDetailsFormProps {
  readOnlyMode: boolean;
  values: Record<FormFieldType, string>;
  error?: FormikErrors<Record<FormFieldType, string>>;
  setFieldValue: (field: FormFieldType, value: string) => void;
}

const OrderDetailsForm = ({ readOnlyMode, values, setFieldValue, error }: OrderDetailsFormProps) => {
  const { t } = useTranslation();

  const fields: FormFieldDescription[] = [
    {
      key: FORM_FIELDS.CURRENCY,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.CURRENCY]),
      renderInput: () => KEYS.CREATE_ORDER_CURRENCY,
    },
    {
      key: FORM_FIELDS.FIAT_AMOUNT,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.FIAT_AMOUNT]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Number}
          value={values?.[FORM_FIELDS.FIAT_AMOUNT]}
          onChange={(value) => setFieldValue(FORM_FIELDS.FIAT_AMOUNT, value)}
          disabled={readonly}
          placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS[FORM_FIELDS.FIAT_AMOUNT])}
        />
      ),
    },
  ];

  return (
    <Panel label={t(LABELS.ORDER_DETAILS_PANEL_TITLE)}>
      <FormContainer>
        <FormLayout fields={fields} readonly={readOnlyMode} error={error} />
      </FormContainer>
    </Panel>
  );
};
export default OrderDetailsForm;
