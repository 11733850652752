import { Divider } from '@mui/material';
import { OptionallyVisible } from 'components';
import { FieldArrayRenderProps, getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import IconAdd from 'components/icons/add';
import IconRemove from 'components/icons/dash';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FormType, LABELS } from '../../keys';
import { Section } from '../../styles';
import { BridgeIdentityType, IdentifyingInformationType } from '../../types';
import replaceLabelsUnderscore from '../../utils/replaceLabelsUnderscore';
import FormikInput from '../formikTextInput/formikTextInput';
import { Block, Container, Label, RecordButton } from './styles';

interface Props {
  // path to the field in the formik values, must point to a field of an array type: IdentifyingInformationType[]
  fieldPath: string;
  insert: FieldArrayRenderProps['insert'];
  remove: FieldArrayRenderProps['remove'];

  countries: SelectItem[];
  //  for errors coming from backend
  error?: Record<string, string>;
}

const IDENTITY_TYPES: SelectItem[] = Object.entries(BridgeIdentityType).map(([key, value]) => ({
  key: value,
  label: key,
}));

const formattedIdentityTypes = replaceLabelsUnderscore(IDENTITY_TYPES);

const IdentifyingInformation = ({ fieldPath, error, countries, insert, remove }: Props) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext<FormType>();
  const identifyingInformationValues = getIn(values, fieldPath) as IdentifyingInformationType[];

  const addItem = () => {
    insert(identifyingInformationValues.length + 1, { type: undefined, issuingCountry: undefined, number: '' });
  };

  const removeItem = (index: number) => {
    return () => {
      remove(index);
    };
  };

  return (
    <>
      <Label>{t(LABELS.SECTIONS.IDENTIFYING_INFORMATION.TITLE)}</Label>

      <Block>
        <FormField error={error?.[fieldPath]}>{/* showing BE errors at the top, move it? */}</FormField>

        <Section>
          {identifyingInformationValues.map((identifyingInformation, index) => {
            const type = `${fieldPath}[${index}].type`;
            const country = `${fieldPath}[${index}].issuingCountry`;
            const number = `${fieldPath}[${index}].number`;

            const typeError = getIn(errors, `${type}.key`);
            const typeTouched = getIn(touched, type);

            const countryError = getIn(errors, `${country}.key`);
            const countryTouched = getIn(touched, country);

            return (
              <Container>
                <FormField error={typeError && typeTouched ? typeError : undefined}>
                  <SearchableSelect<SelectItem, false>
                    fullWidth
                    value={identifyingInformation.type}
                    items={formattedIdentityTypes}
                    placeholder={t(LABELS.SECTIONS.IDENTIFYING_INFORMATION.PLACEHOLDER_TYPE)}
                    onChange={(selectItem) => {
                      setFieldValue(type, selectItem);
                    }}
                    getItemLabel={(item) => item.label}
                    multiple={false}
                    closeDropdownOnSelect
                    popperProps={{ disablePortal: true }}
                  />
                </FormField>

                <FormField error={countryError && countryTouched ? countryError : undefined}>
                  <SearchableSelect<SelectItem, false>
                    fullWidth
                    value={identifyingInformation.issuingCountry}
                    items={countries}
                    placeholder={t(LABELS.SECTIONS.IDENTIFYING_INFORMATION.PLACEHOLDER_COUNTRY)}
                    onChange={(selectItem) => {
                      setFieldValue(country, selectItem);
                    }}
                    getItemLabel={(item) => item.label}
                    multiple={false}
                    closeDropdownOnSelect
                    popperProps={{ disablePortal: true }}
                  />
                </FormField>

                <FormikInput placeholder={t(LABELS.SECTIONS.IDENTIFYING_INFORMATION.PLACEHOLDER_NUMBER)} fieldPath={number} />

                <OptionallyVisible visible={index > 0}>
                  <div>
                    <RecordButton type="button" inside flat onClick={removeItem(index)}>
                      <IconRemove />
                      {t(LABELS.SECTIONS.IDENTIFYING_INFORMATION.REMOVE)}
                    </RecordButton>
                  </div>
                </OptionallyVisible>

                <OptionallyVisible visible={index !== identifyingInformationValues.length - 1}>
                  <Divider />
                </OptionallyVisible>
              </Container>
            );
          })}
        </Section>
      </Block>

      <RecordButton type="button" flat onClick={addItem}>
        <IconAdd />
        {t(LABELS.SECTIONS.IDENTIFYING_INFORMATION.ADD)}
      </RecordButton>
    </>
  );
};

export default IdentifyingInformation;
