import { useReducer } from 'react';

const STARTING_STEP = 1;
const STEP_INITIAL_STATE = { step: STARTING_STEP };
export const MIN_STEP = 1;
export const MAX_STEP = 4;

enum StepAction {
  NEXT = 'NEXT',
  PREV = 'PREV',
  RESET = 'RESET',
  GO = 'GO',
}

interface StepActionType {
  type: StepAction;
  payload?: number; // Optional payload for the GO action
}

const stepReducer = (state: typeof STEP_INITIAL_STATE, action: StepActionType) => {
  switch (action.type) {
    case StepAction.NEXT:
      if (state.step < MAX_STEP) {
        return { step: state.step + 1 };
      }
      return state;
    case StepAction.PREV:
      if (state.step > MIN_STEP) {
        return { step: state.step - 1 };
      }
      return state;
    case StepAction.RESET:
      return STEP_INITIAL_STATE;
    case StepAction.GO:
      if (action.payload !== undefined && action.payload >= MIN_STEP && action.payload <= MAX_STEP) {
        return { step: action.payload };
      }
      return state;
    default:
      return state;
  }
};

const useStepper = () => {
  const [state, dispatch] = useReducer(stepReducer, STEP_INITIAL_STATE);

  const next = () => {
    dispatch({ type: StepAction.NEXT });
  };
  const prev = () => {
    dispatch({ type: StepAction.PREV });
  };
  const reset = () => {
    dispatch({ type: StepAction.RESET });
  };

  const go = (step: number) => {
    dispatch({ type: StepAction.GO, payload: step });
  };

  return {
    step: state.step,
    go,
    next,
    prev,
    reset,
  };
};

export default useStepper;
