import { useEffect, useState } from 'react';
import { useCreateMerchantsBankAccountMutation } from 'services/merchants';
import { AccountOwnerType, CreateBankAccountPayload } from 'services/merchants/types';

import { SelectItem } from 'components/inputs/select/select';

import { getServerSideValidationDetails } from 'utils/error';

import { environments } from './initialData';
import { KEYS } from './keys';
import { BankAccountForm } from './types';

interface CreateBankAccountsModalProps {
  merchantId: string;
  countries: SelectItem[];
}

export const useCreateBankAccountsModal = ({ merchantId, countries }: CreateBankAccountsModalProps) => {
  const { production, sandbox } = environments;
  const isDevOrSandbox = window.s4cConfig.features?.canPrefillBankForm;
  const initialBankAccountData = isDevOrSandbox ? sandbox : production;
  const initialAccountType = isDevOrSandbox ? AccountOwnerType.Business : null;
  const defaultCountry = countries.find((country) => country.key === KEYS.DEFAULT_COUNTRY_CODE);
  const [isModalOpen, setModalOpen] = useState(false);
  const [accountType, setAccountType] = useState<AccountOwnerType | null>(null);
  const [createdAccount, setCreatedAccount] = useState<BankAccountForm | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<SelectItem | undefined>(undefined);
  const [createBankAccount, { isLoading: isSubmittingAccount, error: createAccountError, reset }] = useCreateMerchantsBankAccountMutation();

  useEffect(() => {
    if (isDevOrSandbox) {
      setSelectedCountry(defaultCountry);
    }
  }, [countries]);

  const handleCreateAccountModalOpen = () => {
    setCreatedAccount(initialBankAccountData);

    setAccountType(initialAccountType);
    setModalOpen(true);
  };

  const handleCreateAccountModalClose = () => {
    setCreatedAccount(null);
    setModalOpen(false);
    setSelectedCountry(isDevOrSandbox ? defaultCountry : undefined);
  };

  const handleCreateAccount = async (formValues: BankAccountForm) => {
    const {
      streetLine1,
      streetLine2,
      city,
      state,
      postalCode,
      accountNumber,
      routingNumber,
      firstName,
      lastName,
      businessName,
      ...restProps
    } = formValues;

    const accountToCreate: CreateBankAccountPayload = {
      uuid: merchantId,
      accountOwnerType: accountType!,
      currency: KEYS.HARDCODED_CURRENCY,
      account: {
        accountNumber,
        routingNumber,
      },
      address: {
        streetLine1,
        city,
        postalCode,
        country: selectedCountry?.key as string,
      },
      ...restProps,
    };

    if (streetLine2.length) {
      accountToCreate.address.streetLine2 = streetLine2;
    }

    if (state.length) {
      accountToCreate.address.state = state;
    }

    if (accountType === AccountOwnerType.Individual) {
      accountToCreate.firstName = firstName;
      accountToCreate.lastName = firstName;
    } else if (accountType === AccountOwnerType.Business) {
      accountToCreate.businessName = businessName;
    }

    const result = await createBankAccount(accountToCreate);
    return 'data' in result;
  };

  const handleCountryChange = (nextItem: SelectItem) => {
    setSelectedCountry(nextItem);
  };

  const createAccountErrorMessage = getServerSideValidationDetails(createAccountError, KEYS.DEFAULT_ERROR_LOCATION);

  return {
    handleCreateAccountModalOpen,
    createdAccount,
    modalProps: {
      isSubmitting: isSubmittingAccount,
      reset,
      open: isModalOpen,
      onClose: handleCreateAccountModalClose,
      onCreateAccount: handleCreateAccount,
      account: createdAccount!,
      error: createAccountErrorMessage,
      setAccountType,
      countryProps: {
        countries,
        selectedCountry,
        handleCountryChange,
      },
      accountType,
    },
  };
};
