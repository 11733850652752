import { ColumnDecoration, DataTableColumn } from 'components/dataTable';

import i18next from 'locales/i18next';

const { t } = i18next;

export const LABELS = {
  PANEL_TITLE: ['components.accountSettings.apiKeys.title', 'API Keys'],
  PUBLIC_KEY: ['components.accountSettings.apiKeys.publicKey', 'Public key'],
  SECRET_KEY: ['components.accountSettings.apiKeys.secretKey', 'Secret key'],
  REVEAL: ['components.accountSettings.apiKeys.reveal', 'REVEAL'],
  ROTATE: ['components.accountSettings.apiKeys.reveal', 'ROTATE'],
  MODAL: {
    TITLE: ['components.accountSettings.apiKeys.rotateKeyModal.title', 'Rotate API Key'],
    BUTTONS: {
      CONFIRM: ['components.accountSettings.apiKeys.rotateKeyModal.buttons.confirm', 'Confirm'],
      CANCEL: ['components.accountSettings.apiKeys.rotateKeyModal.buttons.cancel', 'Cancel'],
    },
    CONTENT: {
      QUESTION: ['components.accountSettings.apiKeys.rotateKeyModal.content.question', 'Are you sure?'],
      REMARK: ['components.accountSettings.apiKeys.rotateKeyModal.content.remark', 'Your current key will expire immediately.'],
    },
  },
  TABLE_COLUMNS: {
    KEY_NAME: ['components.accountSettings.apiKeys.table.columns.keyName', 'Name'],
    KEY_VALUE: ['components.accountSettings.apiKeys.table.columns.keyValue', 'Key'],
    CREATED_AT: ['components.accountSettings.apiKeys.table.columns.created', 'Created'],
    LAST_USED: ['components.accountSettings.apiKeys.table.columns.lastUsed', 'Last used'],
  },
};

export const COLUMN_IDS = {
  KEY_NAME: 'keyName',
  KEY_VALUE: 'keyValue',
  CREATED_AT: 'createdAt',
  LAST_USED: 'lastUsed',
  ACTION_ROTATE_KEY: 'actionRotateKey',
} as const;

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.KEY_NAME, label: t(LABELS.TABLE_COLUMNS.KEY_NAME), decoration: ColumnDecoration.bold },
  { id: COLUMN_IDS.KEY_VALUE, label: t(LABELS.TABLE_COLUMNS.KEY_VALUE) },
  { id: COLUMN_IDS.CREATED_AT, label: t(LABELS.TABLE_COLUMNS.CREATED_AT) },
  { id: COLUMN_IDS.LAST_USED, label: t(LABELS.TABLE_COLUMNS.LAST_USED) },
  { id: COLUMN_IDS.ACTION_ROTATE_KEY, label: '' },
];
