import styled from '@emotion/styled';
import { Table as MaterialTable, TableCell } from '@mui/material';

export const TableWrapper = styled.div((props) => ({
  width: '100%',
  backgroundColor: props.theme.colors.background,
}));

export const LoaderWrapper = styled.div(() => ({
  margin: 50,
}));

export const StyledTable = styled(MaterialTable)(() => ({}));

export const StyledTableCell = styled(TableCell)(() => ({
  padding: 0,
  borderBottom: 'none',
}));
