import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, LABELS } from '../../../../keys';
import { BridgeCustomerBusinessType, BusinessIndustryType } from '../../../../types';
import replaceLabelsUnderscore from '../../../../utils/replaceLabelsUnderscore';
import { Container, Label } from './styles';

interface Props {
  // path to the field in the formik values, must point to a field of an array type: IdentifyingInformationType[]
  fieldPath: string;
  //  for errors coming from backend
  error?: Record<string, string>;
}

const BUSINESS_INDUSTRIES: SelectItem[] = Object.entries(BridgeCustomerBusinessType).map(([key, value]) => ({
  key: value,
  label: key,
}));

const formattedBusinessIndustries = replaceLabelsUnderscore(BUSINESS_INDUSTRIES);

const BusinessIndustry = ({ fieldPath, error }: Props) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext<typeof FORMIK_INITIAL_VALUES>();

  const industry = `${fieldPath}`;
  const industryError = getIn(errors, `${fieldPath}.key`);
  const industryTouched = getIn(touched, fieldPath);

  const industryInformationValue = getIn(values, fieldPath) as BusinessIndustryType;

  return (
    <Container>
      <Label>{t(LABELS.SECTIONS.BUSINESS_INDUSTRY.TITLE)}</Label>

      <FormField error={error?.[fieldPath]}>{/* showing BE errors at the top, move it? */}</FormField>

      <FormField error={industryError && industryTouched ? industryError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={industryInformationValue}
          items={formattedBusinessIndustries}
          placeholder={t(LABELS.SECTIONS.BUSINESS_INDUSTRY.PLACEHOLDER)}
          onChange={(selectItem) => {
            setFieldValue(industry, selectItem);
          }}
          getItemLabel={(item) => item.label}
          multiple={false}
          closeDropdownOnSelect
          popperProps={{ disablePortal: true }}
        />
      </FormField>
    </Container>
  );
};

export default BusinessIndustry;
