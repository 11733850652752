import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { formatDateLocal } from 'utils/date';

import { LABELS } from '../../keys';
import { PayoutSectionProps } from '../../types';
import { Details, Item, ItemName, ItemValue, PeriodContainer, SectionWrapper } from '../styles';
import { formatNumber } from '../utils';

export const OrderSection = ({ payoutData }: PayoutSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.DETAILS.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.AMOUNT)}</ItemName>
          <ItemValue>
            {formatNumber(payoutData.amount)} {payoutData.currency.toUpperCase()}
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.DATE)}</ItemName>
          <ItemValue>{formatDateLocal(payoutData.submittedAt)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.PERIOD)}</ItemName>
          <ItemValue>
            <PeriodContainer>
              {' '}
              <div>
                <Details>{t(LABELS.SECTIONS.DETAILS.FROM)}</Details> {formatDateLocal(payoutData.periodStart)}
              </div>
              <div>
                <Details>{t(LABELS.SECTIONS.DETAILS.TO)}</Details> {formatDateLocal(payoutData.periodEnd)}
              </div>
            </PeriodContainer>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.PAYOUT_ID)}</ItemName>
          <ItemValue>{payoutData.uuid}</ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
