import { FormikErrors } from 'formik';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { Input, InputType } from 'components/inputs';
import { Checkbox } from 'components/inputs/checkbox/checkbox';
import { Select, SelectItem } from 'components/inputs/select/select';
import { SelectableItem } from 'components/inputs/select/styles';

import { UnmatchedDepositAmountPolicy } from 'types/pwcOrder';

import {
  AvailableOrderDuration,
  DURATION_LABELS,
  FORM_FIELDS,
  FORM_INITIAL_VALUES,
  FormFieldType,
  LABELS,
  UNMATCHED_DEPOSIT_POLICY_LABELS,
  URL_PLACEHOLDER,
} from '../keys';
import { FormContainer } from '../styles';
import { CheckboxButton, DurationControls, SelectedSelectableItem, UnmatchedPolicyControls } from './styles';

interface AdvancedSettingsFormProps {
  readOnlyMode: boolean;
  values: Record<FormFieldType, string | boolean>;
  errors?: FormikErrors<Record<FormFieldType, any>>;
  setFieldValue: (field: FormFieldType, value: any) => void;
}

const AdvancedSettingsForm = ({ values, readOnlyMode, errors, setFieldValue }: AdvancedSettingsFormProps) => {
  const { t } = useTranslation();

  const durationSelectItems: SelectItem[] = Object.values(AvailableOrderDuration).map((duration) => ({
    key: duration.toString(),
    label: t(DURATION_LABELS[duration]),
  }));

  const [selectedDuration, setSelectedDuration] = useState<SelectItem>(durationSelectItems[0]);
  const [durationAmount, setDurationAmount] = useState<string>(FORM_INITIAL_VALUES[FORM_FIELDS.EXPIRATION_TIME]);

  const onCheckboxUpdate = (field: FormFieldType) => () => {
    setFieldValue(field, !values?.[field]);
  };

  const getCalculatedDuration = (durationPreset: AvailableOrderDuration, amount: string) =>
    ({
      [AvailableOrderDuration.Days]: Number(amount) * 24 * 60,
      [AvailableOrderDuration.Hours]: Number(amount) * 60,
      [AvailableOrderDuration.Minutes]: amount,
    })[durationPreset];

  const onDurationSelect = (item: SelectItem) => {
    const orderTtl = getCalculatedDuration(item.key as AvailableOrderDuration, durationAmount);
    setFieldValue(FORM_FIELDS.EXPIRATION_TIME, orderTtl);
    setSelectedDuration(item);
  };

  const renderSelectedDurationItem = (item: SelectItem) => (
    <SelectedSelectableItem key={item.key}>{t(DURATION_LABELS[item.key as AvailableOrderDuration])}</SelectedSelectableItem>
  );

  const renderAvailableDurationItem = (item: SelectItem, selectItem: (event: MouseEvent<HTMLElement>) => void) => (
    <SelectableItem onClick={selectItem} key={item.key}>
      {t(DURATION_LABELS[item.key as AvailableOrderDuration])}
    </SelectableItem>
  );

  const onDurationAmountChange = (amount: string) => {
    setDurationAmount(amount);

    const orderTtl = getCalculatedDuration(selectedDuration.key as AvailableOrderDuration, amount);
    setFieldValue(FORM_FIELDS.EXPIRATION_TIME, orderTtl);
  };

  const onPolicyChange = (item: SelectItem) => {
    setFieldValue(FORM_FIELDS.UNMATCHED_DEPOSIT_POLICY, item.key);
  };

  const getUnmatchedDepositPolicySelectItem = (policy: UnmatchedDepositAmountPolicy) => ({
    key: policy,
    label: t(UNMATCHED_DEPOSIT_POLICY_LABELS[policy]),
  });

  const unmatchedDepositPolicyItems: SelectItem[] = Object.values(UnmatchedDepositAmountPolicy).map(getUnmatchedDepositPolicySelectItem);

  const fields: FormFieldDescription[] = [
    {
      key: FORM_FIELDS.PASS_PROCESSING_FEE,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.PASS_PROCESSING_FEE]),
      renderInput: ({ readonly }) => (
        <CheckboxButton flat onClick={onCheckboxUpdate(FORM_FIELDS.PASS_PROCESSING_FEE)}>
          <Checkbox value={values?.[FORM_FIELDS.PASS_PROCESSING_FEE] as boolean | undefined} onChange={() => {}} disabled={readonly} />
          {t(LABELS.FORM_FIELDS.PLACEHOLDERS[FORM_FIELDS.PASS_PROCESSING_FEE])}
        </CheckboxButton>
      ),
    },
    {
      key: FORM_FIELDS.EXPIRATION_TIME,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.EXPIRATION_TIME]),
      renderInput: ({ readonly }) => (
        <DurationControls>
          <Input
            fullWidth
            type={InputType.Number}
            value={durationAmount}
            onChange={onDurationAmountChange}
            disabled={readonly}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS[FORM_FIELDS.EXPIRATION_TIME])}
          />
          <Select
            fullWidth
            closeDropdownOnSelect
            getItemLabel={({ label }) => label}
            popperProps={{
              disablePortal: true,
            }}
            items={durationSelectItems}
            value={selectedDuration}
            renderSelectedItem={renderSelectedDurationItem}
            renderListItem={renderAvailableDurationItem}
            onChange={onDurationSelect}
          />
        </DurationControls>
      ),
    },
    {
      key: FORM_FIELDS.UNMATCHED_DEPOSIT_POLICY,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.UNMATCHED_DEPOSIT_POLICY]),
      renderInput: ({ readonly }) => (
        <UnmatchedPolicyControls>
          <Select
            fullWidth
            closeDropdownOnSelect
            getItemLabel={({ label }) => label}
            popperProps={{
              disablePortal: true,
            }}
            items={unmatchedDepositPolicyItems}
            disabled={readonly}
            value={getUnmatchedDepositPolicySelectItem(values?.[FORM_FIELDS.UNMATCHED_DEPOSIT_POLICY] as UnmatchedDepositAmountPolicy)}
            onChange={onPolicyChange}
            renderSelectedItem={({ label }) => <SelectedSelectableItem>{label}</SelectedSelectableItem>}
          />
        </UnmatchedPolicyControls>
      ),
    },
    {
      key: FORM_FIELDS.EXTERNAL_ORDER_ID,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.EXTERNAL_ORDER_ID]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={(values as unknown as Record<FormFieldType, string>)?.[FORM_FIELDS.EXTERNAL_ORDER_ID]}
          onChange={(value) => setFieldValue(FORM_FIELDS.EXTERNAL_ORDER_ID, value)}
          disabled={readonly}
          placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS[FORM_FIELDS.EXTERNAL_ORDER_ID])}
        />
      ),
    },
    {
      key: FORM_FIELDS.REDIRECT_URL,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.REDIRECT_URL]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={(values as unknown as Record<FormFieldType, string>)?.[FORM_FIELDS.REDIRECT_URL]}
          onChange={(value) => setFieldValue(FORM_FIELDS.REDIRECT_URL, value)}
          disabled={readonly}
          placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS[FORM_FIELDS.REDIRECT_URL])}
        />
      ),
    },
    {
      key: FORM_FIELDS.CANCEL_REDIRECT_URL,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.CANCEL_REDIRECT_URL]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={(values as unknown as Record<FormFieldType, string>)?.[FORM_FIELDS.CANCEL_REDIRECT_URL]}
          onChange={(value) => setFieldValue(FORM_FIELDS.CANCEL_REDIRECT_URL, value)}
          disabled={readonly}
          placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS[FORM_FIELDS.CANCEL_REDIRECT_URL])}
        />
      ),
    },
    {
      key: FORM_FIELDS.WEBHOOK_URL,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.WEBHOOK_URL]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={(values as unknown as Record<FormFieldType, string>)?.[FORM_FIELDS.WEBHOOK_URL]}
          onChange={(value) => setFieldValue(FORM_FIELDS.WEBHOOK_URL, value)}
          disabled={readonly}
          placeholder={URL_PLACEHOLDER}
        />
      ),
    },
    {
      key: FORM_FIELDS.LOCKED,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.LOCKED]),
      renderInput: ({ readonly }) => (
        <CheckboxButton flat onClick={onCheckboxUpdate(FORM_FIELDS.LOCKED)}>
          <Checkbox value={values?.[FORM_FIELDS.LOCKED] as boolean | undefined} onChange={() => {}} disabled={readonly} />
          {t(LABELS.FORM_FIELDS.PLACEHOLDERS[FORM_FIELDS.LOCKED])}
        </CheckboxButton>
      ),
    },
  ];

  return (
    <Panel label={t(LABELS.ADVANCED_SETTINGS_PANEL_TITLE)}>
      <FormContainer>
        <FormLayout fields={fields} readonly={readOnlyMode} error={errors as Record<string, string>} />
      </FormContainer>
    </Panel>
  );
};

export default AdvancedSettingsForm;
