import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import { SelectableItem } from 'components/inputs/select/styles';

export const CheckboxButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  '& > span': {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    fontWeight: 500,
    fontSize: 13,
  },
  '& path': {
    fill: `${theme.colors.white} !important`,
  },
}));

export const DurationControls = styled.div(({ theme }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  '& > button': {
    backgroundColor: theme.colors.container.primary,
    maxHeight: 34,
  },
}));

export const SelectedSelectableItem = styled(SelectableItem)(({ theme }) => ({
  background: 'none',
  color: theme.colors.text.primary,
  '&:hover': {
    background: 'none',
  },
}));

export const UnmatchedPolicyControls = styled.div(() => ({
  maxHeight: 34,
  display: 'flex',
}));
