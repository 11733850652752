import { OptionallyVisible } from 'components';
import { FieldArray, FieldArrayRenderProps, getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import IconAdd from 'components/icons/add';
import IconRemove from 'components/icons/dash';
import { InputType } from 'components/inputs';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, FormType, LABELS } from '../../../keys';
import { Section } from '../../../styles';
import { AssociatedPersonType } from '../../../types';
import Address from '../../address/address';
import Documents from '../../documents/documents';
import FormikCalendar from '../../formikCalendar/formikCalendar';
import FormikInput from '../../formikTextInput/formikTextInput';
import IdentifyingInformation from '../../identifyingInformation/identifyingInformation';
import FormikSwitch from '../../switch/switch';
import { Container, RecordButton, RemoveContainer, Title } from './styles';

interface StepFormProps {
  // path to the field in the formik values, must point to a field of an array type: AssociatedPersonType[]
  fieldPath: string;

  countries: SelectItem[];
  insertPerson: FieldArrayRenderProps['insert'];
  removePerson: FieldArrayRenderProps['remove'];

  error?: Record<string, string>;
}

export default ({ error, countries, insertPerson, removePerson, fieldPath }: StepFormProps) => {
  const { t } = useTranslation();

  const { values, errors } = useFormikContext<FormType>();
  const associatedPersons = getIn(values, fieldPath) as AssociatedPersonType[];

  const handleAddPersonClick = () => {
    insertPerson(associatedPersons.length + 1, { ...FORMIK_INITIAL_VALUES.associatedPersons[0] });
  };

  const handleRemovePersonClick = (index: number) => {
    return () => {
      removePerson(index);
    };
  };

  const persons = associatedPersons.map((_, index) => {
    const test = `${fieldPath}[${index}]`; // errors coming from .test() rules

    const documents = `${fieldPath}[${index}].documents`;
    const identifyingInformation = `${fieldPath}[${index}].identifyingInformation`;
    const firstName = `${fieldPath}[${index}].firstName`;
    const lastName = `${fieldPath}[${index}].lastName`;
    const email = `${fieldPath}[${index}].email`;
    const birthDate = `${fieldPath}[${index}].birthDate`;
    const relationshipEstablishedAt = `${fieldPath}[${index}].relationshipEstablishedAt`;
    const phone = `${fieldPath}[${index}].phone`;
    const address = `${fieldPath}[${index}].residentialAddress`;
    const title = `${fieldPath}[${index}].title`;
    const ownershipPercentage = `${fieldPath}[${index}].ownershipPercentage`;

    const hasOwnership = `${fieldPath}[${index}].hasOwnership`;
    const hasControl = `${fieldPath}[${index}].hasControl`;
    const isSigner = `${fieldPath}[${index}].isSigner`;
    const isDirector = `${fieldPath}[${index}].isDirector`;

    const testError = getIn(errors, test);
    const testErrorAsString = typeof testError === 'string' ? testError : undefined;

    return (
      <>
        <Title>{t(LABELS.SECTIONS.ASSOCIATED_PERSON.TITLE)}</Title>

        <Section>
          <FormikInput
            label={t(LABELS.SECTIONS.FIRST_NAME.TITLE)}
            fieldPath={firstName}
            placeholder={t(LABELS.SECTIONS.FIRST_NAME.PLACEHOLDER)}
            error={error}
          />

          <FormikInput
            label={t(LABELS.SECTIONS.LAST_NAME.TITLE)}
            fieldPath={lastName}
            placeholder={t(LABELS.SECTIONS.LAST_NAME.PLACEHOLDER)}
            error={error}
          />

          <FormikInput
            label={t(LABELS.SECTIONS.EMAIL.TITLE)}
            fieldPath={email}
            placeholder={t(LABELS.SECTIONS.EMAIL.PLACEHOLDER)}
            error={error}
          />

          <FormikCalendar
            placeholder={t(LABELS.SECTIONS.BIRTH_DATE.PLACEHOLDER)}
            fieldPath={birthDate}
            label={t(LABELS.SECTIONS.BIRTH_DATE.PLACEHOLDER)}
          />

          <FormikInput
            label={t(LABELS.SECTIONS.PHONE.TITLE)}
            fieldPath={phone}
            placeholder={t(LABELS.SECTIONS.PHONE.PLACEHOLDER)}
            error={error}
          />

          <Address title={LABELS.SECTIONS.ADDRESS.RESIDENTIAL_ADDRESS_TITLE} fieldPath={address} countries={countries} error={error} />

          <FormikSwitch label={t(LABELS.SECTIONS.HAS_OWNERSHIP.LABEL)} fieldPath={hasOwnership} />
          <FormikSwitch label={t(LABELS.SECTIONS.HAS_CONTROL.LABEL)} fieldPath={hasControl} />
          <FormikSwitch label={t(LABELS.SECTIONS.IS_SIGNER.LABEL)} fieldPath={isSigner} />
          <FormikSwitch label={t(LABELS.SECTIONS.IS_DIRECTOR.LABEL)} fieldPath={isDirector} />

          <FormikInput
            label={t(LABELS.SECTIONS.TITLE.TITLE)}
            fieldPath={title}
            placeholder={t(LABELS.SECTIONS.TITLE.PLACEHOLDER)}
            error={error}
          />
          <FormikInput
            type={InputType.Number}
            label={t(LABELS.SECTIONS.OWNERSHIP_PERCENTAGE.TITLE)}
            fieldPath={ownershipPercentage}
            placeholder={t(LABELS.SECTIONS.OWNERSHIP_PERCENTAGE.PLACEHOLDER)}
            error={error}
          />

          <FormikCalendar
            placeholder={t(LABELS.SECTIONS.RELATIONSHIP_AT.PLACEHOLDER)}
            fieldPath={relationshipEstablishedAt}
            label={t(LABELS.SECTIONS.RELATIONSHIP_AT.TITLE)}
          />
        </Section>

        <Container>
          <FieldArray
            render={({ insert, remove }) => (
              <IdentifyingInformation
                error={error}
                insert={insert}
                remove={remove}
                countries={countries}
                fieldPath={identifyingInformation}
              />
            )}
            name={identifyingInformation}
          />
          <FieldArray
            render={({ insert, remove }) => <Documents error={error} insert={insert} remove={remove} fieldPath={documents} />}
            name={documents}
          />

          <Section>
            <FormField error={testErrorAsString}>{/* test error will be displayed here */}</FormField>
          </Section>

          <OptionallyVisible visible={index > 0}>
            <RemoveContainer>
              <RecordButton type="button" inside flat onClick={handleRemovePersonClick(index)}>
                <IconRemove />
                {t(LABELS.SECTIONS.ASSOCIATED_PERSON.REMOVE)}
              </RecordButton>
            </RemoveContainer>
          </OptionallyVisible>
        </Container>
      </>
    );
  });

  return (
    <>
      {persons}
      <RecordButton type="button" flat onClick={handleAddPersonClick}>
        <IconAdd />
        {t(LABELS.SECTIONS.ASSOCIATED_PERSON.ADD)}
      </RecordButton>
    </>
  );
};
