import styled from '@emotion/styled/macro';

import { Button } from 'components/buttons';
import { Path } from 'components/icons/styles';

import { COLUMN_IDS } from './keys';

export const DataTableContainer = styled.div((props) => ({
  [`& [data-column="${COLUMN_IDS.PAYOUT_ID}"]`]: {
    minWidth: 150,
    maxWidth: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const DataBar = styled.div<{ hasPayouts: boolean }>((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  paddingLeft: 10,
  fontSize: 13,
  paddingBottom: props.hasPayouts ? 0 : 11,
}));

export const Section = styled.div((props) => ({
  display: 'flex',
  gap: 4,
  fontWeight: 500,
}));

export const SectionTitle = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  color: props.theme.colors.inactive,

  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));

export const SectionContent = styled.div((props) => ({
  color: props.theme.colors.text.primary,
}));

export const StyledButton = styled(Button)((props) => ({
  marginLeft: 'auto',
  marginRight: 30,
}));
