import { SelectItem } from 'components/inputs/select/select';

const replaceLabelsUnderscore = (data: SelectItem[]): SelectItem[] => {
  return data.map((item) => ({
    ...item,
    label: item?.label?.replaceAll('_', ' '),
  }));
};

export default replaceLabelsUnderscore;
