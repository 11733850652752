import { useNotifications } from 'providers/notifications/useNotifications';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';
import { OrderStatusBadge } from 'components/pwcOrderStatusBadge/orderStatusBadge';

import RoutePath from 'router/path';

import { APIError } from 'types/error';
import { NotificationSeverity } from 'types/notifications';
import { PwcOrderStatus, UnmatchedDepositAmountPolicy } from 'types/pwcOrder';

import { formatDateLocal } from 'utils/date';

import { LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import {
  AmountBlock,
  AmountNumericValue,
  AmountValue,
  CancelButton,
  Delimiter,
  Item,
  ItemName,
  ItemValue,
  Link,
  SectionWrapper,
  UnlockButton,
} from '../styles';
import { formatNumber } from '../utils';
import { CancelOrderModal } from './components/cancelOrderModal';

interface OrderSectionPropsWithUserType extends OrderPwcSectionProps {
  isS4cUser: boolean;
  isPartnerAdmin: boolean;
  unlock: {
    isLoading: boolean;
    trigger: () => Promise<{ error?: APIError }>;
  };
  cancel: {
    trigger: () => Promise<{ error?: APIError }>;
    isLoading: boolean;
  };
}

const POLICY_TITLES: Record<UnmatchedDepositAmountPolicy, string> = {
  [UnmatchedDepositAmountPolicy.FLEXIBLE_AMOUNT]: LABELS.SECTIONS.ORDER.FLEXIBLE_AMOUNT as unknown as string,
  [UnmatchedDepositAmountPolicy.AUTOMATIC_REFUND]: LABELS.SECTIONS.ORDER.AUTOMATIC_REFUND as unknown as string,
  [UnmatchedDepositAmountPolicy.INCOMPLETE_PAYMENT]: LABELS.SECTIONS.ORDER.INCOMPLETE_PAYMENT as unknown as string,
};

const getUnmatchedDepositAmountPolicyTitle = (policy: UnmatchedDepositAmountPolicy): string => {
  return POLICY_TITLES[policy];
};

export const OrderSection = ({ orderData, isS4cUser, isPartnerAdmin, unlock, cancel }: OrderSectionPropsWithUserType) => {
  const { t } = useTranslation();
  const { pushToast } = useNotifications();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const onAction = async (type: 'unlock' | 'cancel') => {
    const action = type === 'unlock' ? unlock : cancel;
    const result = await action.trigger();
    if (result.error) {
      pushToast({
        severity: NotificationSeverity.error,
        message: t(LABELS.SECTIONS.ORDER.ACTION_ORDER_ERROR, { action: type }),
      });
    } else {
      pushToast({
        severity: NotificationSeverity.success,
        message: t(LABELS.SECTIONS.ORDER.ACTION_ORDER_SUCCESS, { action: type }),
      });
    }
    if (type === 'cancel') {
      closeCancelModal();
    }
  };

  const openCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  return (
    <Panel label={t(LABELS.SECTIONS.ORDER.TITLE)}>
      <CancelOrderModal cancel={() => onAction('cancel')} orderData={orderData} isOpen={isCancelModalOpen} onClose={closeCancelModal} />
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.ORDER_ID)}</ItemName>
          <ItemValue>{orderData.general.uuid}</ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.general.externalOrderId)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.EXTERNAL_ORDER_ID)}</ItemName>
            <ItemValue>{orderData.general.externalOrderId}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={isS4cUser}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.MERCHANT_NAME)}</ItemName>
            <ItemValue>
              <Link to={RoutePath.pwc.accountById(orderData.merchant.uuid)}>{orderData.merchant.name}</Link>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.groupAccount)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.GROUP_ACCOUNT_NAME)}</ItemName>
            <ItemValue>{orderData.groupAccount?.name}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.CREATION_TIME)}</ItemName>
          <ItemValue>{formatDateLocal(orderData.general.createdAt)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.EXPIRATION_TIME)}</ItemName>
          <ItemValue>{formatDateLocal(orderData.general.expiredAt)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.STATUS)}</ItemName>
          <ItemValue>
            <OrderStatusBadge status={orderData.general.status} />
          </ItemValue>
          <OptionallyVisible
            visible={
              (orderData.general.status === PwcOrderStatus.Initiated && (isS4cUser || isPartnerAdmin)) ||
              (orderData.general.status === PwcOrderStatus.Pending && orderData.general.locked && (isS4cUser || isPartnerAdmin))
            }
          >
            <CancelButton
              secondary
              isLoading={cancel.isLoading}
              onClick={openCancelModal}
              loadingIndicatorProps={{
                size: 0.7,
              }}
            >
              {t(LABELS.CANCEL_ORDER)}
            </CancelButton>
          </OptionallyVisible>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.LOCKED)}</ItemName>
          <ItemValue>{orderData.general.locked ? t(LABELS.BOOLEAN.YES) : t(LABELS.BOOLEAN.NO)}</ItemValue>
          <OptionallyVisible visible={orderData.general.locked && isS4cUser}>
            <UnlockButton
              secondary
              isLoading={unlock.isLoading}
              onClick={() => onAction('unlock')}
              loadingIndicatorProps={{
                size: 0.7,
              }}
            >
              {t(LABELS.UNLOCK_ORDER)}
            </UnlockButton>
          </OptionallyVisible>
        </Item>

        <Delimiter />

        <OptionallyVisible visible={Boolean(orderData.general.fiatAmount)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.ORDER_AMOUNT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.general.fiatCurrencyCode?.toUpperCase()}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.general.fiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.general.paidFiatAmount)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.PAID_AMOUNT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.general.fiatCurrencyCode?.toUpperCase()}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.general.paidFiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.PROCESSING_FEE_PERCENT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountNumericValue>{formatNumber(orderData.general.processingFeePercent)}</AmountNumericValue>
              <AmountValue>%</AmountValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.PROCESSING_FEE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{orderData.general.fiatCurrencyCode?.toUpperCase()}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.general.processingFeeFiatAmount)}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.general.unmatchedDepositAmountPolicy)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.UNMATCHED_DEPOSIT_POLICY)}</ItemName>
            <ItemValue>{t(getUnmatchedDepositAmountPolicyTitle(orderData.general.unmatchedDepositAmountPolicy))}</ItemValue>
          </Item>
        </OptionallyVisible>
      </SectionWrapper>
    </Panel>
  );
};
