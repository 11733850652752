import IconBook from 'components/icons/book';
import IconCash from 'components/icons/cash';
import IconReport from 'components/icons/report';

import i18next from 'locales/i18next';

import RoutePath from 'router/path';

import { PartnerMenuItem } from 'types/routing';

const { t } = i18next;

export const menuItems: PartnerMenuItem = {
  ramp: [
    {
      subheader: t('Ramp'),
    },
    {
      label: t('Orders'),
      icon: IconReport,
      to: RoutePath.Ramp.Orders.Root,
    },
    {
      label: t('Documentation'),
      icon: IconBook,
      openInNewWindow: true,
      to: window?.s4cConfig?.documentationUrl || '',
    },
  ],
  pwc: [
    {
      subheader: t('Pay with crypto'),
    },
    {
      label: t('Orders'),
      icon: IconCash,
      to: RoutePath.Pwc.Orders.Root,
    },
    {
      label: t('Documentation'),
      icon: IconBook,
      openInNewWindow: true,
      to: window?.s4cConfig?.documentationUrl || '',
    },
  ],
  pwcGroup: [],
};

export default menuItems;
