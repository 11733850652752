import { t } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { IconEnvelope } from 'components/icons/envelope';
import { Input } from 'components/inputs';
import OptionallyVisible from 'components/optionallyVisible';

import { AccountType } from 'types/account';
import { RequestPagination, ServerPagination } from 'types/pagination';

import { RadioGroup } from '../../../inputs/radio/radioGroup';
import { RemoteSelect } from '../../../inputs/select/remoteSelect';
import { SelectItem } from '../../../inputs/select/select';
import { Switch } from '../../../inputs/switch/switch';
import { KEYS, LABELS } from '../keys';
import { Delimeter } from '../styles';
import { CreatedAccount } from '../types';
import {
  AccountOwnerSection,
  AccountSection,
  Adornment,
  GroupAccountSection,
  Label,
  MerchantIdSection,
  Optional,
  Row,
  Welcome,
} from './form.styles';

interface CreateAccountFormProps {
  formValues: CreatedAccount;
  onFieldValueChange: (key: string, value: any) => void;
  error?: Record<string, string>;
  accountType: AccountType;
  setAccountType: (accountType: AccountType) => void;
  isSubmitting: boolean;
  isWelcomeEmailEnabled: boolean;
  setIsWelcomeEmailEnabled: (bool: boolean) => void;
  getGroupAccounts: (params: {
    search?: string;
    pagination?: RequestPagination;
  }) => Promise<{ data: SelectItem[]; pagination?: ServerPagination }>;
}

const blockOptions = [
  {
    key: AccountType.Ramp,
    value: AccountType.Ramp,
    label: t(LABELS.CREATE_MODAL.HEADER.RAMP_TITLE),
  },
  {
    key: AccountType.Pwc,
    value: AccountType.Pwc,
    selectedOn: [AccountType.Pwc, AccountType.Pwc_Group],
    label: t(LABELS.CREATE_MODAL.HEADER.PWC_TITLE),
  },
];

const pwcOptions = [
  {
    key: AccountType.Pwc,
    value: AccountType.Pwc,
    label: t(LABELS.CREATE_MODAL.PWC_OPTIONS.MERCHANT),
  },
  {
    key: AccountType.Pwc_Group,
    value: AccountType.Pwc_Group,
    label: t(LABELS.CREATE_MODAL.PWC_OPTIONS.GROUP_ACCOUNT),
  },
];

export const CreateAccountModalForm = ({
  formValues,
  error,
  onFieldValueChange,
  accountType,
  setAccountType,
  getGroupAccounts,
  isWelcomeEmailEnabled,
  setIsWelcomeEmailEnabled,
  isSubmitting,
}: CreateAccountFormProps) => {
  const { t } = useTranslation();

  const handleFormChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  const handleAccountTypeChange = (type: string) => {
    setAccountType(type as AccountType);
  };

  const handleGroupAccountChange = (item: SelectItem | SelectItem[]) => {
    handleFormChange(KEYS.FORM_FIELDS.GROUP_ACCOUNT_ID)((item as SelectItem).key);
    setIsWelcomeEmailEnabled(false);
  };

  const handleWelcomeEmailChange = (value: boolean) => {
    setIsWelcomeEmailEnabled(value);
  };

  return (
    <>
      <RadioGroup horizontal options={blockOptions} onChange={handleAccountTypeChange} value={accountType} />

      <Delimeter />

      <OptionallyVisible visible={accountType === AccountType.Pwc || accountType === AccountType.Pwc_Group}>
        <RadioGroup horizontal options={pwcOptions} onChange={handleAccountTypeChange} value={accountType} />
      </OptionallyVisible>

      <AccountSection>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_SECTION.TITLE)}</Label>
        <FormField error={error?.accountName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.ACCOUNT_NAME)}
            value={formValues.accountName}
            onChange={handleFormChange(KEYS.FORM_FIELDS.ACCOUNT_NAME)}
          />
        </FormField>
      </AccountSection>

      <OptionallyVisible visible={accountType === AccountType.Pwc}>
        <GroupAccountSection>
          <Label>
            {t(LABELS.CREATE_MODAL.SECTIONS.GROUP_ACCOUNT_SECTION.TITLE)} <Optional>{t(LABELS.CREATE_MODAL.FORM_FIELDS.OPTIONAL)}</Optional>
          </Label>
          <RemoteSelect<SelectItem, boolean>
            fullWidth
            value={formValues.groupAccountId}
            onChange={handleGroupAccountChange}
            multiple={false}
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.GROUP_ACCOUNT_ID)}
            getItems={getGroupAccounts}
            getItemLabel={(item) => item.label}
            closeDropdownOnSelect
          />
        </GroupAccountSection>

        <MerchantIdSection>
          <Label>
            {t(LABELS.CREATE_MODAL.SECTIONS.MERCHANT_ID_SECTION.TITLE)} <Optional>{t(LABELS.CREATE_MODAL.FORM_FIELDS.OPTIONAL)}</Optional>
          </Label>
          <FormField error={error?.shift4MerchantId}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.MERCHANT_ID)}
              value={formValues.shift4MerchantId}
              onChange={handleFormChange(KEYS.FORM_FIELDS.MERCHANT_ID)}
            />
          </FormField>
        </MerchantIdSection>
      </OptionallyVisible>

      <AccountOwnerSection>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_OWNER_SECTION.TITLE)}</Label>
        <FormField error={error?.username}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.EMAIL)}
            value={formValues.username}
            onChange={handleFormChange(KEYS.FORM_FIELDS.EMAIL)}
            startAdornment={
              <Adornment>
                <IconEnvelope />
              </Adornment>
            }
          />
        </FormField>

        <FormField error={error?.firstName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.FIRST_NAME)}
            value={formValues.firstName}
            onChange={handleFormChange(KEYS.FORM_FIELDS.FIRST_NAME)}
          />
        </FormField>

        <FormField error={error?.lastName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.LAST_NAME)}
            value={formValues.lastName}
            onChange={handleFormChange(KEYS.FORM_FIELDS.LAST_NAME)}
          />
        </FormField>
      </AccountOwnerSection>

      <OptionallyVisible visible={accountType === AccountType.Pwc}>
        <Row>
          <Welcome>
            <Trans i18nKey={LABELS.CREATE_MODAL.SECTIONS.WELCOME_EMAIL_SECTION.TITLE[1]} components={[<br />]} />
          </Welcome>
          <Switch disabled={isSubmitting} value={isWelcomeEmailEnabled} onChange={handleWelcomeEmailChange} />
        </Row>
      </OptionallyVisible>
    </>
  );
};
