import React from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownMenu } from 'components/dropdownMenu/dropdownMenu';
import IconChevron from 'components/icons/chevron';
import IconProfileCircled from 'components/icons/profileCircled';
import OptionallyVisible from 'components/optionallyVisible';

import { AccountInfo } from 'types/account';

import { LABELS } from './keys';
import {
  ArrowIcon,
  ButtonLabel,
  ButtonLabelContent,
  Delimeter,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemLabel,
  MenuTitle,
  ProfileIcon,
  ScrollableItemList,
} from './styles';

interface ProfileMenuProps {
  onAccountSwitch: (account: AccountInfo) => void;
  selectedAccount?: AccountInfo;
  rampAccounts: AccountInfo[];
  pwcAvailableAccounts: AccountInfo[];
  rampAvailableAccounts: AccountInfo[];
  logout: () => void;
  createAccount: () => void;
  navigateToProfile: () => void;
  isAccountCreationAllowed: boolean;
}

export const ProfileMenu = ({
  onAccountSwitch,
  selectedAccount,
  rampAccounts = [],
  pwcAvailableAccounts,
  rampAvailableAccounts,
  navigateToProfile,
  logout,
  createAccount,
  isAccountCreationAllowed,
}: ProfileMenuProps) => {
  const { t } = useTranslation();
  const buttonLabel = selectedAccount?.name || t(LABELS.DEFAULT_LABEL);
  const areRampAccountsAvailable = rampAvailableAccounts.length !== 0;
  const arePwcAccountsAvailable = pwcAvailableAccounts.length !== 0;

  const getAccountSwitchHandler = (uuid: string, close: () => void) => () => {
    const nextAccount = rampAccounts.find((account) => account.uuid === uuid);
    close();
    if (!nextAccount) {
      return;
    }

    onAccountSwitch(nextAccount);
  };
  const createAccountAndClose = (close: () => void) => () => {
    createAccount();
    close();
  };
  const renderButton = ({ open, onClick }: { open: boolean; onClick: (event: React.MouseEvent<HTMLElement>) => void }) => (
    <MenuButton secondary onClick={onClick}>
      <ButtonLabelContent>
        <ProfileIcon>
          <IconProfileCircled />
        </ProfileIcon>
        <ButtonLabel>{buttonLabel}</ButtonLabel>
        <ArrowIcon rotated={open}>
          <IconChevron />
        </ArrowIcon>
      </ButtonLabelContent>
    </MenuButton>
  );

  const renderMenu = (close: () => void) => (
    <Menu>
      <MenuItem onClick={navigateToProfile}>{t(LABELS.PROFILE)}</MenuItem>
      <OptionallyVisible visible={areRampAccountsAvailable || arePwcAccountsAvailable}>
        <Delimeter />
        <OptionallyVisible visible={areRampAccountsAvailable}>
          <MenuTitle>{t(LABELS.RAMP_ACCOUNTS)}</MenuTitle>
          <ScrollableItemList>
            {rampAvailableAccounts.map((account) => (
              <MenuItem key={account.uuid} onClick={getAccountSwitchHandler(account.uuid, close)}>
                <MenuItemLabel>{account.name}</MenuItemLabel>
              </MenuItem>
            ))}
          </ScrollableItemList>
        </OptionallyVisible>
        <OptionallyVisible visible={arePwcAccountsAvailable}>
          <MenuTitle>{t(LABELS.PWC_ACCOUNTS)}</MenuTitle>
          <ScrollableItemList>
            {pwcAvailableAccounts.map((account) => (
              <MenuItem key={account.uuid} onClick={getAccountSwitchHandler(account.uuid, close)}>
                <MenuItemLabel>{account.name}</MenuItemLabel>
              </MenuItem>
            ))}
          </ScrollableItemList>
        </OptionallyVisible>
        <Delimeter />
      </OptionallyVisible>

      <OptionallyVisible visible={isAccountCreationAllowed}>
        <MenuItem onClick={createAccountAndClose(close)}>{t(LABELS.CREATE_ACCOUNT)}</MenuItem>
      </OptionallyVisible>

      <MenuItem onClick={logout}>{t(LABELS.LOGOUT)}</MenuItem>
    </Menu>
  );

  return <DropdownMenu renderButton={renderButton} renderMenu={renderMenu} popperProps={{ placement: 'bottom-end' }} />;
};
