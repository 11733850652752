import { makeLabels } from 'utils/i18n';

export const KEYS = {
  COPY_MESSAGE_TIMEOUT: 2000,
};

const NAMESPACE = 'buttons';
export const LABELS = makeLabels(
  {
    COPY_MESSAGE: ['copied', 'Copied!'],
  },
  NAMESPACE,
);

export default LABELS;
