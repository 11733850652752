import { createApi } from '@reduxjs/toolkit/query/react';
import i18n, { t } from 'i18next';
import { Endpoint } from 'services/endpoints';

import { Customer, CustomerCard } from 'types/customer';
import { HTTPMethod, Response, SuccessResponse } from 'types/http';

import { getErrorDetails } from 'utils/error';
import { getDefaultPagination } from 'utils/pagination';

import { APIError } from '../../types/error';
import { formatFiatCurrencyI18n } from '../../utils/i18n';
import { fetchBaseQueryWithAuth } from '../api';
import { KEYS, LABELS, TAGS } from './keys';
import {
  BalanceItem,
  CreateOrderRequest,
  CreateOrderResponse,
  CustomerBalancesResponse,
  CustomersListQuery,
  CustomersListResponse,
  GetOrderPreviewRequest,
  GetOrderPreviewResponse,
  GetQuoteQuery,
  GetQuoteResponse,
  IncreaseKYCAttemptsResponse,
  KYCStatusResponse,
  MakeRefundRequest,
  RefundResponse,
  UpdateStatusRequest,
} from './types';

const REDUCER_PATH = 'customersApi';

export const customersApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.CUSTOMER_RELATED, TAGS.CARDS, TAGS.CUSTOMERS],
  endpoints: (builder) => ({
    getCustomersList: builder.query<CustomersListResponse, CustomersListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Clients.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<CustomersListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination() };
        }

        return response.data;
      },
      providesTags: (result) => (result?.data ? result.data.map(({ uuid }) => ({ type: TAGS.CUSTOMERS, id: uuid })) : [TAGS.CUSTOMERS]),
    }),
    fetchKYCStatus: builder.mutation<KYCStatusResponse | null, string>({
      query: (customerUUID) => ({
        url: Endpoint.Clients.RefreshKYCStatus(customerUUID),
        method: HTTPMethod.POST,
      }),
      transformResponse: (response: Response<KYCStatusResponse>) => {
        if (!response.data) {
          return null;
        }

        return response.data;
      },
      transformErrorResponse: (baseQueryReturnValue): unknown => {
        return baseQueryReturnValue.data;
      },
    }),
    increaseKYCAttempts: builder.mutation<IncreaseKYCAttemptsResponse | null, string>({
      query: (customerUUID) => ({
        url: Endpoint.Clients.IncreaseKYCAttempts(customerUUID),
        method: HTTPMethod.POST,
      }),
      transformResponse: (response: Response<IncreaseKYCAttemptsResponse>) => {
        if (!response.data) {
          return null;
        }

        return response.data;
      },
      transformErrorResponse: (baseQueryReturnValue): unknown => {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (_, error) => (error ? [] : [TAGS.CUSTOMER_RELATED]),
    }),
    getCustomer: builder.query<Customer, string>({
      query: (uuid) => Endpoint.Clients.ById(uuid),
      transformResponse: (response: Response<Customer>) => response.data!,
      providesTags: (_, __, id) => [TAGS.CUSTOMER_RELATED, { type: TAGS.CUSTOMERS, id }],
      forceRefetch: () => true,
    }),
    getCards: builder.query<CustomerCard[], string>({
      query: (uuid) => Endpoint.Clients.Cards(uuid),
      transformResponse: (response: Response<{ cards: CustomerCard[] }>) => response.data!.cards,
      providesTags: [TAGS.CUSTOMER_RELATED, TAGS.CARDS],
    }),
    getOrderPreview: builder.query<GetOrderPreviewResponse, GetOrderPreviewRequest>({
      query: ({ clientUuid, ...body }) => ({
        url: Endpoint.Clients.GetOrderPreview(clientUuid),
        method: HTTPMethod.POST,
        body: {
          ...body,
          cryptoAmount: body.cryptoAmount?.toString(),
        },
      }),
      transformResponse: (response: Response<{ quote: GetOrderPreviewResponse }>) => response.data!.quote,
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => {
        const details = getErrorDetails(baseQueryReturnValue);
        if (typeof details === 'object') {
          return details;
        }

        const { meta: errorMeta } = baseQueryReturnValue?.data as APIError;
        if (errorMeta?.currencyType === KEYS.FIAT_CURRENCY_TYPE && errorMeta?.minAmount) {
          const message = t(LABELS.ERRORS.GET_ORDER_PREVIEW.MIN_FIAT_AMOUNT, {
            minAmount: formatFiatCurrencyI18n(i18n.language)(errorMeta.minAmount, arg.fiatCurrencyCode),
            requestAmount: formatFiatCurrencyI18n(i18n.language)(errorMeta.requestAmount, arg.fiatCurrencyCode),
          });
          return { cryptoAmount: message };
        }

        if (errorMeta?.currencyType === KEYS.FIAT_CURRENCY_TYPE && errorMeta?.maxAmount) {
          const message = t(LABELS.ERRORS.GET_ORDER_PREVIEW.MAX_FIAT_AMOUNT, {
            maxAmount: formatFiatCurrencyI18n(i18n.language)(errorMeta.maxAmount, arg.fiatCurrencyCode),
            requestAmount: formatFiatCurrencyI18n(i18n.language)(errorMeta.requestAmount, arg.fiatCurrencyCode),
          });

          return { cryptoAmount: message };
        }

        return {
          general: details,
        };
      },
    }),
    createOrder: builder.mutation<CreateOrderResponse, CreateOrderRequest>({
      query: ({ clientUuid, ...body }) => ({
        url: Endpoint.Clients.CreateOrder(clientUuid),
        method: HTTPMethod.POST,
        body,
      }),
      transformResponse: (response: Response<CreateOrderResponse>) => response.data!,
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => {
        const details = getErrorDetails(baseQueryReturnValue);
        return details;
      },
    }),
    getRefundQuote: builder.query<GetQuoteResponse, GetQuoteQuery>({
      query: ({ customerUuid, ...body }) => ({
        url: Endpoint.Clients.GetQuote(customerUuid),
        method: HTTPMethod.POST,
        body,
      }),
      transformResponse: (response: Response<GetQuoteResponse>) => response.data!,
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => {
        const details = getErrorDetails(baseQueryReturnValue);
        return details;
      },
    }),
    makeRefund: builder.mutation<RefundResponse, MakeRefundRequest>({
      query: ({ clientUuid, ...body }) => ({
        url: Endpoint.Clients.Refund(clientUuid),
        method: HTTPMethod.POST,
        body,
      }),
      transformResponse: (response: Response<RefundResponse>) => response.data!,
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => {
        const details = getErrorDetails(baseQueryReturnValue);
        return details;
      },
    }),
    getCustomerBalances: builder.query<BalanceItem[], string>({
      query: (uuid) => Endpoint.Clients.Balances(uuid),
      transformResponse: (response: Response<CustomerBalancesResponse>) => response.data?.balances || [],
      providesTags: [TAGS.CUSTOMER_RELATED],
    }),
    changeUserStatus: builder.mutation<SuccessResponse, UpdateStatusRequest>({
      query: ({ clientUuid, ...body }) => ({
        url: Endpoint.Clients.Status(clientUuid),
        method: HTTPMethod.POST,
        body,
      }),
      transformResponse: (response: Response<SuccessResponse>) => response.data!,
      transformErrorResponse: (baseQueryReturnValue): unknown => {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (_, error, { clientUuid }) => (error ? [] : [TAGS.CUSTOMER_RELATED, { type: TAGS.CUSTOMERS, id: clientUuid }]),
    }),
  }),
});

export const { getCustomersList, getCustomer, fetchKYCStatus, increaseKYCAttempts } = customersApi.endpoints;
export const {
  useGetCustomersListQuery,
  useGetCustomerQuery,
  useFetchKYCStatusMutation,
  useIncreaseKYCAttemptsMutation,
  useGetCustomerBalancesQuery,
  useLazyGetCustomerBalancesQuery,
  useLazyGetRefundQuoteQuery,
  useMakeRefundMutation,
  useChangeUserStatusMutation,
  useGetCardsQuery,
  useLazyGetOrderPreviewQuery,
  useCreateOrderMutation,
} = customersApi;
export default customersApi;
