import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { formatDateLocal } from 'utils/date';

import { LABELS } from '../../keys';
import { CustomerPwcSectionProps } from '../../types';
import { Item, ItemName, ItemValue, SectionWrapper } from '../styles';

interface CustomerSectionProps extends CustomerPwcSectionProps {}

export const CustomerSection = ({ customerData }: CustomerSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.CUSTOMER.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.FULL_NAME)}</ItemName>
          <ItemValue>
            {customerData.customer.firstName} {customerData.customer.lastName}
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.CUSTOMER_EMAIL)}</ItemName>
          <ItemValue>{customerData.customer.email}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.CUSTOMER_ID)}</ItemName>
          <ItemValue>{customerData.customer.uuid}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.CREATION_DATE)}</ItemName>
          <ItemValue>{formatDateLocal(customerData.customer.createdAt)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.LAST_PURCHASE)}</ItemName>
          <ItemValue>{formatDateLocal(customerData.customer.lastPurchasedAt)}</ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
