import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetAvailableAccountsQuery, useRevokeAccessToAccountMutation, useUpdateProfileMutation } from 'services/profile';

import { selectProfile } from 'state/selectors/profile/profileSelector';

import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { usePersistentPagination } from 'utils/tables/pagination';

import { AccountsSection } from './accounts';
import { AccountDataRow } from './types';
import { transformAccountsDataToTableFormat } from './utils';

export const AccountsSectionContainer = () => {
  const { profile } = useSelector(selectProfile);
  const { pagination, setPagination, changeRowsPerPage, changePage } = usePersistentPagination(getDefaultPagination(), [profile]);

  const { data, isFetching } = useGetAvailableAccountsQuery({
    pagination: undecoratePagination(pagination),
  });

  const [updateProfile] = useUpdateProfileMutation();
  const [revokeAccessToAccountByUuid] = useRevokeAccessToAccountMutation();

  const setAccountAsDefault = (accountId: string) => {
    updateProfile({
      defaultAccountUuid: accountId,
    });
  };

  const revokeAccessToAccount = (accountId: string) => {
    revokeAccessToAccountByUuid({
      accountUuid: accountId,
      userUuid: profile.uuid,
    });
  };

  useEffect(() => {
    if (data?.pagination) {
      setPagination(decoratePagination(data?.pagination));
    }
  }, [data?.pagination]);

  const accounts: AccountDataRow[] = transformAccountsDataToTableFormat(data?.data);

  return (
    <AccountsSection
      pagination={pagination}
      accounts={accounts}
      isLoading={isFetching}
      onChangePage={changePage}
      onChangeRowsPerPage={changeRowsPerPage}
      setAccountAsDefault={setAccountAsDefault}
      revokeAccessToAccount={revokeAccessToAccount}
    />
  );
};
