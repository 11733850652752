import styled from '@emotion/styled/macro';

import { COLUMN_IDS } from './keys';

export const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  padding: '5px 0',
  [`& [data-column="${COLUMN_IDS.STATUS}"]`]: {
    minWidth: 100,
  },
  [`& [data-column="${COLUMN_IDS.DATE}"]`]: {
    minWidth: 180,
  },
  [`
  & [data-column="${COLUMN_IDS.ID}"],
  & [data-column="${COLUMN_IDS.CUSTOMER}"]
  `]: {
    minWidth: 120,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`
  & [data-column="${COLUMN_IDS.ORDER_AMOUNT}"],
  & [data-column="${COLUMN_IDS.PAID_AMOUNT}"]
  `]: {
    maxWidth: 300,
    textAlign: 'right',
    '& .MuiTableSortLabel-root': {
      flexDirection: 'row-reverse',
    },
  },
}));

export const Search = styled.div((props) => ({}));
export const ActionBar = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 8,
}));
