import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, LABELS } from '../../keys';
import { AddressType } from '../../types';
import FormikInput from '../formikTextInput/formikTextInput';
import { Container, Label } from './styles';

interface Props {
  title: string[];
  // path to the field in the formik values, must point to a field of type: AddressType
  fieldPath: string;
  countries: SelectItem[];
  //  for errors coming from backend
  error?: Record<string, string>;
}

const Address = ({ title, fieldPath, countries, error }: Props) => {
  const { t } = useTranslation();

  const streetLine1 = `${fieldPath}.streetLine1`;
  const streetLine2 = `${fieldPath}.streetLine2`;
  const city = `${fieldPath}.city`;
  const country = `${fieldPath}.country`;
  const postalCode = `${fieldPath}.postalCode`;
  const subdivision = `${fieldPath}.subdivision`;

  const { values, touched, errors, setFieldValue } = useFormikContext<typeof FORMIK_INITIAL_VALUES>();
  const addressValue = getIn(values, fieldPath) as AddressType;
  const countryError = getIn(errors, `${country}.key`);
  const countryTouched = getIn(touched, country);

  return (
    <Container>
      <Label>{t(title)}</Label>
      <FormikInput fieldPath={streetLine1} placeholder={t(LABELS.SECTIONS.ADDRESS.STREET_LINE_1)} error={error} />
      <FormikInput fieldPath={streetLine2} placeholder={t(LABELS.SECTIONS.ADDRESS.STREET_LINE_2)} error={error} />
      <FormikInput fieldPath={city} placeholder={t(LABELS.SECTIONS.ADDRESS.CITY)} error={error} />
      <FormikInput fieldPath={subdivision} placeholder={t(LABELS.SECTIONS.ADDRESS.SUBDIVISION)} error={error} />
      <FormikInput fieldPath={postalCode} placeholder={t(LABELS.SECTIONS.ADDRESS.POSTAL_CODE)} error={error} />
      <FormField error={countryError && countryTouched ? countryError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={addressValue.country}
          items={countries}
          placeholder={t(LABELS.SECTIONS.ADDRESS.COUNTRY)}
          onChange={(selectItem) => {
            setFieldValue(country, selectItem);
          }}
          getItemLabel={(item) => item.label}
          multiple={false}
          closeDropdownOnSelect
          popperProps={{ disablePortal: true }}
        />
      </FormField>
    </Container>
  );
};

export default Address;
