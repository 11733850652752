export const environments = {
  production: {
    bankName: '',
    accountOwnerName: '',
    firstName: '',
    lastName: '',
    businessName: '',
    accountNumber: '',
    routingNumber: '',
    streetLine1: '',
    streetLine2: '',
    city: '',
    state: '',
    postalCode: '',
  },
  sandbox: {
    bankName: 'Test Bank',
    accountOwnerName: 'John Doe',
    firstName: 'John',
    lastName: 'Doe',
    businessName: 'Test Company, Inc.',
    accountNumber: '000123456789',
    routingNumber: '110000000',
    streetLine1: '11208 Zena Inlet',
    streetLine2: '',
    city: 'East Milton',
    state: 'NY',
    postalCode: '61250-4528',
  },
};
