import { Dialog } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import questionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCross from 'components/icons/cross';
import { Body, BoldMessage, Content, Delimeter, Image, Message, ModalControls, TitleMessage } from 'components/modal/styles';

import { LABELS } from '../../../keys';
import { OrderPwcSectionProps } from '../../../types';
import { ModalRoot, StyledButtons } from './styles';

interface CancelOrderModalProps extends OrderPwcSectionProps {
  isOpen: boolean;
  onClose: () => void;
  cancel: () => void;
}

export const CancelOrderModal = ({ isOpen, onClose, orderData, cancel }: CancelOrderModalProps) => {
  const { t } = useTranslation();

  const closeModal = () => {
    onClose();
  };

  return (
    <Dialog scroll="body" open={isOpen} onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.SECTIONS.CANCEL_ORDER.MODAL_TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <Body>
            <Content>
              <Image src={questionImage} />
              <TitleMessage>{t(LABELS.SECTIONS.CANCEL_ORDER.TITLE)}</TitleMessage>
              <Message>
                <Trans
                  i18nkey={LABELS.SECTIONS.CANCEL_ORDER.MESSAGE[1]}
                  defaults={LABELS.SECTIONS.CANCEL_ORDER.MESSAGE[1]}
                  values={{
                    amount: orderData.general.fiatAmount,
                    code: orderData.general.fiatCurrencyCode?.toUpperCase(),
                  }}
                  components={[<BoldMessage />]}
                />
              </Message>
            </Content>
            <Delimeter />
            <StyledButtons>
              <Button secondary onClick={closeModal}>
                {t(LABELS.SECTIONS.CANCEL_ORDER.CANCEL)}
              </Button>
              <Button onClick={cancel}>{t(LABELS.SECTIONS.CANCEL_ORDER.CONFIRM)}</Button>
            </StyledButtons>
          </Body>
        </Panel>
      </ModalRoot>
    </Dialog>
  );
};
