import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { Input, InputType } from 'components/inputs';

import LABELS, { CUSTOMER_FORM_FIELDS, CustomerFormFieldType, FORM_FIELDS, FormFieldType } from '../keys';
import { FormContainer } from '../styles';

interface CustomerDetailsProps {
  readOnlyMode: boolean;
  values: Record<FormFieldType, Record<CustomerFormFieldType, string>>;
  errors?: FormikErrors<Record<FormFieldType, string>>;
  setFieldValue: (field: FormFieldType, value: string) => void;
}

const CustomerDetails = ({ values, readOnlyMode, setFieldValue, errors }: CustomerDetailsProps) => {
  const { t } = useTranslation();

  const getFormPathForCustomerField = (field: string) => `${FORM_FIELDS.CUSTOMER}.${field}` as FormFieldType;

  const mapErrors = (errors?: FormikErrors<Record<FormFieldType, string>>): FormikErrors<Record<CustomerFormFieldType, string>> => ({
    [CUSTOMER_FORM_FIELDS.EMAIL]: errors?.[getFormPathForCustomerField(CUSTOMER_FORM_FIELDS.EMAIL)],
    [CUSTOMER_FORM_FIELDS.FIRST_NAME]: errors?.[getFormPathForCustomerField(CUSTOMER_FORM_FIELDS.FIRST_NAME)],
    [CUSTOMER_FORM_FIELDS.LAST_NAME]: errors?.[getFormPathForCustomerField(CUSTOMER_FORM_FIELDS.LAST_NAME)],
  });

  const fields: FormFieldDescription[] = [
    {
      key: CUSTOMER_FORM_FIELDS.EMAIL,
      label: t(LABELS.CUSTOMER_FORM_FIELDS.LABELS[CUSTOMER_FORM_FIELDS.EMAIL]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={values?.[FORM_FIELDS.CUSTOMER]?.[CUSTOMER_FORM_FIELDS.EMAIL]}
          onChange={(value) => setFieldValue(getFormPathForCustomerField(CUSTOMER_FORM_FIELDS.EMAIL), value)}
          disabled={readonly}
          placeholder={t(LABELS.CUSTOMER_FORM_FIELDS.PLACEHOLDERS[CUSTOMER_FORM_FIELDS.EMAIL])}
        />
      ),
    },
    {
      key: CUSTOMER_FORM_FIELDS.FIRST_NAME,
      label: t(LABELS.CUSTOMER_FORM_FIELDS.LABELS[CUSTOMER_FORM_FIELDS.FIRST_NAME]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={values?.[FORM_FIELDS.CUSTOMER]?.[CUSTOMER_FORM_FIELDS.FIRST_NAME]}
          onChange={(value) => setFieldValue(getFormPathForCustomerField(CUSTOMER_FORM_FIELDS.FIRST_NAME), value)}
          disabled={readonly}
          placeholder={t(LABELS.CUSTOMER_FORM_FIELDS.PLACEHOLDERS[CUSTOMER_FORM_FIELDS.FIRST_NAME])}
        />
      ),
    },
    {
      key: CUSTOMER_FORM_FIELDS.LAST_NAME,
      label: t(LABELS.CUSTOMER_FORM_FIELDS.LABELS[CUSTOMER_FORM_FIELDS.LAST_NAME]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={values?.[FORM_FIELDS.CUSTOMER]?.[CUSTOMER_FORM_FIELDS.LAST_NAME]}
          onChange={(value) => setFieldValue(getFormPathForCustomerField(CUSTOMER_FORM_FIELDS.LAST_NAME), value)}
          disabled={readonly}
          placeholder={t(LABELS.CUSTOMER_FORM_FIELDS.PLACEHOLDERS[CUSTOMER_FORM_FIELDS.LAST_NAME])}
        />
      ),
    },
    {
      key: FORM_FIELDS.EXTERNAL_CUSTOMER_ID,
      label: t(LABELS.FORM_FIELDS.LABELS[FORM_FIELDS.EXTERNAL_CUSTOMER_ID]),
      renderInput: ({ readonly }) => (
        <Input
          fullWidth
          type={InputType.Text}
          value={(values as unknown as Record<FormFieldType, string>)?.[FORM_FIELDS.EXTERNAL_CUSTOMER_ID]}
          onChange={(value) => setFieldValue(FORM_FIELDS.EXTERNAL_CUSTOMER_ID, value)}
          disabled={readonly}
          placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS[FORM_FIELDS.EXTERNAL_CUSTOMER_ID])}
        />
      ),
    },
  ];

  return (
    <Panel label={t(LABELS.CUSTOMER_DETAILS_PANEL_TITLE)}>
      <FormContainer>
        <FormLayout fields={fields} readonly={readOnlyMode} error={mapErrors(errors)} />
      </FormContainer>
    </Panel>
  );
};

export default CustomerDetails;
