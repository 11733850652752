import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import { APIError } from 'types/error';

import { LABELS } from './keys';
import { ChargesListSection } from './sections/chargesList/chargesList';
import { CustomerSection } from './sections/customer/customer';
import { OrderSection } from './sections/order/order';
import { OrderSummarySection } from './sections/orderSummary/orderSummary';
import { TopAreaSection } from './sections/topArea/topArea';
import { Container, SubContainer } from './styles';
import { OrderPwcData } from './types';

export interface OrderDetailsProps {
  orderPwcData: OrderPwcData;
  shouldShowExchangeDetailsSection: boolean;
  hasExtendedAccess: boolean;
  shouldShowSettlementDetailsSection: boolean;
  shouldShowPayoutSection: boolean;
  shouldShowOrderDetailsSection: boolean;
  shouldShowChargesListSection: boolean;
  isS4cUser: boolean;
  isPartnerAdmin: boolean;
  unlock: {
    trigger: () => Promise<{ error?: APIError }>;
    isLoading: boolean;
  };
  cancel: {
    trigger: () => Promise<{ error?: APIError }>;
    isLoading: boolean;
  };
}

export const OrderDetails = ({
  orderPwcData,
  shouldShowExchangeDetailsSection,
  hasExtendedAccess,
  shouldShowSettlementDetailsSection,
  shouldShowPayoutSection,
  shouldShowOrderDetailsSection,
  shouldShowChargesListSection,
  isS4cUser,
  isPartnerAdmin,
  unlock,
  cancel,
}: OrderDetailsProps) => {
  const sectionsProps = {
    orderData: orderPwcData,
  };

  const { t } = useTranslation();

  return (
    <>
      <TopAreaSection {...sectionsProps} />
      <Container>
        <SubContainer>
          <OrderSection {...sectionsProps} unlock={unlock} cancel={cancel} isS4cUser={isS4cUser} isPartnerAdmin={isPartnerAdmin} />
        </SubContainer>

        <SubContainer>
          <CustomerSection {...sectionsProps} showCustomerLink={hasExtendedAccess} />
          <OptionallyVisible visible={shouldShowOrderDetailsSection}>
            <OrderSummarySection {...sectionsProps} />
          </OptionallyVisible>
        </SubContainer>
      </Container>

      <OptionallyVisible visible={shouldShowChargesListSection}>
        <Panel label={t(LABELS.SECTIONS.CHARGES_LIST.TITLE)}>
          <ChargesListSection
            orderPwcData={orderPwcData}
            shouldShowExchangeDetailsSection={shouldShowExchangeDetailsSection}
            shouldShowSettlementDetailsSection={shouldShowSettlementDetailsSection}
            shouldShowPayoutSection={shouldShowPayoutSection}
          />
        </Panel>
      </OptionallyVisible>
    </>
  );
};
