import { Country } from '../../../types/country';
import { LABELS } from './keys';

export const formatBoolean = (value: boolean) => (value ? LABELS.YES : LABELS.NO);

export const getCountry = (countries: Country[], countryCode?: string | null) => {
  if (!countryCode) {
    return null;
  }

  return countries.find((country) => country.isoAlpha2 === countryCode);
};

export const getState = (country: Country | null | undefined, stateCode?: string | null) => {
  if (!country || !stateCode) {
    return null;
  }

  return country.states?.find((state) => state.abbreviation === stateCode);
};
