import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Logger from 'utils/logger';

import { Button, ButtonProps } from './button';
import { KEYS, LABELS } from './keys';

interface CopyMenuItemProps extends Omit<ButtonProps, 'onClick'> {
  children: JSX.Element | string;
  value: string;
  onClick?: () => void;
}

export const CopyButton = ({ children, value, ...props }: CopyMenuItemProps) => {
  const { t } = useTranslation();
  const [isCopied, setCopied] = useState<boolean>(false);
  const copy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), KEYS.COPY_MESSAGE_TIMEOUT);
    } catch (err) {
      Logger.error('Failed to copy value: ', err);
    }
  };

  const content = isCopied ? t(LABELS.COPY_MESSAGE) : children;

  return (
    <Button {...props} disabled={isCopied} onClick={copy}>
      {content}
    </Button>
  );
};
