import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="" fill="#344054" />
</svg>;

const path = [
  'M3.16659 13.3346C2.79992 13.3346 2.48614 13.2042 2.22525 12.9433C1.96392',
  '12.682 1.83325 12.368 1.83325 12.0013V4.0013C1.83325 3.63464 1.96392 3.32086',
  '2.22525 3.05997C2.48614 2.79864 2.79992 2.66797 3.16659 2.66797H13.8333C14.1999',
  '2.66797 14.5139 2.79864 14.7753 3.05997C15.0361 3.32086 15.1666 3.63464 15.1666',
  '4.0013V8.0013H3.16659V12.0013H9.83325V13.3346H3.16659ZM3.16659',
  '5.33464H13.8333V4.0013H3.16659V5.33464ZM13.1666',
  '14.668V12.668H11.1666V11.3346H13.1666V9.33464H14.4999V11.3346H16.4999V12.668H14.4999V14.668H13.1666ZM3.16659 12.0013V4.0013V12.0013Z',
].join(' ');

export const IconCreditCardWithPlus = ({ width = 17, height = 16, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fill={color || 'currentColor'} />
  </Svg>
);

export default IconCreditCardWithPlus;
