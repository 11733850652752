import React, { MouseEvent, PropsWithChildren } from 'react';

import { Root, Text } from './styles';
import { ButtonVariant } from './types';

export interface ButtonProps extends PropsWithChildren {
  primary?: boolean;
  secondary?: boolean;
  flat?: boolean;
  danger?: boolean;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  className?: string;
  small?: boolean;
  forwardRef?: (ref: HTMLButtonElement) => void;
  type?: 'button' | 'submit' | 'reset';
}

const getButtonVariant = (args: Record<ButtonVariant, boolean | undefined>): ButtonVariant => {
  if (args.primary) {
    return ButtonVariant.Primary;
  }

  if (args.secondary) {
    return ButtonVariant.Secondary;
  }

  if (args.flat) {
    return ButtonVariant.Flat;
  }

  if (args.danger) {
    return ButtonVariant.Danger;
  }

  return ButtonVariant.Primary;
};

export const Button = ({
  primary,
  small,
  secondary,
  flat,
  danger,
  children,
  onClick,
  disabled,
  className,
  forwardRef,
  type = 'button',
}: ButtonProps) => {
  const variant = getButtonVariant({ primary, secondary, flat, danger });
  return (
    <Root ref={forwardRef} small={small} onClick={onClick} disabled={disabled} variant={variant} className={className} type={type}>
      <Text>{children}</Text>
    </Root>
  );
};
