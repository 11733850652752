import dayjs, { Dayjs } from 'dayjs';
import React, { FocusEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import IconCalendar from '../../icons/calendar';
import KEYS from './keys';
import { AdornedDateInput, Adornment } from './styles';

export interface DateInputProps {
  mask?: string;
  format?: string;
  value: Dayjs | null;
  inputRef: (ref: HTMLInputElement) => void;
  onChange: (value: Dayjs | null) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  toggleCalendar?: () => void;
}

export const DateInput = ({
  mask = KEYS.DEFAULT_MASK,
  format = KEYS.DEFAULT_FORMAT,
  onChange,
  value,
  disabled,
  inputRef,
  placeholder,
  toggleCalendar,
  onFocus,
  onBlur,
}: DateInputProps) => {
  const displayValue = value?.format(format);
  const [localValue, setLocalValue] = useState<string>(displayValue || '');
  const [isFocused, setFocused] = useState<boolean>(false);
  const handleInputChange = (value: string) => {
    const processedValue = value.substring(0, KEYS.DATE_STRING_LENGTH);
    setLocalValue(processedValue);
    const date = processedValue ? dayjs(processedValue) : null;
    const shouldUpdateValue = (date?.isValid() && processedValue.length === KEYS.DATE_STRING_LENGTH) || !date;

    if (shouldUpdateValue) {
      onChange(date);
    }
  };

  useEffect(() => {
    setLocalValue(displayValue || '');
  }, [value]);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    setLocalValue(value?.format(format) || '');
    onBlur?.(event);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    setFocused(true);
    onFocus?.(event);
  };

  return (
    <InputMask
      mask={mask}
      // @ts-ignore input mask has it's own type definition, but uses nested component's handler
      onChange={handleInputChange}
      disabled={disabled}
      value={localValue}
      onFocus={handleFocus}
      placeholder={isFocused ? format?.toLowerCase() : placeholder}
      onBlur={handleBlur}
      maskPlaceholder=""
    >
      <AdornedDateInput
        forwardRef={inputRef}
        startAdornment={
          <Adornment type="button" onClick={toggleCalendar}>
            <IconCalendar />
          </Adornment>
        }
      />
    </InputMask>
  );
};
