import { PageWrapper } from 'components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetMerchantsListQuery } from 'services/merchants';

import { DataTableRow } from 'components/dataTable';

import RoutePath from 'router/path';

import { usePersistentFilters } from 'utils/filters';
import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { usePersistentPagination } from 'utils/tables/pagination';
import { usePersistentSearch } from 'utils/tables/search';
import { getRequestSorting, useTableSorting } from 'utils/tables/sorting';

import { KEYS, LABELS } from './keys';
import { MerchantsList } from './merchantsList';
import { Filters, MerchantDataRow } from './types';
import { makeFilters, prepareFilters, transformAccountsListDataToTableFormat } from './utils';

export const MerchantsListContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  const { search, setSearch } = usePersistentSearch(KEYS.SEARCH_DEBOUNCE_TIMEOUT, KEYS.FILTER_KEY);
  const { filters, updateFilters, clearFilters } = usePersistentFilters<Filters>({}, KEYS.FILTER_KEY);
  const { sorting, onSort } = useTableSorting({
    defaultSorting: {
      direction: KEYS.DEFAULT_TABLE_SORTING.DIRECTION,
      field: KEYS.DEFAULT_TABLE_SORTING.FIELD,
    },
  });

  const { pagination, setPagination, changeRowsPerPage, changePage } = usePersistentPagination(
    getDefaultPagination(),
    [filters, sorting, search],
    KEYS.FILTER_KEY,
  );
  const { data, isFetching } = useGetMerchantsListQuery({
    pagination: undecoratePagination(pagination),
    sort: getRequestSorting(sorting),
    filters: prepareFilters(filters),
    search: search || undefined,
  });

  useEffect(() => {
    if (data?.pagination) {
      setPagination(decoratePagination(data?.pagination));
    }
  }, [data?.pagination]);

  const merchantAccounts: MerchantDataRow[] = transformAccountsListDataToTableFormat(data?.data);

  const filterSettings = makeFilters();

  const navigateToAccount = ({ uuid }: DataTableRow) => navigate(RoutePath.pwc.accountById(uuid));

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <MerchantsList
        pagination={pagination}
        merchantAccounts={merchantAccounts}
        isLoading={isFetching}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
        onSort={onSort}
        sorting={sorting}
        onSearch={setSearch}
        search={search}
        onRowClick={navigateToAccount}
        filterProps={{
          filterSettings,
          onFilterChange: updateFilters,
          onFiltersClear: clearFilters,
          filters,
        }}
      />
    </PageWrapper>
  );
};
