import React from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { useGetCountriesListQuery } from 'services/assets';
import { useGetMerchantQuery } from 'services/merchants';
import { MerchantKycStatuses } from 'services/merchants/types';

import { SelectItem } from 'components/inputs/select/select';
import LoadingIndicator from 'components/loadingIndicator/loadingIndicator';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { UserAccessLevel } from 'types/user';

import { KEYS } from './keys';
import { KYB } from './kyb';

//  todo: Ensure it doesn’t get added to the render tree at all unless the user is an admin or superadmin.
export const KybSectionContainer = () => {
  const matchId = useMatch(KEYS.ROUTE_MATCH_ID);
  const match = useMatch(KEYS.ROUTE_MATCH);
  const selectedMatch = matchId || match;
  const { account } = useSelector(selectAccount);
  const { uuid } = selectedMatch?.params ?? { uuid: account.uuid };
  const { data: merchantResponse, isLoading } = useGetMerchantQuery(uuid!, { skip: !uuid });
  const { data: countries = [] } = useGetCountriesListQuery();

  const mappedCountries: SelectItem[] = countries.map((country) => ({
    key: country.isoAlpha3,
    icon: country.iconUrl,
    label: country.name,
  }));
  const isKybEnabled = Boolean(window?.s4cConfig?.features?.isKybEnabled ?? false);

  const userLevel = useSelector(selectUserLevel);
  const isAdmin = Boolean(userLevel && userLevel >= UserAccessLevel.Admin);

  //  todo: Enable in envs other than dev and get rid of this check & update charts
  if (!isKybEnabled) {
    return null;
  }

  if (isLoading) {
    return <LoadingIndicator padded />;
  }

  // visible only when a new merchant is created until the KYB is approved by Bridge
  if (!isAdmin || !merchantResponse?.data || merchantResponse?.data.kyc.kycStatus !== MerchantKycStatuses.NotStarted) {
    return null;
  }

  return <KYB countries={mappedCountries} merchant={merchantResponse.data} />;
};
