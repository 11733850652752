import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import { InputRoot } from 'components/inputs/input/styles';

export const Section = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 8,

  width: 290,
  // @ts-ignore
  [InputRoot]: {
    width: 290,
  },
}));

export const PanelButton = styled(Button)((props) => ({
  width: 132,
}));

export const Label = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const Optional = styled(Label)((props) => ({
  fontStyle: 'italic',
  color: props.theme.colors.inactive,
}));

export const Row = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 40,
}));

export const Controls = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: -8,
  marginBottom: -8,
}));

export const FormContainer = styled.div(() => ({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));
