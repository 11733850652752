import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';

import { LABELS } from '../keys';
import { PanelButton } from '../styles';

interface GeneralAccountSettingFormProps {
  openModal: () => void;
}

export const KybPanel = ({ openModal }: GeneralAccountSettingFormProps) => {
  const { t } = useTranslation();

  const fields: FormFieldDescription[] = [
    {
      key: t(LABELS.PANEL_FIELDS.INFORMATION),
      label: t(LABELS.PANEL_FIELDS.INFORMATION),
      valueLabelWidth: 446,
      renderInput: () => t(LABELS.PANEL_VALUES.INFORMATION),
    },
    {
      key: t(LABELS.PANEL_FIELDS.BUTTON),
      label: t(LABELS.PANEL_FIELDS.BUTTON),
      renderInput: () => <PanelButton onClick={openModal}>{t(LABELS.PANEL_VALUES.BUTTON)}</PanelButton>,
    },
  ];

  return <FormLayout fields={fields} />;
};
