import { OptionallyVisible } from 'components';
import { FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { ErrorMessage } from 'components/form/formField/styles';
import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { IconAdd } from 'components/icons/add';
import IconRemove from 'components/icons/dash';
import { Input, InputType } from 'components/inputs';

import { OrderItemType } from 'types/pwcOrder';

import { FORM_FIELDS, FormFieldType, INITIAL_NAMES_FOR_TAX_ITEMS, ITEM_FORM_FIELDS, ITEM_FORM_INITIAL_VALUES, LABELS } from '../keys';
import { ImageUploadOutcome, Item } from '../types';
import OrderSummaryItem from './item';
import { FieldsWrapper, FormWrapper, LightText, PaddingWrapper, RemoveButton, Separator } from './styles';

interface OrderSummaryProps {
  readOnlyMode: boolean;
  errors?: FormikErrors<Record<string, string>>;
  uploadErrors: ImageUploadOutcome[];
  setFieldValue: (field: FormFieldType, value: Item[]) => void;
}

const OrderSummary = ({ readOnlyMode, errors, setFieldValue, uploadErrors }: OrderSummaryProps) => {
  const { t } = useTranslation();

  const getInitialItem = (id: string) => {
    return {
      id,
      name: ITEM_FORM_INITIAL_VALUES[ITEM_FORM_FIELDS.NAME],
      fiatAmount: ITEM_FORM_INITIAL_VALUES[ITEM_FORM_FIELDS.FIAT_AMOUNT],
      imageUrl: ITEM_FORM_INITIAL_VALUES[ITEM_FORM_FIELDS.IMAGE_URL],
      type: OrderItemType.Product,
    };
  };

  const getInitialTaxItem = (id: string) => ({
    id,
    name: ITEM_FORM_INITIAL_VALUES[ITEM_FORM_FIELDS.NAME],
    fiatAmount: ITEM_FORM_INITIAL_VALUES[ITEM_FORM_FIELDS.FIAT_AMOUNT],
    imageUrl: ITEM_FORM_INITIAL_VALUES[ITEM_FORM_FIELDS.IMAGE_URL],
    type: OrderItemType.Custom,
  });

  const getInitialItems = () => {
    const id = Date.now().toString();
    return {
      [id]: getInitialItem(id),
    };
  };
  const [items, setItems] = useState<{ [id: string]: Item }>(getInitialItems());

  const getInitialTaxItems = () =>
    [OrderItemType.Tax, OrderItemType.Delivery, OrderItemType.Tip].reduce((acc, type) => {
      const id = type;
      return {
        ...acc,
        [id]: {
          id,
          name: INITIAL_NAMES_FOR_TAX_ITEMS[type],
          fiatAmount: ITEM_FORM_INITIAL_VALUES[ITEM_FORM_FIELDS.FIAT_AMOUNT],
          type,
        },
      };
    }, {});

  const [taxItems, setTaxItems] = useState<{ [id: string]: Item }>(getInitialTaxItems());

  const onAddItem = () => {
    const id = Date.now().toString();
    setItems({
      ...items,
      [id]: getInitialItem(id),
    });
  };

  const onRemoveItem = (id: string) => {
    const updatedItems = Object.fromEntries(Object.entries(items).filter(([key]) => key !== id));

    setItems(updatedItems);
  };

  const onRemoveTaxItem = (id: string) => {
    const updatedItems = Object.fromEntries(Object.entries(taxItems).filter(([key]) => key !== id));

    setTaxItems(updatedItems);
  };

  const onAddTaxItem = () => {
    const id = Date.now().toString();
    setTaxItems({
      ...taxItems,
      [id]: getInitialTaxItem(id),
    });
  };

  const onItemChange = (id: string, type: OrderItemType) => (item: Omit<Item, 'type'>) => {
    const updatedItems = { ...items };
    updatedItems[id] = {
      ...updatedItems[id],
      ...item,
      type,
    };

    setItems(updatedItems);
  };

  const onTaxItemChange = (id: string, field: string) => (value: string) => {
    const updatedItems = { ...taxItems };
    updatedItems[id] = {
      ...updatedItems[id],
      [field]: value,
    };

    setTaxItems(updatedItems);
  };

  useEffect(() => {
    setFieldValue(FORM_FIELDS.ITEMS, Object.values(items).concat(Object.values(taxItems)));
  }, [items, taxItems]);

  const getItemError = (index: number) => ({
    [ITEM_FORM_FIELDS.NAME]: errors?.[`${FORM_FIELDS.ITEMS}[${index}].${ITEM_FORM_FIELDS.NAME}`],
    [ITEM_FORM_FIELDS.FIAT_AMOUNT]: errors?.[`${FORM_FIELDS.ITEMS}[${index}].${ITEM_FORM_FIELDS.FIAT_AMOUNT}`],
    [ITEM_FORM_FIELDS.IMAGE_URL]: errors?.[`${FORM_FIELDS.ITEMS}[${index}].${ITEM_FORM_FIELDS.IMAGE_URL}`],
  });

  const getImageError = (index: number) => {
    const uploadError = uploadErrors.find((upload) => upload.error && upload.id === Object.keys(items)[index]);
    const itemError = getItemError(index)?.[ITEM_FORM_FIELDS.IMAGE_URL];
    const backendValidationError = itemError ? ({ error: itemError } as ImageUploadOutcome) : undefined;

    return uploadError || backendValidationError;
  };

  const getInputForTypeForItem = (item: Item, index: number, readonly?: boolean) => {
    const fieldError = errors?.[`${FORM_FIELDS.ITEMS}[${index + Object.keys(items).length}].${ITEM_FORM_FIELDS.FIAT_AMOUNT}`];
    return (
      <>
        <Input
          fullWidth
          type={InputType.Number}
          value={item.fiatAmount?.toString()}
          onChange={onTaxItemChange(item.id, ITEM_FORM_FIELDS.FIAT_AMOUNT)}
          disabled={readonly}
          placeholder={t(LABELS.ITEM_FORM_FIELDS.PLACEHOLDERS_FOR_TYPE[item.type]!)}
        />
        <OptionallyVisible visible={Boolean(fieldError)}>
          <ErrorMessage>{fieldError}</ErrorMessage>
        </OptionallyVisible>
      </>
    );
  };

  const mapErrorMessagesToFormFieldIds = () => {
    const combinedItems = { ...items, ...taxItems };
    return Object.entries(errors ?? {}).reduce((acc, [key, error]) => {
      const extractedIndex = key.match(/\d+/)?.[0];
      if (!extractedIndex) {
        return acc;
      }
      const failingFieldId = Object.values(combinedItems)[extractedIndex as any]?.id;

      return {
        ...acc,
        [failingFieldId]: error,
      };
    }, {});
  };

  const mapTaxItemsToFormFields = (taxItems: { [id: string]: Item }): FormFieldDescription[] =>
    Object.values(taxItems).map((item, index) => {
      const fieldError = errors?.[`${FORM_FIELDS.ITEMS}[${index + Object.keys(items).length}].${ITEM_FORM_FIELDS.NAME}`];

      return {
        key: item.id,
        label: t(LABELS.ITEM_FORM_FIELDS.LABELS_FOR_TYPE[item.type]!),
        renderInput: ({ readonly }) => (
          <FieldsWrapper>
            <OptionallyVisible visible={item.type === OrderItemType.Custom}>
              <Input
                fullWidth
                type={InputType.Text}
                value={item.name}
                onChange={onTaxItemChange(item.id, ITEM_FORM_FIELDS.NAME)}
                disabled={readonly}
                placeholder={t(LABELS.ITEM_FORM_FIELDS.CUSTOM_NAME_PLACEHOLDER)}
              />
              <OptionallyVisible visible={Boolean(fieldError)}>
                <ErrorMessage>{fieldError}</ErrorMessage>
              </OptionallyVisible>
            </OptionallyVisible>
            {getInputForTypeForItem(item, index, readonly)}
          </FieldsWrapper>
        ),
        formRowItem: (
          <OptionallyVisible visible={item.type === OrderItemType.Custom}>
            <RemoveButton flat onClick={() => onRemoveTaxItem(item.id)}>
              <IconRemove /> {t(LABELS.ACTIONS.REMOVE_CUSTOM)}
            </RemoveButton>
          </OptionallyVisible>
        ),
      };
    });

  return (
    <Panel label={t(LABELS.ORDER_SUMMARY_PANEL_TITLE)}>
      <PaddingWrapper>
        <LightText>{t(LABELS.ITEM_DESCRIPTION)}</LightText>
        {Object.entries(items).map(([id, item], index) => (
          <OrderSummaryItem
            key={id}
            item={item}
            onItemChange={onItemChange(id, OrderItemType.Product)}
            readOnlyMode={readOnlyMode}
            error={getItemError(index)}
            uploadError={getImageError(index)}
            onRemoveItem={() => onRemoveItem(id)}
            deleteable={index > 0}
          />
        ))}
        <Button flat onClick={onAddItem}>
          <IconAdd /> {t(LABELS.ACTIONS.ADD_PRODUCT)}
        </Button>
        <Separator />
        <LightText>{t(LABELS.TAX_DESCRIPTION)}</LightText>
        <FormWrapper>
          <FormLayout
            fields={mapTaxItemsToFormFields(taxItems)}
            readonly={readOnlyMode}
            error={mapErrorMessagesToFormFieldIds() as Record<string, string>}
          />
        </FormWrapper>
        <Button flat onClick={onAddTaxItem}>
          <IconAdd /> {t(LABELS.ACTIONS.ADD_CUSTOM)}
        </Button>
      </PaddingWrapper>
    </Panel>
  );
};

export default OrderSummary;
