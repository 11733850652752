import { DateCalendar } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../buttons';
import { DateInput } from './input';
import KEYS, { LABELS } from './keys';
import { Buttons, CalendarContainer, PopperRoot } from './styles';

interface DatePickerProps {
  format?: string;
  mask?: string;
  value: Dayjs | null;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  onChange: (value: Dayjs | null) => void;
  mobile?: boolean;
  disabled?: boolean;
  inputLabel?: string;
}

export const DatePicker = ({
  format = KEYS.DEFAULT_FORMAT,
  mask = KEYS.DEFAULT_MASK,
  value,
  onChange,
  mobile,
  minDate,
  maxDate,
  disabled,
  inputLabel: customInputLabel,
}: DatePickerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

  const handleInputRef = (ref: HTMLInputElement | HTMLTextAreaElement) => {
    inputRef.current = ref;
  };

  const toggleCalendar = () => {
    if (disabled) {
      return;
    }

    setOpen(!open);
  };
  const handleCalendarChange = (value: Dayjs | null) => {
    if (disabled) {
      return;
    }

    onChange(value);
    toggleCalendar();
  };

  return (
    <>
      <DateInput
        mask={mask}
        placeholder={customInputLabel}
        value={value}
        inputRef={handleInputRef}
        onChange={onChange}
        format={format}
        toggleCalendar={toggleCalendar}
        disabled={disabled}
      />
      <PopperRoot open={open} anchorEl={inputRef.current} placement="bottom-start">
        <CalendarContainer>
          <DateCalendar<Dayjs> value={value} onChange={handleCalendarChange} minDate={minDate} maxDate={maxDate} disabled={disabled} />
          <Buttons>
            <Button flat onClick={toggleCalendar}>
              {t(LABELS.CLOSE_MODAL)}
            </Button>
          </Buttons>
        </CalendarContainer>
      </PopperRoot>
    </>
  );
};

export default DatePicker;
