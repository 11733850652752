import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { OptionallyVisible } from 'components/optionallyVisible';

import { formatDateLocal } from 'utils/date';
import { capitalizeFirstLetter } from 'utils/string';

import { KEYS, LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import { AmountBlock, AmountNumericValue, AmountValue, Item, ItemName, ItemValue, SectionWrapper } from '../styles';
import { formatCryptoCode, formatNumber } from '../utils';

export const ExchangeDetailsSection = ({ orderData }: OrderPwcSectionProps) => {
  const { t } = useTranslation();

  const { cryptoExchange, cryptoAsset } = orderData;

  const formatExchangeStatus = (str: string): string => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Panel label={t(LABELS.SECTIONS.EXCHANGE_DETAILS.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.LIQUIDITY_PROVIDER)}</ItemName>
          <ItemValue>
            {cryptoExchange?.liquidityProvider ? capitalizeFirstLetter(cryptoExchange?.liquidityProvider) : t(KEYS.DASH)}
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.CRYPTO_PRICE)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountNumericValue>{KEYS.BASE_CURRENCY_UNIT}</AmountNumericValue>
              <AmountValue>{formatCryptoCode(cryptoAsset.cryptoCurrencyCode)}</AmountValue>
              <AmountValue>{KEYS.APPROX_EQUAL_SYMBOL}</AmountValue>
              <AmountNumericValue>{formatNumber(cryptoExchange?.cryptoPrice)}</AmountNumericValue>
              <AmountValue>{orderData.general.fiatCurrencyCode}</AmountValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.DEPOSIT_ADDRESS)}</ItemName>
          <ItemValue>{cryptoExchange?.toWalletAddress}</ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(cryptoExchange?.toWalletMemo)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.DEPOSIT_MEMO)}</ItemName>
            <ItemValue>{cryptoExchange?.toWalletMemo}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(cryptoExchange?.depositReceivedAt)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.DEPOSIT_RECEIVED_TIME)}</ItemName>
            <ItemValue>{formatDateLocal(cryptoExchange!.depositReceivedAt!)}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(cryptoExchange?.exchangeStatus)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.EXCHANGE_STATUS)}</ItemName>
            <ItemValue>{formatExchangeStatus(cryptoExchange?.exchangeStatus as string)}</ItemValue>
          </Item>
        </OptionallyVisible>
      </SectionWrapper>
    </Panel>
  );
};
