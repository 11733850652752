import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectItem } from 'components/inputs/select/select';

import { FORM_FIELDS, LABELS } from '../../keys';
import { Section } from '../../styles';
import Address from '../address/address';
import FormikInput from '../formikTextInput/formikTextInput';
import BusinessIndustry from './components/businessIndustry/businessIndustry';

interface StepFormProps {
  error?: Record<string, string>;
  countries: SelectItem[];
}

export default ({ error, countries }: StepFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Section>
        <FormikInput
          label={t(LABELS.SECTIONS.MERCHANT_NAME.TITLE)}
          fieldPath={FORM_FIELDS.MERCHANT_NAME}
          placeholder={t(LABELS.SECTIONS.MERCHANT_NAME.PLACEHOLDER)}
          error={error}
        />
      </Section>

      <Section>
        <FormikInput
          label={t(LABELS.SECTIONS.MERCHANT_DESCRIPTION.TITLE)}
          fieldPath={FORM_FIELDS.MERCHANT_DESCRIPTION}
          placeholder={t(LABELS.SECTIONS.MERCHANT_DESCRIPTION.PLACEHOLDER)}
          error={error}
        />
      </Section>

      <Section>
        <BusinessIndustry fieldPath={FORM_FIELDS.BUSINESS_INDUSTRY} error={error} />
      </Section>

      <Section>
        <FormikInput
          label={t(LABELS.SECTIONS.MERCHANT_EMAIL.TITLE)}
          fieldPath={FORM_FIELDS.MERCHANT_EMAIL}
          placeholder={t(LABELS.SECTIONS.MERCHANT_EMAIL.PLACEHOLDER)}
          error={error}
        />
      </Section>

      <Section>
        <FormikInput
          label={t(LABELS.SECTIONS.BUSINESS_TYPE.TITLE)}
          fieldPath={FORM_FIELDS.BUSINESS_TYPE}
          placeholder={t(LABELS.SECTIONS.BUSINESS_TYPE.PLACEHOLDER)}
          error={error}
        />
      </Section>

      <Section>
        <FormikInput
          label={t(LABELS.SECTIONS.WEBSITE.TITLE)}
          fieldPath={FORM_FIELDS.WEBSITE}
          placeholder={t(LABELS.SECTIONS.WEBSITE.PLACEHOLDER)}
          error={error}
        />
      </Section>

      <Section>
        <Address
          title={LABELS.SECTIONS.ADDRESS.REGISTERED_ADDRESS_TITLE}
          fieldPath={FORM_FIELDS.REGISTERED_ADDRESS}
          countries={countries}
          error={error}
        />
      </Section>

      <Section>
        <Address
          title={LABELS.SECTIONS.ADDRESS.PHYSICAL_ADDRESS_TITLE}
          fieldPath={FORM_FIELDS.PHYSICAL_ADDRESS}
          countries={countries}
          error={error}
        />
      </Section>
    </>
  );
};
