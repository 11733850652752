import { OptionallyVisible } from 'components';
import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'components/inputs/checkbox/checkbox';

import { FormType, LABELS } from '../../keys';
import { Container, Label, Optional, Text, Wrapper } from './styles';

interface Props {
  //  path to the field in the formik values
  fieldPath: string;
  label?: string;
  text: string;
  isOptional?: boolean;
}

const FormikCheckbox = ({ fieldPath, label, text, isOptional = false }: Props) => {
  const { values, setFieldValue } = useFormikContext<FormType>();
  const { t } = useTranslation();

  const inputValue = getIn(values, fieldPath);

  const handleChange = (value: boolean) => {
    setFieldValue(fieldPath, value);
  };

  return (
    <Wrapper>
      <OptionallyVisible visible={Boolean(label)}>
        <Label>
          {label}
          <OptionallyVisible visible={isOptional}>
            <Optional> {t(LABELS.SECTIONS.FIELD_OPTIONAL)}</Optional>
          </OptionallyVisible>
        </Label>
      </OptionallyVisible>
      <Container>
        <Checkbox value={inputValue} onChange={handleChange} />
        <Text>{text}</Text>
      </Container>
    </Wrapper>
  );
};

export default FormikCheckbox;
