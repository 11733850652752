import { t } from 'i18next';

import { ColumnDecoration, DataTableColumn } from 'components/dataTable';

import { SortOrder } from 'types/sort';

export const LABELS = {
  BREADCRUMBS: [['page.partnerAccounts.breadcrumbs', 'Partner Accounts']],
  SEARCH_PLACEHOLDER: ['page.partnerAccounts.list.search.placeholder', 'Search by Account Name or Account ID'],
  PAGE_TITLE: ['page.partnerAccounts.list.title', 'Partner Accounts'],
  TABLE_COLUMNS: {
    NAME: ['page.partnerAccounts.list.table.columns.name', 'Account Name'],
    ID: ['page.partnerAccounts.list.table.columns.accountId', 'Account ID'],
    CREATED_AT: ['page.partnerAccounts.list.table.columns.createdAt', 'Created at'],
    IS_CERTIFIED: ['page.partnerAccounts.list.table.columns.isCertified', 'Certified'],
    ACTIONS: ['page.partnerAccounts.list.table.columns.actions', ''],
  },
  ACTIONS: {
    TITLE: ['page.partnerAccounts.list.actions.title', 'Actions'],
    OPEN_DETAILS: ['page.partnerAccounts.list.actions.openDetails', 'Open Account Details'],
    COPY_ACCOUNT_ID: ['page.partnerAccounts.list.actions.copyAccountId', 'Copy Account ID'],
  },
  FILTERS: {
    IS_CERTIFIED: {
      LABEL: ['page.account.list.filter.isCertified.label', 'Certified'],
      YES: ['page.account.list.filter.isCertified.yes', 'Yes'],
      NO: ['page.account.list.filter.isCertified.no', 'No'],
    },
    DATE: {
      LABEL: ['page.account.list.filter.date.label', 'Creation Date'],
    },
  },
};

export const COLUMN_IDS = {
  NAME: 'name',
  ID: 'uuid',
  CREATED_AT: 'createdAt',
  IS_CERTIFIED: 'isCertified',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.NAME, label: t(LABELS.TABLE_COLUMNS.NAME), decoration: ColumnDecoration.bold, sortable: true },
  { id: COLUMN_IDS.ID, label: t(LABELS.TABLE_COLUMNS.ID), sortable: true },
  { id: COLUMN_IDS.CREATED_AT, label: t(LABELS.TABLE_COLUMNS.CREATED_AT), sortable: true },
  { id: COLUMN_IDS.IS_CERTIFIED, label: t(LABELS.TABLE_COLUMNS.IS_CERTIFIED), headCellProps: { align: 'center' }, sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const KEYS = {
  FILTER_KEY: 'accounts',
  SEARCH_DEBOUNCE_TIMEOUT: 1000,
  DEFAULT_TABLE_SORTING: {
    FIELD: COLUMN_IDS.CREATED_AT,
    DIRECTION: SortOrder.desc,
  },
};
