import { useEffect, useState } from 'react';
import { useCreateAccountMutation } from 'services/accounts';
import { AvailableAccountsQuery } from 'services/accounts/types';
import { useLazyGetAvailableAccountsQuery } from 'services/users';
import { CreateUserPayload } from 'services/users/types';

import { AccountType } from 'types/account';
import { RequestPagination, ServerPagination } from 'types/pagination';
import { UserRoleType } from 'types/user';

import { getServerSideValidationDetails } from 'utils/error';

import { getDefaultPagination, undecoratePagination } from '../../../utils/pagination';
import { SelectItem } from '../../inputs/select/select';
import { KEYS } from './keys';
import { CreatedAccount } from './types';

export const useCreateAccountModal = () => {
  const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<AccountType>(AccountType.Ramp);
  const [isWelcomeEmailEnabled, setIsWelcomeEmailEnabled] = useState<boolean>(true);
  const [createdAccount, setCreatedAccount] = useState<CreatedAccount | null>(null);
  const [createAccount, { isLoading: isSubmittingAccount, error: createAccountError, reset }] = useCreateAccountMutation();
  const [fetchGroupAccounts] = useLazyGetAvailableAccountsQuery();
  const handleCreateAccountModalOpen = () => {
    setCreatedAccount({
      username: '',
      firstName: '',
      lastName: '',
      accountName: '',
      shift4MerchantId: '',
      groupAccountId: '',
    });
    setCreateAccountModalOpen(true);
  };

  useEffect(() => {
    if (isCreateAccountModalOpen) {
      // preload group accounts data
      fetchGroupAccounts(
        {
          pagination: undecoratePagination(getDefaultPagination()),
          filters: { type: AccountType.Pwc_Group },
        },
        true,
      );
    }
  }, [isCreateAccountModalOpen]);

  const handleCreateAccountModalClose = () => {
    setCreatedAccount(null);
    setCreateAccountModalOpen(false);
  };

  const getGroupAccounts = async (params: {
    search?: string;
    pagination?: RequestPagination;
  }): Promise<{ data: SelectItem[]; pagination?: ServerPagination }> => {
    const query: AvailableAccountsQuery = {
      pagination: params.pagination,
      filters: { type: AccountType.Pwc_Group },
    };

    if (params.search) {
      query.search = params.search;
    }

    const response = await fetchGroupAccounts(query, true);

    if ('data' in response) {
      const records: SelectItem[] = response.data!.data.map((item) => ({
        key: item.account.uuid,
        label: item.account.name,
      }));
      return {
        data: records,
        pagination: response.data!.pagination,
      };
    }

    return { data: [] };
  };

  const handleCreateAccount = async (formValues: CreatedAccount) => {
    const { shift4MerchantId, groupAccountId, ...commonProps } = formValues;

    const accountToCreate: CreateUserPayload = {
      ...commonProps,
      roles: [UserRoleType.Partner],
    };

    if (accountType === AccountType.Pwc) {
      accountToCreate.accountType = AccountType.Pwc;
      if (shift4MerchantId) {
        accountToCreate.shift4MerchantId = shift4MerchantId;
      }
      if (groupAccountId) {
        accountToCreate.groupAccountId = groupAccountId;
      }
      if (!isWelcomeEmailEnabled) {
        accountToCreate.sendWelcomeEmail = false;
      }
    } else if (accountType === AccountType.Pwc_Group) {
      accountToCreate.accountType = AccountType.Pwc_Group;
    }

    const result = await createAccount(accountToCreate);

    return 'data' in result;
  };

  const createAccountErrorMessage = getServerSideValidationDetails(createAccountError, KEYS.FORM_FIELDS.EMAIL);

  return {
    handleCreateAccountModalOpen,
    createdAccount,
    modalProps: {
      isSubmitting: isSubmittingAccount,
      reset,
      open: isCreateAccountModalOpen,
      onClose: handleCreateAccountModalClose,
      onCreateAccount: handleCreateAccount,
      account: createdAccount!,
      error: createAccountErrorMessage,
      setAccountType,
      accountType,
      setIsWelcomeEmailEnabled,
      isWelcomeEmailEnabled,
      getGroupAccounts,
    },
  };
};
