import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import RoutePath from 'router/path';

import { CryptoPayment } from 'types/pwcOrder';

import { formatDateLocal } from 'utils/date';
import { capitalizeFirstLetter } from 'utils/string';

import { KEYS, LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import { Item, ItemName, ItemValue, Link, OnChainTransactionHash, SectionWrapper } from '../styles';

export const PaymentSection = ({ orderData }: OrderPwcSectionProps) => {
  const { t } = useTranslation();

  const cryptoPayment = orderData?.cryptoPayment as CryptoPayment;

  return (
    <Panel label={t(LABELS.SECTIONS.PAYMENT.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.CUSTOMER_ID)}</ItemName>
          <ItemValue>
            <Link to={RoutePath.pwc.customerById(orderData.customer.uuid || KEYS.DASH)}>{orderData.customer.uuid}</Link>
          </ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.customer.externalCustomerId)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.EXTERNAL_CUSTOMER_ID)}</ItemName>
            <ItemValue>{orderData.customer.externalCustomerId}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_PROVIDER)}</ItemName>
          <ItemValue>{cryptoPayment.paymentProvider ? capitalizeFirstLetter(cryptoPayment.paymentProvider) : t(KEYS.DASH)}</ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(cryptoPayment.paymentProviderConnectionName)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_CONNECTION_NAME)}</ItemName>
            <ItemValue>{cryptoPayment.paymentProviderConnectionName}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(cryptoPayment.paymentProviderConnectionType)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_CONNECTION_TYPE)}</ItemName>
            <ItemValue>{cryptoPayment.paymentProviderConnectionType}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(cryptoPayment.paymentProviderStatusUpdatedAt)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_SUBMITTED_AT)}</ItemName>
            <ItemValue>{formatDateLocal(cryptoPayment.paymentProviderStatusUpdatedAt)}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(cryptoPayment.paymentProviderStatus)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_STATUS)}</ItemName>
            <ItemValue>
              {cryptoPayment.paymentProviderStatus ? capitalizeFirstLetter(cryptoPayment.paymentProviderStatus) : t(KEYS.DASH)}
            </ItemValue>
          </Item>
        </OptionallyVisible>

        {/* TODO: Remove the comment when Bridge provides data */}
        {/* <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.DEPOSIT_ADDRESS)}</ItemName>
          <ItemValue>{cryptoPayment.fromWalletAddress}</ItemValue>
        </Item> */}

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.NETWORK)}</ItemName>
          <ItemValue>{cryptoPayment.network ? capitalizeFirstLetter(cryptoPayment.network) : t(KEYS.DASH)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.TRANSACTION_HASH)}</ItemName>
          <OptionallyVisible visible={Boolean(cryptoPayment.onChain?.explorerUrl)}>
            <OnChainTransactionHash displayFullHash>
              <a href={cryptoPayment.onChain?.explorerUrl} target="_blank">
                {cryptoPayment.onChain?.transactionHash}
              </a>
            </OnChainTransactionHash>
          </OptionallyVisible>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
