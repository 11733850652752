import { FieldArray } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectItem } from 'components/inputs/select/select';

import { FORM_FIELDS, LABELS } from '../../keys';
import Checkbox from '../checkbox/checkbox';
import Documents from '../documents/documents';
import IdentifyingInformation from '../identifyingInformation/identifyingInformation';
import { Container } from './styles';

interface StepFormProps {
  error?: Record<string, string>;
  countries: SelectItem[];
}

export default ({ error, countries }: StepFormProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Checkbox
        text={t(LABELS.SECTIONS.HAS_MATERIAL_INTERMEDIARY_OWNERSHIP.TEXT)}
        label={t(LABELS.SECTIONS.HAS_MATERIAL_INTERMEDIARY_OWNERSHIP.LABEL)}
        fieldPath={FORM_FIELDS.HAS_MATERIAL_INTERMEDIARY_OWNERSHIP}
      />
      <FieldArray
        render={({ insert, remove }) => (
          <IdentifyingInformation
            error={error}
            insert={insert}
            remove={remove}
            countries={countries}
            fieldPath={FORM_FIELDS.IDENTIFYING_INFORMATION}
          />
        )}
        name={FORM_FIELDS.IDENTIFYING_INFORMATION}
      />
      <FieldArray
        render={({ insert, remove }) => <Documents error={error} insert={insert} remove={remove} fieldPath={FORM_FIELDS.DOCUMENTS} />}
        name={FORM_FIELDS.DOCUMENTS}
      />
    </Container>
  );
};
