import { createApi } from '@reduxjs/toolkit/query/react';
import API_CONFIG from 'constants/api';
import { CustomHeaderType } from 'services/api/types';
import { Endpoint } from 'services/endpoints';
import {
  PwcOrderCreationResponse,
  PwcOrderResponse,
  PwcOrdersCSVResponse,
  PwcOrdersListQuery,
  PwcOrdersListResponse,
} from 'services/pwcOrders/types';

import { HTTPMethod, Response } from 'types/http';
import { CreateOrderParams } from 'types/pwcOrder';

import { getDefaultPagination } from 'utils/pagination';

import { auth0TokenStorage, fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';

const REDUCER_PATH = 'pwcOrdersApi';

export const pwcOrdersApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.ORDERS],
  endpoints: (builder) => ({
    getPwcOrdersList: builder.query<PwcOrdersListResponse, PwcOrdersListQuery>({
      query: (requestBody) => ({
        url: Endpoint.PwcOrders.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      providesTags: [TAGS.ORDERS],
      transformResponse: (response: Response<PwcOrdersListResponse>) => {
        if (!response?.data) {
          return { orders: [], pagination: getDefaultPagination() };
        }

        return response.data;
      },
    }),
    getPwcOrder: builder.query<PwcOrderResponse, string>({
      query: (uuid) => ({
        url: Endpoint.PwcOrders.OrderById(uuid),
        method: HTTPMethod.GET,
      }),
      providesTags: [TAGS.ORDER],
    }),
    getPwcOrdersCSV: builder.query<PwcOrdersCSVResponse, PwcOrdersListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Orders.CSV,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      forceRefetch: () => true,
    }),
    createOrder: builder.mutation<PwcOrderCreationResponse | undefined, CreateOrderParams>({
      query: (payload) => ({
        url: Endpoint.PwcOrders.Root,
        method: HTTPMethod.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.ORDERS],
      transformResponse: (response: Response<PwcOrderCreationResponse>) => {
        return response?.data;
      },
    }),
    unlock: builder.mutation<Response<{ success: boolean }>, { uuid: string }>({
      query: ({ uuid }) => ({
        url: Endpoint.PwcOrders.UnlockByUuid(uuid),
        method: HTTPMethod.POST,
        body: {},
      }),
      invalidatesTags: [TAGS.ORDERS, TAGS.ORDER],
    }),
    cancel: builder.mutation<Response<{ success: boolean }>, { uuid: string }>({
      query: ({ uuid }) => ({
        url: Endpoint.PwcOrders.CancelByUuid(uuid),
        method: HTTPMethod.POST,
        body: {},
      }),
      invalidatesTags: [TAGS.ORDERS, TAGS.ORDER],
    }),
  }),
});

export const getCSVRecords = async (input: object) => {
  const URL = [API_CONFIG.ROOT_URL, Endpoint.PwcOrders.CSV].join('');
  const headers = new Headers();
  headers.set('Content-type', 'application/json');
  const accessToken = await auth0TokenStorage.getAccessToken()();
  if (accessToken) {
    headers.set(CustomHeaderType.Auth, `Bearer ${accessToken}`);
  }
  const response = await fetch(URL, {
    method: HTTPMethod.POST,
    headers,
    body: JSON.stringify(input),
  });
  const blob = await response.blob();
  return blob;
};

export const { getPwcOrdersList, getPwcOrder } = pwcOrdersApi.endpoints;
export const { useGetPwcOrdersListQuery, useGetPwcOrderQuery } = pwcOrdersApi;
export default pwcOrdersApi;
