import styled from '@emotion/styled';

export const Label = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  marginBottom: 8,
}));

export const Wrapper = styled.div((props) => ({
  marginBottom: 20,
}));

export const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 10,
}));

export const Text = styled.div((props) => ({
  maxWidth: 275,
  color: props.theme.colors.inactive,
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const Optional = styled.span((props) => ({
  fontStyle: 'italic',
  color: props.theme.colors.inactive,
}));
