import styled from '@emotion/styled';

import { Button } from '../../buttons';

export const ButtonLabel = styled.span(() => ({
  textTransform: 'uppercase',
}));

export const FiltersContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  minWidth: 360,
  maxWidth: 420,
  padding: '5px 10px 20px 10px',
}));

export const FiltersWrapper = styled.div(() => ({
  minHeight: 150,
}));

export const Filters = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  lineHeight: '20px',
  gap: 10,
}));

export const EmptyFiltersMessage = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));

export const Title = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 400,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));

export const PanelTitle = styled.span((props) => ({
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 500,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));

export const CloseButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 'unset',
}));

export const Buttons = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: 20,
}));

export const Badge = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 12px 4px 4px',
  borderRadius: 100,
  gap: 8,
  backgroundColor: props.theme.colors.highlight,
  border: '0.5px solid rgba(98, 118, 160, 0.30)',
  marginTop: 10,
}));

export const Close = styled(Button)((props) => ({
  display: 'flex',
  minWidth: 'unset',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  width: 16,
  height: 16,
}));

export const Search = styled.div((props) => ({
  display: 'flex',
}));

export const Label = styled.div((props) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: 0,
  lineHeight: '15px',
  gap: 4,
}));

export const Name = styled.span((props) => ({
  fontSize: 12,
  lineHeight: '15px',
  color: props.theme.colors.inactive,
}));

export const Value = styled.span((props) => ({
  fontSize: 12,
  lineHeight: '15px',
  color: props.theme.colors.primary,
}));
