import { OptionallyVisible } from 'components';
import React, { MouseEvent } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { MerchantInfo, SimulateDepositResponse } from 'services/merchants/types';

import { Panel } from 'components/containers';
import { DataTableRow } from 'components/dataTable';
import DataTable from 'components/dataTable/dataTable';
import IconRefundAlt from 'components/icons/refundAlt';
import IconUpdate24 from 'components/icons/update24';
import LoadingIndicator from 'components/loadingIndicator';

import { TablePagination } from 'types/pagination';
import { TableSorting } from 'types/sort';

import { formatTableDate } from 'utils/date';

import { PayoutCell } from './components/cells';
import { COLUMNS, KEYS, LABELS } from './keys';
import { DataBar, DataTableContainer, Section, SectionContent, SectionTitle, StyledButton } from './styles';
import { PayoutDataRow } from './types';
import { formatNumber } from './utils';

interface AccountsSectionComponentProps extends WithTranslation {
  payouts: PayoutDataRow[];
  isLoading: boolean;
  pagination: TablePagination;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (itemsPerPage: number) => void;
  onSort: (event: MouseEvent, property: string) => void;
  sorting: TableSorting;
  onRowClick: (row: DataTableRow) => void;
  merchantData?: MerchantInfo['payout'];
  payout: Payout;
}

interface Payout {
  simulatePayout: () => Promise<SimulateDepositResponse>;
  isPayoutInProgress: boolean;
}

const PayoutsSectionComponent = ({
  payouts,
  isLoading,
  pagination,
  onChangePage,
  onChangeRowsPerPage,
  onSort,
  sorting,
  onRowClick,
  merchantData,
  payout,
}: AccountsSectionComponentProps) => {
  const { t } = useTranslation();

  const hasPayouts = Boolean(payouts.length);
  const hasMerchantData = Boolean(merchantData);
  const { isPayoutInProgress, simulatePayout } = payout;
  const isPayoutAvailable = Boolean(Number(merchantData?.balance)) && (window?.s4cConfig?.features?.simulatePayout ?? false);

  if (isLoading) {
    return (
      <Panel label={t(LABELS.PANEL_TITLE)}>
        <OptionallyVisible visible={isLoading}>
          <LoadingIndicator padded />
        </OptionallyVisible>
      </Panel>
    );
  }

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <OptionallyVisible visible={hasMerchantData}>
        <DataBar hasPayouts={hasPayouts}>
          <Section>
            <SectionTitle>
              <IconRefundAlt />
              {t(LABELS.MERCHANT_INFO.BALANCE)}
            </SectionTitle>{' '}
            <SectionContent>
              {formatNumber(merchantData?.balance)} {KEYS.HARDCODED_CURRENCY}
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>
              <IconUpdate24 />
              {t(LABELS.MERCHANT_INFO.DATE)}
            </SectionTitle>{' '}
            <SectionContent>{formatTableDate(merchantData?.nextPayout)}</SectionContent>
          </Section>
          <OptionallyVisible visible={isPayoutAvailable}>
            <StyledButton primary onClick={simulatePayout} disabled={isPayoutInProgress}>
              {t(LABELS.MERCHANT_INFO.SIMULATE_PAYOUT)}
            </StyledButton>
          </OptionallyVisible>
        </DataBar>
      </OptionallyVisible>
      <OptionallyVisible visible={hasPayouts}>
        <DataTableContainer>
          <DataTable
            columns={COLUMNS}
            rows={payouts}
            order={sorting.order}
            orderBy={sorting.orderBy}
            onSort={onSort}
            onRowClick={onRowClick}
            CellComponent={PayoutCell}
            pagination={pagination}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </DataTableContainer>
      </OptionallyVisible>
    </Panel>
  );
};

export const PayoutsSection = withTranslation()(PayoutsSectionComponent);
