export enum BridgeIdentityType {
  DRIVERS_LICENSE = 'drivers_license',
  MATRICULATE_ID = 'matriculate_id',
  MILITARY_ID = 'military_id',
  NATIONAL_ID = 'national_id',
  PASSPORT = 'passport',
  PERMANENT_RESIDENCY_ID = 'permanent_residency_id',
  STATE_OR_PROVINCIAL_ID = 'state_or_provincial_id',
  VISA = 'visa',
  ABN = 'abn',
  ACN = 'acn',
  AHV = 'ahv',
  AK = 'ak',
  AOM = 'aom',
  ARBN = 'arbn',
  AVS = 'avs',
  BC = 'bc',
  BCE = 'bce',
  BIN = 'bin',
  BIR = 'bir',
  BP = 'bp',
  BRN = 'brn',
  BSN = 'bsn',
  BVN = 'bvn',
  CC = 'cc',
  CDI = 'cdi',
  CEDULA_JURIDICA = 'cedula_juridica',
  CF = 'cf',
  CIF = 'cif',
  CIN = 'cin',
  CIPC = 'cipc',
  CN = 'cn',
  CNP = 'cnp',
  CNPJ = 'cnpj',
  CPF = 'cpf',
  CPR = 'cpr',
  CRC = 'crc',
  CRIB = 'crib',
  CRN = 'crn',
  CRO = 'cro',
  CUI = 'cui',
  CUIL = 'cuil',
  CUIT = 'cuit',
  CVR = 'cvr',
  EDRPOU = 'edrpou',
  EIN = 'ein',
  EMBG = 'embg',
  EMIRATES_ID = 'emirates_id',
  EN = 'en',
  FIN = 'fin',
  FN = 'fn',
  GSTIN = 'gstin',
  GUI = 'gui',
  HETU = 'hetu',
  HKID = 'hkid',
  HN = 'hn',
  IC = 'ic',
  ICO = 'ico',
  ID = 'id',
  ID_BROJ = 'id_broj',
  IDNO = 'idno',
  IDNP = 'idnp',
  IDNR = 'idnr',
  IF = 'if',
  IIN = 'iin',
  IK = 'ik',
  INN = 'inn',
  IRD = 'ird',
  ITIN = 'itin',
  ITR = 'itr',
  IVA = 'iva',
  JMBG = 'jmbg',
  KBO = 'kbo',
  KVK = 'kvk',
  MATRICULE = 'matricule',
  MF = 'mf',
  MN = 'mn',
  MS = 'ms',
  MST = 'mst',
  NIC = 'nic',
  NICN = 'nicn',
  NIE = 'nie',
  NIF = 'nif',
  NIN = 'nin',
  NINO = 'nino',
  NIP = 'nip',
  NIPC = 'nipc',
  NIPT = 'nipt',
  NIT = 'nit',
  NPWP = 'npwp',
  NRIC = 'nric',
  NRN = 'nrn',
  NRT = 'nrt',
  NTN = 'ntn',
  NUIT = 'nuit',
  NZBN = 'nzbn',
  OIB = 'oib',
  ORG = 'org',
  OTHER = 'other',
  PAN = 'pan',
  PARTITA_IVA = 'partita_iva',
  PESEL = 'pesel',
  PIB = 'pib',
  PIN = 'pin',
  PK = 'pk',
  PPSN = 'ppsn',
  QID = 'qid',
  RC = 'rc',
  REGON = 'regon',
  RFC = 'rfc',
  RICN = 'ricn',
  RIF = 'rif',
  RN = 'rn',
  RNC = 'rnc',
  RNOKPP = 'rnokpp',
  RP = 'rp',
  RRN = 'rrn',
  RTN = 'rtn',
  RUC = 'ruc',
  RUT = 'rut',
  SI = 'si',
  SIN = 'sin',
  SIREN = 'siren',
  SIRET = 'siret',
  SPI = 'spi',
  SSM = 'ssm',
  SSN = 'ssn',
  STEUER_ID = 'steuer_id',
  STRN = 'strn',
  TCKN = 'tckn',
  TFN = 'tfn',
  TIN = 'tin',
  TPIN = 'tpin',
  TRN = 'trn',
  UCN = 'ucn',
  UEN = 'uen',
  UIC = 'uic',
  UID = 'uid',
  USC = 'usc',
  UST_IDNR = 'ust_idnr',
  UTR = 'utr',
  VAT = 'vat',
  VKN = 'vkn',
  VOEN = 'voen',
  Y_TUNNUS = 'y_tunnus',
}

export enum BridgeCustomerBusinessType {
  COOPERATIVE = 'cooperative',
  CORPORATION = 'corporation',
  LLC = 'llc',
  OTHER = 'other',
  PARTNERSHIP = 'partnership',
  SOLE_PROP = 'sole_prop',
}

export enum BridgeDocumentPurpose {
  AML_COMFORT_LETTER = 'aml_comfort_letter',
  BUSINESS_FORMATION = 'business_formation',
  DIRECTORS_REGISTRY = 'directors_registry',
  E_SIGNATURE_CERTIFICATE = 'e_signature_certificate',
  EVIDENCE_OF_GOOD_STANDING = 'evidence_of_good_standing',
  FLOW_OF_FUNDS = 'flow_of_funds',
  FORMATION_DOCUMENT = 'formation_document',
  MARKETING_MATERIALS = 'marketing_materials',
  OWNERSHIP_CHART = 'ownership_chart',
  OWNERSHIP_INFORMATION = 'ownership_information',
  PROOF_OF_ACCOUNT_PURPOSE = 'proof_of_account_purpose',
  PROOF_OF_ADDRESS = 'proof_of_address',
  PROOF_OF_ENTITY_NAME_CHANGE = 'proof_of_entity_name_change',
  PROOF_OF_NATURE_OF_BUSINESS = 'proof_of_nature_of_business',
  PROOF_OF_SIGNATORY_AUTHORITY = 'proof_of_signatory_authority',
  PROOF_OF_SOURCE_OF_FUNDS = 'proof_of_source_of_funds',
  PROOF_OF_SOURCE_OF_WEALTH = 'proof_of_source_of_wealth',
  PROOF_OF_TAX_IDENTIFICATION = 'proof_of_tax_identification',
  SHAREHOLDER_REGISTER = 'shareholder_register',
  OTHER = 'other',
}

export enum BridgeHighRiskActivities {
  ADULT_ENTERTAINMENT = 'adult_entertainment',
  GAMBLING = 'gambling',
  HOLD_CLIENT_FUNDS = 'hold_client_funds',
  INVESTMENT_SERVICES = 'investment_services',
  LENDING_BANKING = 'lending_banking',
  MARIJUANA_OR_RELATED_SERVICES = 'marijuana_or_related_services',
  MONEY_SERVICES = 'money_services',
  NICOTINE_TOBACCO_OR_RELATED_SERVICES = 'nicotine_tobacco_or_related_services',
  OPERATE_FOREIGN_EXCHANGE_VIRTUAL_CURRENCIES_BROKERAGE_OTC = 'operate_foreign_exchange_virtual_currencies_brokerage_otc',
  PHARMACEUTICALS = 'pharmaceuticals',
  PRECIOUS_METALS_PRECIOUS_STONES_JEWELRY = 'precious_metals_precious_stones_jewelry',
  SAFE_DEPOSIT_BOX_RENTALS = 'safe_deposit_box_rentals',
  THIRD_PARTY_PAYMENT_PROCESSING = 'third_party_payment_processing',
  WEAPONS_FIREARMS_AND_EXPLOSIVES = 'weapons_firearms_and_explosives',
  NONE_OF_THE_ABOVE = 'none_of_the_above',
}

export enum BridgeSourceOfFunds {
  BUSINESS_LOANS = 'business_loans',
  GRANTS = 'grants',
  INTER_COMPANY_FUNDS = 'inter_company_funds',
  INVESTMENT_PROCEEDS = 'investment_proceeds',
  LEGAL_SETTLEMENT = 'legal_settlement',
  OWNERS_CAPITAL = 'owners_capital',
  PENSION_RETIREMENT = 'pension_retirement',
  SALE_OF_ASSETS = 'sale_of_assets',
  SALES_OF_GOODS_AND_SERVICES = 'sales_of_goods_and_services',
  TAX_REFUND = 'tax_refund',
  THIRD_PARTY_FUNDS = 'third_party_funds',
  TREASURY_RESERVES = 'treasury_reserves',
}

export enum BridgeAccountPurpose {
  CHARITABLE_DONATIONS = 'charitable_donations',
  ECOMMERCE_RETAIL_PAYMENTS = 'ecommerce_retail_payments',
  INVESTMENT_PURPOSES = 'investment_purposes',
  OTHER = 'other',
  PAYMENTS_TO_FRIENDS_OR_FAMILY_ABROAD = 'payments_to_friends_or_family_abroad',
  PAYROLL = 'payroll',
  PERSONAL_OR_LIVING_EXPENSES = 'personal_or_living_expenses',
  PROTECT_WEALTH = 'protect_wealth',
  PURCHASE_GOODS_AND_SERVICES = 'purchase_goods_and_services',
  RECEIVE_PAYMENTS_FOR_GOODS_AND_SERVICES = 'receive_payments_for_goods_and_services',
  TAX_OPTIMIZATION = 'tax_optimization',
  THIRD_PARTY_MONEY_TRANSMISSION = 'third_party_money_transmission',
  TREASURY_MANAGEMENT = 'treasury_management',
}

export type IdentifyingInformationType = {
  type?: {
    key: BridgeIdentityType;
    label?: string;
  };
  issuingCountry?: {
    key: string;
    label?: string;
    icon?: string;
  };
  number: string;
};

export type BusinessIndustryType = {
  key: BridgeCustomerBusinessType;
  label?: string;
};

export type AddressType = {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  subdivision?: string;
  postalCode: string;
  country?: {
    key: string;
    label?: string;
    icon?: string;
  };
};

export type DocumentType = {
  purpose?: {
    key: BridgeDocumentPurpose;
    label?: string;
  };
  file?: File;
  description?: string;
};

export type AssociatedPersonType = {
  firstName: string;
  lastName: string;
  email: string;
  residentialAddress: AddressType;
  birthDate: string | null;
  hasOwnership: boolean;
  hasControl: boolean;
  isSigner: boolean;
  phone: string;
  isDirector: boolean;
  title: string;
  ownershipPercentage: number | undefined;
  relationshipEstablishedAt: string;
  identifyingInformation: IdentifyingInformationType[];
  documents: DocumentType[];
};

export type BusinessHighRiskActivities = {
  activity?: {
    key: BridgeHighRiskActivities;
    label?: string;
  };
};

export type RegulatedActivityType = {
  regulatedActivitiesDescription: string;
  primaryRegulatoryAuthorityCountry?: {
    key: string;
    label?: string;
    icon?: string;
  };
  primaryRegulatoryAuthorityName: string;
  licenseNumber: string;
};

export type SourceOfFundsType = {
  key: BridgeSourceOfFunds;
  label?: string;
};

export type AccountPurposeType = {
  key: BridgeAccountPurpose;
  label?: string;
};
