import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { CopyMenuItem, MenuItem } from 'components/dataTable/cells';
import { ActionMenu } from 'components/dataTable/cells/actionMenu';

import RoutePath from 'router/path';

import { LABELS } from '../../keys';
import { TransactionDataRow } from '../../types';

export const ActionsCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { source, orderUuid } = row as TransactionDataRow;

  const navigateToOrder = (uuid: string) => () => navigate(RoutePath.pwc.orderById(uuid));

  return (
    <TableCell>
      <ActionMenu title={t(LABELS.ACTIONS.PAYOUT.TITLE)}>
        <CopyMenuItem value={source}>{t(LABELS.ACTIONS.PAYOUT.COPY_SOURCE_ID)}</CopyMenuItem>
        <CopyMenuItem value={orderUuid}>{t(LABELS.ACTIONS.PAYOUT.COPY_ORDER_ID)}</CopyMenuItem>
        <MenuItem onClick={navigateToOrder(orderUuid)}>{t(LABELS.ACTIONS.PAYOUT.OPEN_ORDER_DETAILS)}</MenuItem>
      </ActionMenu>
    </TableCell>
  );
};
