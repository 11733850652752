import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantKyc, MerchantKycStatuses } from 'services/merchants/types';

import { Panel } from 'components/containers';
import DataTable from 'components/dataTable/dataTable';
import IconAdd from 'components/icons/add';
import { SelectItem } from 'components/inputs/select/select';

import { BankAccountCell } from './components/cells';
import { CreateBankAccountsModal } from './components/createAccount';
import { useCreateBankAccountsModal } from './components/createAccount/useCreateBankAccountsModal';
import { SettlementTablePlaceholder } from './components/tablePlaceholder';
import { LABELS, SETTLEMENT_COLUMNS } from './keys';
import { ActionPanel, AddRecordButton, SettlementContainer } from './styles';
import { BankAccountsDataRow } from './types';

interface PaymentMethodsSectionProps {
  bankAccounts: BankAccountsDataRow[];
  countries: SelectItem[];
  loading: boolean;
  isPartner: boolean;
  merchantId: string;
  kyc: MerchantKyc;
}

export const Settlement = ({ bankAccounts, loading, merchantId, countries, isPartner, kyc }: PaymentMethodsSectionProps) => {
  const { t } = useTranslation();
  const {
    handleCreateAccountModalOpen,
    createdAccount,
    modalProps: createAccountModalProps,
  } = useCreateBankAccountsModal({ merchantId, countries });

  return (
    <Panel label={t(LABELS.SETTLEMENT.PANEL_TITLE)}>
      <SettlementContainer isPartner={isPartner}>
        <OptionallyVisible visible={bankAccounts.length === 0 || kyc.kycStatus === MerchantKycStatuses.Approved}>
          <ActionPanel>
            {/* TODO: Change the condition when multiple accounts are supported */}
            <AddRecordButton
              flat
              onClick={handleCreateAccountModalOpen}
              disabled={bankAccounts.length > 0 || kyc.kycStatus !== MerchantKycStatuses.Approved}
            >
              <IconAdd />
              {t(LABELS.SETTLEMENT.ADD_METHOD)}
            </AddRecordButton>
          </ActionPanel>
        </OptionallyVisible>
        <OptionallyVisible visible={Boolean(createdAccount)}>
          <CreateBankAccountsModal {...createAccountModalProps} />
        </OptionallyVisible>
        <DataTable
          placeholder={<SettlementTablePlaceholder action={handleCreateAccountModalOpen} kyc={kyc} />}
          loading={loading}
          columns={SETTLEMENT_COLUMNS(isPartner)}
          rows={bankAccounts}
          CellComponent={BankAccountCell}
        />
      </SettlementContainer>
    </Panel>
  );
};
