import { useTranslation } from 'react-i18next';

import defaultProductImage from 'assets/images/default-product-image.png';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import { OrderItem as ItemOfOrder, OrderItemType } from 'types/pwcOrder';

import { KEYS, LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import {
  AmountBlock,
  AmountNumericValue,
  AmountValue,
  Delimiter,
  Item,
  ItemName,
  ItemValue,
  OrderItemDetails,
  OrderItemImage,
  OrderItemName,
  OrderItems,
  SectionWrapper,
} from '../styles';
import { formatNumber } from '../utils';

const OrderItem = ({ name, imageUrl, fiatAmount, currency }: ItemOfOrder & { currency: string }) => {
  const { t } = useTranslation();

  return (
    <OrderItemDetails>
      <OrderItemImage src={imageUrl || defaultProductImage} alt={name} /> <OrderItemName>{name}</OrderItemName> {t(KEYS.DASH)}
      <span>
        {fiatAmount} {currency.toUpperCase()}
      </span>
    </OrderItemDetails>
  );
};

export const OrderSummarySection = ({ orderData }: OrderPwcSectionProps) => {
  const { t } = useTranslation();

  const getItemByType = (type: OrderItemType): ItemOfOrder | undefined => {
    return orderData.general.items.find((item) => item.type === type);
  };

  return (
    <Panel label={t(LABELS.SECTIONS.ORDER_SUMMARY.TITLE)}>
      <SectionWrapper>
        <OptionallyVisible visible={Boolean(orderData.general?.items?.length) || Boolean(orderData.general.redirectUrl)}>
          <Delimiter />
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.general?.items?.length)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER_SUMMARY.ITEMS)}</ItemName>
            <ItemValue>
              <OrderItems>
                {orderData.general?.items
                  ?.filter((item) => item.type === OrderItemType.Product)
                  .map((item) => <OrderItem key={item.uuid} {...item} currency={orderData.general.fiatCurrencyCode!} />)}
              </OrderItems>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(getItemByType(OrderItemType.Tax))}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER_SUMMARY.TAX)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.general.fiatCurrencyCode?.toUpperCase()}</AmountValue>
                <AmountNumericValue>{formatNumber(getItemByType(OrderItemType.Tax)?.fiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(getItemByType(OrderItemType.Delivery))}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER_SUMMARY.DELIVERY_FEE)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.general.fiatCurrencyCode?.toUpperCase()}</AmountValue>
                <AmountNumericValue>{formatNumber(getItemByType(OrderItemType.Delivery)?.fiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(getItemByType(OrderItemType.Tip))}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER_SUMMARY.TIP)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.general.fiatCurrencyCode?.toUpperCase()}</AmountValue>
                <AmountNumericValue>{formatNumber(getItemByType(OrderItemType.Tip)?.fiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.general.redirectUrl)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER_SUMMARY.REDIRECT_URL)}</ItemName>
            <ItemValue>{orderData.general.redirectUrl}</ItemValue>
          </Item>
        </OptionallyVisible>
      </SectionWrapper>
    </Panel>
  );
};
