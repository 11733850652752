import styled from '@emotion/styled';

export const Label = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const Optional = styled.span((props) => ({
  fontStyle: 'italic',
  color: props.theme.colors.inactive,
}));
